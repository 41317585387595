import { CfiAttributeListModel } from "./cfi-attribute-list";
import { CfiItem } from "./cfi-item";

export class CfiGroup extends CfiItem {
    longDescription: string = '';
    attributeList1: CfiAttributeListModel = {} as CfiAttributeListModel;
    attributeList2: CfiAttributeListModel = {} as CfiAttributeListModel;
    attributeList3: CfiAttributeListModel = {} as CfiAttributeListModel;
    attributeList4: CfiAttributeListModel = {} as CfiAttributeListModel;

    constructor(
        letterCode: string,
        shortDescription: string,
        longDescription: string,
        attributeList1: CfiAttributeListModel,
        attributeList2: CfiAttributeListModel,
        attributeList3: CfiAttributeListModel,
        attributeList4: CfiAttributeListModel) {
        super(letterCode, shortDescription);
        this.longDescription = longDescription;

        this.attributeList1 = attributeList1;
        this.attributeList2 = attributeList2;
        this.attributeList3 = attributeList3;
        this.attributeList4 = attributeList4;
    }
}