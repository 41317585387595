import { IncidentLogTypes } from "../../../core/enums/incident-log-types.enum";
import { IncidentLog } from "../../../core/models/incident-log";
import dayjs from 'dayjs';
import { IncidentStatusType } from "../../../core/enums/incident-status-type.enum";
import Stack from "@mui/system/Stack";
import Chip from "@mui/material/Chip";

export const GuidilineIncidentLogs = ({ logs }: { logs: IncidentLog[] }) => {
    const getIncidentStausLabel = (status: IncidentStatusType) => {
        switch (status) {
            case IncidentStatusType.ActiveBreach:
                return <Chip color='error' label='ACTIVE BREACH' />
            case IncidentStatusType.Escalation:
                return <Chip color='primary' label='ESCALATION' />
            case IncidentStatusType.PassiveBreach:
                return <Chip color='primary' label='PASSIVE BREACH' />
            case IncidentStatusType.Open:
                return <Chip color='default' label='OPEN' />
            case IncidentStatusType.Resolved:
                return <Chip color='success' label='RESOLVED' />
        }
    }

    const getIncidentLogDate = (date: Date) => {
        return dayjs(date).format("MMMM D, YYYY h:mm A")
    }

    const getIncidentLogMessage = (log: IncidentLog) => {
        const eventType = log.type;
        switch (eventType) {
            case IncidentLogTypes.NewIncidentCreated:
                return <h5>
                    <span>Incident created</span>
                </h5>

            case IncidentLogTypes.IncidentResolverIsAutoAssigned:
                return <h5>
                    <span>{log.data.user} was auto-assigned to the incident</span>
                </h5>

            case IncidentLogTypes.IncidentAssigneeChanged:
                return <h5>
                    <span>{log.data.user} was assigned to the incident</span>
                </h5>

            case IncidentLogTypes.NewCommentAdded:
                return <div>
                    <h5>{log.data.user} added a comment</h5>
                    <div>{log.data.comment}</div>
                </div>

            case IncidentLogTypes.IncidentStatusChanged:
                return <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                    <h5>{log.data.user} changed incident status to</h5>
                    <div>{getIncidentStausLabel(log.data.status)}</div>
                </Stack>

            case IncidentLogTypes.IncidentStatusChangedAutomatically:
                return <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                    <h5>Incident status was automatically updated to</h5>
                    <div>{getIncidentStausLabel(log.data.status)}</div>
                </Stack>

            case IncidentLogTypes.IncidentMuted:
                return <h5>
                    <span>Incident muted until {dayjs(log.data.mutedDate).format("DD.MM.YYYY")}</span>
                </h5>

            case IncidentLogTypes.IncidentMutingCanceled:
                return <h5>
                    <span>Muted automatically cancelled due to status update</span>
                </h5>
        }
    }

    return <div>
        {
            logs.map(l => <Stack
                key={l.dateCreated.toString()}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing="1">
                <div>
                    {getIncidentLogMessage(l)}
                </div>
                <h6 style={{ color: '#AEAEAE' }}>
                    {getIncidentLogDate(l.dateCreated)}
                </h6>
            </Stack>)
        }
    </div>
}