import { useAuth } from "../../auth";
import { useEffect, useState } from "react";
import { CompanyService } from "../../core/services/company.service";
import { ICompany } from "../../core/models/company";
import { Box, Button, TextField } from "@mui/material";
import { UserRoles } from "../../core/constants/user-role.const";
import { Spinner } from "../../spinner";

import "./company-tab.css";

const CompanyTab = () => {
    const { user } = useAuth();

    useEffect(() => loadCompany(), []);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [company, setCompany] = useState<ICompany>({} as ICompany);

    const [name, setName] = useState<string>("");
    const [isNameError, setNameError] = useState<boolean>(false);
    const [nameHelperText, setNameHelperText] = useState<string>("");

    const [website, setWebsite] = useState<string>("");
    const [isWebsiteError, setWebsiteError] = useState<boolean>(false);
    const [websiteHelperText, setWebsiteHelperText] = useState<string>("");

    const [addressLine1, setAddressLine1] = useState<string>("");
    const [isAddressLine1Error, setAddressLine1WebsiteError] = useState<boolean>(false);
    const [addressLine1HelperText, setAddressLine1WebsiteHelperText] = useState<string>("");

    const [addressLine2, setAddressLine2] = useState<string>("");
    const [isAddressLine2Error, setAddressLine2Error] = useState<boolean>(false);
    const [addressLine2HelperText, setAddressLine2HelperText] = useState<string>("");

    const [postcode, setPostcode] = useState<string>("");
    const [isPostcodeError, setPostcodeError] = useState<boolean>(false);
    const [postcodeHelperText, setPostcodeHelperText] = useState<string>("");

    const [city, setCity] = useState<string>("");
    const [isCityError, setCityError] = useState<boolean>(false);
    const [cityHelperText, setCityHelperText] = useState<string>("");

    const [county, setCounty] = useState<string>("");
    const [isCountyError, setCountyError] = useState<boolean>(false);
    const [countyHelperText, setCountyHelperText] = useState<string>("");

    const [country, setCountry] = useState<string>("");
    const [isCountryError, setCountryError] = useState<boolean>(false);
    const [countryHelperText, setCountryHelperText] = useState<string>("");

    const disabledField = user.role !== UserRoles.admin;

    const setFormFieldsValues = (company: ICompany) => {
        setCompany(company);
        setName(company.name);
        setWebsite(company.website);
        setAddressLine1(company.addressLine1);
        setAddressLine2(company.addressLine2);
        setPostcode(company.postcode);
        setCity(company.city);
        setCounty(company.county);
        setCountry(company.country);

        setDataLoaded(true);
    }

    function loadCompany() {
        if (dataLoaded) setDataLoaded(false);

        CompanyService
            .getCompany()
            .then((company: ICompany) => setFormFieldsValues(company))
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);

        if (!event.target.value.trim()) {
            setNameError(true);
            setNameHelperText("Invalid company name");

            return;
        }

        setNameError(false);
        setNameHelperText("");
    }

    const handleChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebsite(event.target.value);

        if (!event.target.value.trim()) {
            setWebsiteError(true);
            setWebsiteHelperText("Invalid website name");

            return;
        }

        setWebsiteError(false);
        setWebsiteHelperText("");
    }

    const handleChangeAddressLine1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine1(event.target.value);

        if (!event.target.value.trim()) {
            setAddressLine1WebsiteError(true);
            setAddressLine1WebsiteHelperText("Invalid address line 1");

            return;
        }

        setAddressLine1WebsiteError(false);
        setAddressLine1WebsiteHelperText("");
    }

    const handleChangeAddressLine2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine2(event.target.value);

        if (!event.target.value.trim()) {
            setAddressLine2Error(true);
            setAddressLine2HelperText("Invalid address line 2");

            return;
        }

        setAddressLine2Error(false);
        setAddressLine2HelperText("");
    }

    const handleChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostcode(event.target.value);

        if (!event.target.value.trim()) {
            setPostcodeError(true);
            setPostcodeHelperText("Invalid postal code");

            return;
        }

        setPostcodeError(false);
        setPostcodeHelperText("");
    }

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);

        if (!event.target.value.trim()) {
            setCityError(true);
            setCityHelperText("Invalid city name");

            return;
        }

        setCityError(false);
        setCityHelperText("");
    }

    const handleChangeCounty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCounty(event.target.value);

        if (!event.target.value.trim()) {
            setCountyError(true);
            setCountyHelperText("Invalid county name");

            return;
        }

        setCountyError(false);
        setCountyHelperText("");
    }

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);

        if (!event.target.value.trim()) {
            setCountryError(true);
            setCountryHelperText("Invalid country name");

            return;
        }

        setCountryError(false);
        setCountryHelperText("");
    }

    const handleCompanyUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (
            isNameError ||
            isWebsiteError ||
            isAddressLine1Error ||
            isAddressLine2Error ||
            isPostcodeError ||
            isCityError ||
            isCountyError ||
            isCountryError
        ) return;

        if (dataLoaded) setDataLoaded(false);

        await CompanyService
            .updateCompany(
                name,
                website,
                addressLine1,
                addressLine2,
                postcode,
                city,
                county,
                country,
            )
            .then((company: ICompany) => setFormFieldsValues(company));
    }

    return (
        <Box component={"div"}>
            <div className="tab-header company-tab-header">
                <span className="tab-title">
                    Company Information
                </span>
            </div>

            {
                dataLoaded
                    ? <Box
                        component={"form"}
                        onSubmit={handleCompanyUpdate}
                        className="form company-tab-form"
                    >
                        {
                            user.role === UserRoles.admin
                                ? <div className="buttons-container">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                </div>
                                : null
                        }

                        <div className="field-group">
                            <TextField
                                required
                                value={name}
                                onChange={handleChangeName}
                                className="field"
                                label="COMPANY LEGAL NAME"
                                placeholder="Please enter company legal name"
                                variant="outlined"
                                helperText={nameHelperText}
                                error={isNameError}
                                disabled={disabledField}
                            />

                            <TextField
                                required
                                value={website}
                                onChange={handleChangeWebsite}
                                className="field"
                                label="WEBSITE"
                                placeholder="www.web.com"
                                variant="outlined"
                                helperText={websiteHelperText}
                                error={isWebsiteError}
                                disabled={disabledField}
                            />
                        </div>

                        <TextField
                            required
                            value={addressLine1}
                            onChange={handleChangeAddressLine1}
                            className="field"
                            label="ADDRESS LINE 1"
                            placeholder="Please enter address line 1"
                            variant="outlined"
                            error={isAddressLine1Error}
                            helperText={addressLine1HelperText}
                            disabled={disabledField}
                        />

                        <TextField
                            required
                            value={addressLine2}
                            onChange={handleChangeAddressLine2}
                            className="field"
                            label="ADDRESS LINE 2"
                            placeholder="Please enter address line 2"
                            variant="outlined"
                            error={isAddressLine2Error}
                            helperText={addressLine2HelperText}
                            disabled={disabledField}
                        />

                        <div className="field-group">
                            <TextField
                                required
                                value={postcode}
                                onChange={handleChangePostcode}
                                className="field"
                                label="POSTAL CODE"
                                placeholder="Please enter postal code"
                                variant="outlined"
                                error={isPostcodeError}
                                helperText={postcodeHelperText}
                                disabled={disabledField}
                            />

                            <TextField
                                required
                                value={city}
                                onChange={handleChangeCity}
                                className="field"
                                label="CITY"
                                placeholder="Please enter city"
                                variant="outlined"
                                error={isCityError}
                                helperText={cityHelperText}
                                disabled={disabledField}
                            />
                        </div>


                        <div className="field-group">
                            <TextField
                                required
                                value={county}
                                onChange={handleChangeCounty}
                                className="field"
                                label="COUNTY"
                                placeholder="Please enter county or region"
                                variant="outlined"
                                error={isCountyError}
                                helperText={countyHelperText}
                                disabled={disabledField}
                            />

                            <TextField
                                required
                                value={country}
                                onChange={handleChangeCountry}
                                className="field"
                                label="COUNTRY"
                                placeholder="Please enter country"
                                variant="outlined"
                                error={isCountryError}
                                helperText={countryHelperText}
                                disabled={disabledField}
                            />
                        </div>
                    </Box>
                    : <Spinner />
            }
        </Box>
    )
}

export { CompanyTab };