import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./guideline-incident.css"
import { GuidelineIncidentHeader } from "./guideline-incident-header";
import { useEffect, useRef, useState } from "react";
import { IncidentStatusType } from "../../core/enums/incident-status-type.enum";
import { IncidentReadModel } from "../../core/models/incident-read-model";
import { IncidentService } from "../../core/services/incident.setvice";
import { GuidilineIncidentLogs } from "./guideline-incident-logs";
import { GuidilineIncidentDetails } from "./guidiline-incident-details";
import Stack from "@mui/system/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

interface IGuidelineIncidentProps {
    incident: IncidentReadModel;
    fundToken: string;
    dailyHoldingsToken: string;
}

export const GuidelineIncident = ({ incident, fundToken, dailyHoldingsToken }: IGuidelineIncidentProps) => {
    const [status, setStatus] = useState(incident.status);
    const [mutedDate, setMutedDate] = useState(incident.mutedDate);
    const [isIncidentSummaryVisible, setIsIncidentSummaryVisible] = useState(false);
    const [logs, setLogs] = useState(incident.logs)
    const [assignee, setAssignee] = useState(incident.assignee);
    const [canIncidentStateBeModified, setCanIncidentStateBeModifiedFlag] = useState(incident.isActive);

    useEffect(() => {
        setStatus(incident.status);
        setMutedDate(incident.mutedDate);
        setLogs(incident.logs);
        setAssignee(incident.assignee);
        setCanIncidentStateBeModifiedFlag(incident.isActive);
    }, [incident])

    const commentTextFieldRef = useRef('');

    const toggleIncidentSummaryVisiblity = () => {
        setIsIncidentSummaryVisible(!isIncidentSummaryVisible);
    }

    const assigneeChangedHandler = (newAssignee: string) => {
        IncidentService.changeAssignee(
            incident.token,
            fundToken, dailyHoldingsToken,
            newAssignee)
            .then((newIncidentState: IncidentReadModel) => {
                setAssignee(newIncidentState.assignee);
                setLogs(newIncidentState.logs);
            })
            .catch((error) => console.log(`Unable to update assignee: ${error}`));
    }

    const statusChangedHandler =
        (newStatus: IncidentStatusType, mutedDate: Date | null = null) => {
            IncidentService.changeStatus(
                incident.token,
                fundToken, dailyHoldingsToken,
                newStatus,
                mutedDate)
                .then((newIncidentState: IncidentReadModel) => {
                    setStatus(newIncidentState.status);
                    setLogs(newIncidentState.logs);

                    if (newIncidentState.status.status == IncidentStatusType.PassiveBreach) {
                        setMutedDate(newIncidentState.mutedDate);
                    }

                    if (newIncidentState.status.status == IncidentStatusType.Resolved) {
                        setCanIncidentStateBeModifiedFlag(newIncidentState.isActive);
                    }
                })
                .catch((error) => console.log(`Unable to update status: ${error}`));
        }

    const addComment = () => {
        const comment = (commentTextFieldRef as any).current.value;

        IncidentService.addComment(
            incident.token,
            fundToken, dailyHoldingsToken,
            comment)
            .then((newIncidentState: IncidentReadModel) => {
                (commentTextFieldRef as any).current.value = '';
                setLogs(newIncidentState.logs);
            })
            .catch((error) => console.log(`Unable to add comment: ${error}`));
    }

    return <>
        <Accordion expanded={isIncidentSummaryVisible}>
            <AccordionSummary onClick={toggleIncidentSummaryVisiblity} expandIcon={<ExpandMoreIcon />}>
                <GuidelineIncidentHeader
                    canIncidentStatusBeChanged={canIncidentStateBeModified}
                    status={status}
                    statusChangedHandler={statusChangedHandler}
                    mutedDate={mutedDate} />
            </AccordionSummary>

            <AccordionDetails>
                <GuidilineIncidentDetails
                    canAssigneeBeChanged={canIncidentStateBeModified}
                    assignee={assignee}
                    incidentId={incident.incidentId}
                    incidentAssigneeChanged={assigneeChangedHandler} />
                <h5>Activity</h5>
                <Stack
                    mt={3}
                    mb={3}
                    direction='row'
                    alignItems='flex-end'
                    spacing={3}>
                    <TextField
                        disabled={!canIncidentStateBeModified}
                        placeholder="Enter a comment ..."
                        multiline
                        rows={3}
                        sx={{ width: '80%' }}
                        inputRef={commentTextFieldRef} />
                    <Button
                        disabled={!canIncidentStateBeModified}
                        type="button"
                        variant="outlined"
                        size='small'
                        onClick={addComment}>
                        Send
                    </Button>
                </Stack>
                <GuidilineIncidentLogs logs={logs} />
            </AccordionDetails>
        </Accordion>
    </>
}