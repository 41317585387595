import { Box, Button, MenuItem, Modal, TextField, IconButton, Alert, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useState } from "react";
import { IUser } from "../../core/models/user";
import { UserService } from "../../core/services/user.service";
import { Spinner } from "../../spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UserRoles } from "../../core/constants/user-role.const";
import { AuthService } from "../../core/services/auth.service";
import { UsersTable } from "./users-table";
import { useAuth } from "../../auth";
import { UserResetPasswordResult } from "../../core/models/user-reset-password-result";
import { SearchInput } from "../../common/search-input";

import "./users-tab.css";

function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 840,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    minHeight: "352px",
};

const UsersTab = () => {
    const { user } = useAuth();

    const [userId, setUserId] = useState<string>("");

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>([]);
    const [searchParam, setSearchParam] = useState<string>("");

    const [isNewUserModalOpen, setNewUserModalOpen] = useState(false);
    const [isSignInInstructionsModalOpen, setSignInInstructionsModalOpen] = useState(false);
    const [isResetPasswordConfirmationModalOpen, setResetPasswordConfirmationModalOpen] = useState(false);

    const [firstName, setUserFirstName] = useState<string>("");
    const [isFirstNameError, setFirstNameError] = useState<boolean>(false);
    const [firstNameHelperText, setFirstNameHelperText] = useState<string>("");

    const [lastName, setUserLastName] = useState<string>("");
    const [isLastNameError, setLastNameError] = useState<boolean>(false);
    const [lastNameHelperText, setLastNameHelperText] = useState<string>("");

    const [email, setUserEmail] = useState<string>("");
    const [isEmailError, setEmailError] = useState<boolean>(false);
    const [emailHelperText, setEmailHelperText] = useState<string>("");

    const [userRole, setUserRole] = useState<string>(UserRoles.user);

    const [password, setUserPassword] = useState<string>("");
    const [isShowPassword, setVisibilityPassword] = useState<boolean>(false);

    const [registrationErrors, setRegistrationErrors] = useState<string[]>([]);
    const [isRegistrationProgress, setRegistrationProgress] = useState<boolean>(false);

    function loadUsers(searchString: string) {
        if (dataLoaded) setDataLoaded(false);

        UserService
            .getAllUsers(searchString)
            .then((appUsers: IUser[]) => {
                setUsers(appUsers);
                setDataLoaded(true);
            });
    };

    function changeUserRole(id: string) {
        UserService
            .changeUserRole(id)
            .then(() => {
                UserService
                    .getAllUsers(searchParam)
                    .then((appUsersAfterRoleChange: IUser[]) => {
                        setUsers(appUsersAfterRoleChange);
                    });
            })
    }

    function changeUserStatus(id: string) {
        UserService
            .changeUserStatus(id)
            .then(() => {
                UserService
                    .getAllUsers(searchParam)
                    .then((appUsersAfterStatusChange: IUser[]) => {
                        setUsers(appUsersAfterStatusChange);
                    })
            });
    }

    function resetUserPassword(id: string) {
        UserService
            .resetUserPassword(id)
            .then((result: UserResetPasswordResult) => {
                setUserId("");
                setResetPasswordConfirmationModalOpen(false);
                setUserEmail(result.email);
                setUserPassword(result.password);
                setSignInInstructionsModalOpen(true);
            })
    }

    const resetForm = () => {
        setUserFirstName("");
        setFirstNameError(false);
        setFirstNameHelperText("");

        setUserLastName("");
        setLastNameError(false);
        setLastNameHelperText("");

        setUserEmail("");
        setEmailError(false);
        setEmailHelperText("");

        setUserRole(UserRoles.user);

        setRegistrationErrors([]);
    }

    const onSearchStringChanged = (searchString: string) => {
        setSearchParam(searchString);
        loadUsers(searchString);
    };

    const handleChangeUserRole = (id: string) => changeUserRole(id);

    const handleChangeUserStatus = (id: string) => changeUserStatus(id);

    const handleResetUserPassword = (id: string) => {
        setUserId(id);
        setResetPasswordConfirmationModalOpen(true);
    }

    const handleConfirmResetUserPassword = () => resetUserPassword(userId);

    const handleModalOpen = () => {
        resetForm();
        setNewUserModalOpen(true);
    }

    const handleNewUserModalClose = () => setNewUserModalOpen(false);

    const handleSignInInstructionsModalClose = () => {
        UserService
            .getAllUsers(searchParam)
            .then((appUsers: IUser[]) => {
                setUsers(appUsers);
                setDataLoaded(true);
                setSignInInstructionsModalOpen(false);
                setVisibilityPassword(false);
            });
    }

    const handleResetPasswordConfirmationModalClose = () => {
        setUserId("");
        setResetPasswordConfirmationModalOpen(false);
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(event.target.value);

        if (!isValidEmail(event.target.value)) {
            setEmailError(true);
            setEmailHelperText("Invalid email address");

            return;
        }

        setEmailError(false);
        setEmailHelperText("");
    }

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserFirstName(event.target.value);

        if (!event.target.value.trim()) {
            setFirstNameError(true);
            setFirstNameHelperText("Invalid first name");

            return;
        }

        setFirstNameError(false);
        setFirstNameHelperText("");
    }

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserLastName(event.target.value);

        if (!event.target.value.trim()) {
            setLastNameError(true);
            setLastNameHelperText("Invalid last name");

            return;
        }

        setLastNameError(false);
        setLastNameHelperText("");
    }

    const handleUserRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isEmailError || isFirstNameError || isLastNameError) return;

        setRegistrationProgress(true);

        const registrationResult = await AuthService.register(firstName, lastName, email, userRole);
        if (Array.isArray(registrationResult)) {
            setRegistrationErrors(registrationResult);
            setRegistrationProgress(false);

            return;
        }

        if (registrationResult.password) {
            handleNewUserModalClose();
            setRegistrationProgress(false);
            setUserEmail(email);
            setUserPassword(registrationResult.password);
            setSignInInstructionsModalOpen(true);
        }
    }

    const handleVisiblePassword = () => setVisibilityPassword(!isShowPassword);

    const handleAddAnotherUser = () => {
        handleSignInInstructionsModalClose();
        handleModalOpen();
    }

    return (
        <div className="users-tab-content">
            <div className="tab-header">
                <span className="tab-title">
                    All Users
                </span>

                <div className="search-bar-container">
                    <SearchInput
                        onChange={onSearchStringChanged}
                        debounceTime={1500}
                    />

                    {
                        user.role === UserRoles.admin
                            ? <Button
                                variant="contained"
                                className="new-user-button"
                                onClick={handleModalOpen}
                            >
                                New user
                            </Button>
                            : null
                    }

                    <Modal
                        open={isNewUserModalOpen}
                        onClose={handleNewUserModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <h1>New user</h1>

                            {
                                !isRegistrationProgress
                                    ? <Box
                                        className="form"
                                        component={"form"}
                                        onSubmit={handleUserRegister}
                                    >
                                        <div className="field-group">
                                            <TextField
                                                required
                                                value={firstName}
                                                onChange={handleChangeFirstName}
                                                className="field"
                                                label="First Name"
                                                variant="outlined"
                                                helperText={firstNameHelperText}
                                                error={isFirstNameError}
                                            />

                                            <TextField
                                                required
                                                value={lastName}
                                                onChange={handleChangeLastName}
                                                className="field"
                                                label="Last Name"
                                                variant="outlined"
                                                helperText={lastNameHelperText}
                                                error={isLastNameError}
                                            />
                                        </div>

                                        <TextField
                                            required
                                            value={email}
                                            onChange={handleChangeEmail}
                                            className="field"
                                            label="mail@mail.com"
                                            variant="outlined"
                                            error={isEmailError}
                                            helperText={emailHelperText}
                                        />

                                        <TextField
                                            value={userRole}
                                            select
                                            label="Invite as"
                                            onChange={event => setUserRole(event.target.value)}
                                        >
                                            {
                                                [UserRoles.admin, UserRoles.user].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>

                                        {
                                            registrationErrors.length
                                                ? <div>
                                                    {
                                                        registrationErrors
                                                            .map((error: string, idx: number) => (
                                                                <h6 key={idx}>
                                                                    <Alert severity="error">
                                                                        {error}
                                                                    </Alert>
                                                                </h6>
                                                            ))
                                                    }
                                                </div>
                                                : ''
                                        }

                                        <div className="buttons-container">
                                            <Button onClick={handleNewUserModalClose}>
                                                Cancel
                                            </Button>

                                            <Button type="submit" variant="contained">
                                                Add User
                                            </Button>
                                        </div>
                                    </Box>

                                    : <Spinner />
                            }
                        </Box>
                    </Modal>

                    <Modal
                        open={isSignInInstructionsModalOpen}
                        onClose={handleSignInInstructionsModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <h1>Sign-in instructions</h1>

                            <Box
                                className="form"
                                component={"form"}
                            >
                                <TextField
                                    value={email}
                                    className="field"
                                    label="Email"
                                    disabled
                                />

                                <div className="field-password-container">
                                    <TextField
                                        value={password}
                                        className="password-field"
                                        label="Password"
                                        type={isShowPassword ? "text" : "password"}
                                        disabled
                                    />

                                    <IconButton
                                        onClick={handleVisiblePassword}
                                        className="visibility-button"
                                    >
                                        {
                                            isShowPassword ? <VisibilityOffIcon color="primary" /> : <VisibilityIcon color="primary" />
                                        }
                                    </IconButton>
                                </div>

                                <a
                                    href={URL.createObjectURL(new Blob([`email: ${email}\npassword: ${password}`], { type: "text/plain" }))}
                                    download="Sign-in instructions.txt"
                                >
                                    Copy sign-in instructions
                                </a>

                                <div className="buttons-container">
                                    <Button onClick={handleAddAnotherUser}>
                                        Add anothre user
                                    </Button>

                                    <Button variant="contained" onClick={handleSignInInstructionsModalClose}>
                                        Done
                                    </Button>
                                </div>
                            </Box>
                        </Box>
                    </Modal>

                    <Dialog
                        open={isResetPasswordConfirmationModalOpen}
                        onClose={handleResetPasswordConfirmationModalClose}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Do you really want to reset the password?"}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={handleResetPasswordConfirmationModalClose}>
                                Cancel
                            </Button>

                            <Button onClick={handleConfirmResetUserPassword} autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            {
                dataLoaded
                    ? <UsersTable
                        data={users}
                        changeRoleAction={handleChangeUserRole}
                        changeStatusAction={handleChangeUserStatus}
                        resetPasswordAction={handleResetUserPassword}
                    />
                    : <Spinner />
            }
        </div>
    )
}

export { UsersTab };