import Button from '@mui/material/Button';

interface IGuidelineHeaderProps {
    isListMode: boolean;
    handleModeChange: (isListModeSet: boolean) => void;
}

export const GuidelineHeader = ({ isListMode, handleModeChange }: IGuidelineHeaderProps) => {
    const switchToCreateMode = () => handleModeChange(false);

    const switchToListMode = () => handleModeChange(true);

    return (
        <div className="guideline-header">
            {
                isListMode
                    ? <Button
                        onClick={switchToCreateMode}
                        type="button"
                        variant="outlined"
                    >
                        Create new
                    </Button>
                    : <Button
                        onClick={switchToListMode}
                        type="button"
                        variant="outlined"
                    >
                        Back to list
                    </Button>
            }
        </div>
    );
};