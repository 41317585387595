import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';

interface ISearchInputProps {
    onChange: (searchString: string) => void;
    debounceTime: number;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F2F2F2',
    marginLeft: 0,
    width: '224px',
    paddingRight: '10px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const SearchInput = ({ onChange, debounceTime }: ISearchInputProps) => {
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        let timerId = setTimeout(() => onChange(inputValue), debounceTime);

        return () => clearTimeout(timerId);
    }, [inputValue]);

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon color='disabled' />
            </SearchIconWrapper>

            <StyledInputBase
                placeholder='Search…'
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
            />
        </Search>
    )
}

export { SearchInput };