import { CfiAttributeListModel } from "../models/cfi-attribute-list";
import { CfiCategory } from "../models/cfi-category";
import { CfiGroup } from "../models/cfi-group";
import { CfiItem } from "../models/cfi-item";
import { notFormattedCategories } from "./not-formatted-categories";

const convertFromNotFormattedCategories = () => {
    return Object.keys(notFormattedCategories)
        .reduce((categories: CfiCategory[], categoryKey: string) => {
            const notFormattedCategory = notFormattedCategories[categoryKey];
            const notFormattedGroups = notFormattedCategory['groups'];

            const cfiGroups: CfiGroup[] = Object.keys(notFormattedGroups)
                .reduce((groups: CfiGroup[], groupKey: string) => {
                    const notFormattedGroup = notFormattedGroups[groupKey];
                    const notFormattedAttributes = notFormattedGroup['attributes'];

                    const attributeList1 = getAttributeList(notFormattedAttributes['attribute_1']);
                    const attributeList2 = getAttributeList(notFormattedAttributes['attribute_2']);
                    const attributeList3 = getAttributeList(notFormattedAttributes['attribute_3']);
                    const attributeList4 = getAttributeList(notFormattedAttributes['attribute_4']);

                    const cfiGroup = new CfiGroup(
                        groupKey,
                        notFormattedGroup.name,
                        notFormattedGroup.description,
                        attributeList1,
                        attributeList2,
                        attributeList3,
                        attributeList4);

                    groups.push(cfiGroup);
                    return groups;

                    function getAttributeList(attributeList: any) {
                        return new CfiAttributeListModel(
                            attributeList.description,
                            attributeList['possible_values'].map((attrValue: any) => new CfiItem(attrValue['letter'], attrValue['description'])));
                    }

                }, []);

            const cfiCategory = new CfiCategory(
                categoryKey,
                notFormattedCategory.name,
                notFormattedCategory.description,
                cfiGroups);

            categories.push(cfiCategory);
            return categories;
        }, []);
}

export const cfiCategories = convertFromNotFormattedCategories();