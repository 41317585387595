import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Stack } from "@mui/system"
import { IncidentResolvers } from "../../../core/constants/incident-resolvers"
import dayjs from 'dayjs';

export const GuidilineIncidentDetails = ({ canAssigneeBeChanged, incidentId, assignee, incidentAssigneeChanged, incidentCreationDate }: any) => {
    const changeAssigneeHandler = (event: SelectChangeEvent) => {
        const newAssignee = event.target.value;
        incidentAssigneeChanged(newAssignee);
    }

    const getIncidentInfoHeader = (header: string) => {
        return <div style={{ color: '#AEAEAE' }}>{header}</div>
    }

    const getIncidentInfoItem = (
        header: string,
        value: string) => {
        return <Stack
            direction='column'
            alignItems='flex-start'
            spacing={1}>
            {getIncidentInfoHeader(header)}
            <div style={{ fontWeight: "500" }}>{value}</div>
        </Stack>
    }

    // is used for canceling event propagation only
    // prevent accordion collapse / expand during status selection
    const assigneeSelectionClickHandler = (event: any) => {
        event.stopPropagation();
    }

    return <Stack
        mt={5}
        mb={5}
        direction='row'
        alignItems='baseline'
        spacing={5}>
        {getIncidentInfoItem('Incident Id', incidentId)}

        <Stack
            direction='column'
            alignItems='flex-start'
            spacing={1}>
            {getIncidentInfoHeader('Assignee')}
            <FormControl>
                <Select
                    disabled={!canAssigneeBeChanged}
                    sx={{ width: '180px' }}
                    displayEmpty
                    size="small"
                    value={assignee}
                    onChange={changeAssigneeHandler}
                    onClick={assigneeSelectionClickHandler}>
                    {
                        IncidentResolvers.map(resolver => {
                            return <MenuItem key={resolver} value={resolver}>{resolver}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Stack>

        {getIncidentInfoItem('Incident created date', dayjs(incidentCreationDate).format("DD.MM.YYYY"))}

    </Stack>
}