import './cfi-codes-row.css';

export const CfiCodesRow = (props: any) => {
    const { codes } = props;

    return (
        <span className="cfi-codes">
            CFI Codes: {codes.length ? codes.join(', ') : ''}
        </span>
    );
}