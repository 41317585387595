import Grid from '@mui/material/Grid';
import Sidebar from '../common/sidebar';
import { Dashboard } from '../dashboard';
import { Login } from '../login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GuidelineCreator } from '../guideline-creator';
import { GuidelineCheckHistoryPage } from '../guideline-check-history-page';
import { useState } from 'react';
import { Settings } from '../settings';
import { Funds } from '../funds';
import { FundCreator } from '../fund-creator';
import { Reports } from '../reports';

import './layout.css';

export const Layout = () => {
    const [isShowSidebar, setSideBarVisibility] = useState<boolean>(false);

    const pageChangedHandler = (isShowSidebar: boolean) => setSideBarVisibility(isShowSidebar);

    return (
        <BrowserRouter>
            <Grid className='layout-container' container spacing={2}>
                {
                    isShowSidebar &&
                    <Grid className='left-column' item xs={12} md={2}>
                        <Sidebar />
                    </Grid>
                }
                <Grid item xs={12} md={isShowSidebar ? 9 : 12}>
                    <Routes>
                        <Route path="/" element={<Dashboard onPageChanged={pageChangedHandler} />} />
                        <Route path="/guideline-creator" element={<GuidelineCreator onPageChanged={pageChangedHandler} />} />
                        <Route path="/login" element={<Login onPageChanged={pageChangedHandler} />} />
                        <Route
                            path="/monitoring/funds/:fundToken/guidelines/:guidelineToken"
                            element={<GuidelineCheckHistoryPage onPageChanged={pageChangedHandler} />}
                        />
                        <Route path="/funds" element={<Funds onPageChanged={pageChangedHandler} />} />
                        <Route path="/fund-creator" element={<FundCreator onPageChanged={pageChangedHandler} />}>
                            <Route path=":fundToken" element={<FundCreator onPageChanged={pageChangedHandler} />}/>
                        </Route>
                        <Route path="/settings" element={<Settings onPageChanged={pageChangedHandler} />} />
                        <Route path="/reports" element={<Reports onPageChanged={pageChangedHandler} />} />
                    </Routes>
                </Grid>
            </Grid>
        </BrowserRouter>
    );
};