import Dialog from "@mui/material/Dialog"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Box from "@mui/system/Box"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { useState } from "react"
import { IncidentStatusItems, IncidentStatusType } from "../../../core/enums/incident-status-type.enum"
import { IncidentStatus } from "../../../core/models/incident-status"
import { GuidelineIncidentStatus } from "../guideline-incident-status"

export interface IGuidelineIncidentHeaderProps {
    status: IncidentStatus,
    statusChangedHandler: (newStatus: IncidentStatusType, mutedDate: Date | null) => void,
    mutedDate: null | Date;
    canIncidentStatusBeChanged: boolean;
}

export const GuidelineIncidentHeader = (props: IGuidelineIncidentHeaderProps) => {
    const [isMutedDateSelectionRequired, setIsMutedDateSelectionRequired] = useState(false);

    // is used for canceling event propagation only
    // prevent accordion collapse / expand during status selection
    const statusSelectionClickHandler = (event: any) => {
        event.stopPropagation();
    }

    const mutedDateSelectionHandler = (newMutedDate: any) => {
        const mutedDate = newMutedDate.$d;
        props.statusChangedHandler(IncidentStatusType.PassiveBreach, mutedDate);
    }

    const handleCancelingMutedDateSelection = () => {
        setIsMutedDateSelectionRequired(false);
    }

    const changeStatusHandler = (event: SelectChangeEvent) => {
        const newStatus = + event.target.value;

        if (newStatus == IncidentStatusType.PassiveBreach) {
            setIsMutedDateSelectionRequired(true);
        }
        else {
            props.statusChangedHandler(newStatus, null);
        }
    }

    return <div className="incident-header">
        <h4>Incident management</h4>
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '350px' }}>
                <GuidelineIncidentStatus
                    status={props.status}
                    mutedDate={props.mutedDate} />
                <FormControl>
                    <InputLabel size="small">Status</InputLabel>
                    <Select
                        sx={{ width: '180px' }}
                        size="small"
                        value={props.status.status.toString()}
                        label="Status"
                        onChange={changeStatusHandler}
                        onClick={statusSelectionClickHandler}
                        disabled={!props.canIncidentStatusBeChanged}>
                        {
                            IncidentStatusItems.map(option => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        </div>

        <Dialog onClose={handleCancelingMutedDateSelection} open={isMutedDateSelectionRequired}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'red' }}>Set muted date: </span>
                <StaticDatePicker
                    onClose={handleCancelingMutedDateSelection}
                    onAccept={mutedDateSelectionHandler} />
            </Box>
        </Dialog>
    </div>
}