import { RestrictionMetadataItem } from "../models/restriction-metadata-item";

export const GuidelineRestrictionService = {
    getMetadata: async (): Promise<RestrictionMetadataItem[]> => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([
                    {
                        dataType: { token: 1, name: 'Integer' },
                        availableOperations: [
                            { token: 1, name: 'Equal' },
                            { token: 2, name: 'Not Equal' },
                            { token: 3, name: 'Greater Than' },
                            { token: 4, name: 'Less Than' },
                            { token: 5, name: 'Greater Than Or Equal' },
                            { token: 6, name: 'Less Than Or Equal' },
                        ]
                    },
                    {
                        dataType: { token: 2, name: 'Float' },
                        availableOperations: [
                            { token: 1, name: 'Equal' },
                            { token: 2, name: 'Not Equal' },
                            { token: 3, name: 'Greater Than' },
                            { token: 4, name: 'Less Than' },
                            { token: 5, name: 'Greater Than Or Equal' },
                            { token: 6, name: 'Less Than Or Equal' },
                        ]
                    },
                    {
                        dataType: { token: 3, name: 'Text' },
                        availableOperations: [
                            { token: 1, name: 'Equal' },
                            { token: 2, name: 'Not Equal' }
                        ]
                    },
                    {
                        dataType: { token: 4, name: 'Rating' },
                        availableOperations: [
                            { token: 1, name: 'Equal' },
                            { token: 2, name: 'Not Equal' },
                            { token: 3, name: 'Greater Than' },
                            { token: 4, name: 'Less Than' },
                            { token: 5, name: 'Greater Than Or Equal' },
                            { token: 6, name: 'Less Than Or Equal' },
                        ]
                    }
                ]);
            }, 2000);
        });
    }
}