import { Button, IconButton, Paper } from "@mui/material";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAuth } from "../auth";
import { IFundLookupItem } from "../core/models/fund";
import { FundService } from "../core/services/fund.service";
import { Spinner } from "../spinner";
import { AccessTokenProviderService } from "../core/services/access-token-provider.service";
import EditIcon from '@mui/icons-material/Edit';

import "./funds.css";

const columns: GridColDef[] = [
    { field: "id", flex: 1, headerName: "Fund ID", minWidth: 250 },
    { field: "fundName", flex: 1, headerName: "Fund Name", minWidth: 250 },
    {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        flex: 1,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => {
            return (
                <Link to={`/fund-creator/${params.row.id}`} className="button-link">
                    <IconButton
                        color="primary"
                        component="span">
                        <EditIcon />
                    </IconButton>
                </Link>
            );
        }
    },
];

interface IFundsProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

const Funds = ({ onPageChanged }: IFundsProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [funds, setFunds] = useState<IFundLookupItem[]>([] as IFundLookupItem[]);

    useEffect(() => {
        const token = AccessTokenProviderService.getToken();

        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);

    useEffect(() => {
        onPageChanged(true);
        loadFunds();
    }, []);

    function loadFunds() {
        if (dataLoaded) setDataLoaded(false);

        FundService
            .getFunds()
            .then((result: IFundLookupItem[]) => {
                setFunds(result);
                setDataLoaded(true);
            })
    };

    const rows = funds.map(({ token, name }) => ({ id: token, fundName: name }));

    return (
        <Paper className="funds-list-container">
            <div className="funds-table-header">
                <span className="funds-table-title">
                    Funds
                </span>

                <Link to={"/fund-creator"} className="button-link">
                    <Button variant="contained">
                        New fund
                    </Button>
                </Link>

            </div>

            <div className="data-table">
                {
                    dataLoaded
                        ? <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        />
                        : <Spinner />
                }
            </div>
        </Paper>
    )
}

export { Funds };