import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { NavLink } from "react-router-dom";
import { Avatar, Button, Divider, Tooltip, Typography } from '@mui/material';
import { useAuth } from "../../auth";
import { Settings } from "@mui/icons-material";
import { AccessTokenProviderService } from "../../core/services/access-token-provider.service";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SummarizeIcon from '@mui/icons-material/Summarize';

import './sidebar.css';
import Stack from "@mui/system/Stack";

function Sidebar() {
  const { user } = useAuth();

  const userName = user.isAuthenticated
    ? `${user?.firstName[0]}${user?.lastName[0]}`
    : "AU";

  const title = user.isAuthenticated
    ? `${user?.firstName} ${user?.lastName}`
    : "Anonymous User";

  const logoutHandler = () => {
    AccessTokenProviderService.resetToken();

    window.dispatchEvent(new Event("storage"));
  }

  return (

    <div>
      <Stack
        mt={2}
        mb={2}
        ml={4}
        direction='row'
        alignItems='center'
        spacing={3}>
        <div className="logo">Qlerk</div>
        <Tooltip title={title}>
          <Avatar sx={{ bgcolor: "#1565c0", width: '36px', height: '36px' }}>
            {userName}
          </Avatar>
        </Tooltip>

        {
          user.isAuthenticated
            ?
            <Button
              onClick={logoutHandler}
              variant="outlined"
              className="logout-button"
              size="small"
            >
              Logout
            </Button>
            :
            null
        }
      </Stack>
      <List className='sidebar'>
        <Divider />

        <ListItem className='sidebar-menu-item'>
          <NavLink className={({ isActive }) => isActive ? 'selected' : ''} to='/'>
            <DashboardIcon />

            <span>Monitoring</span>
          </NavLink>
        </ListItem>

        <ListItem className='sidebar-menu-item'>
          <NavLink className={({ isActive }) => isActive ? 'selected' : ''} to='/guideline-creator'>
            <AddCircleOutlineRoundedIcon />

            <span>Guideline Creator</span>
          </NavLink>
        </ListItem>

        <ListItem className='sidebar-menu-item'>
          <NavLink className={({ isActive }) => isActive ? 'selected' : ''} to='/funds'>
            <ContentCopyIcon />

            <span>Funds</span>
          </NavLink>
        </ListItem>

        <ListItem className='sidebar-menu-item'>
          <NavLink className={({ isActive }) => isActive ? 'selected' : ''} to='/reports'>
            <SummarizeIcon />

            <span>Reports</span>
          </NavLink>
        </ListItem>

        <Divider />

        <Typography className="settings-title" ml="35px" mt="10px">
          Settings
        </Typography>

        <ListItem className='sidebar-menu-item'>
          <NavLink className={({ isActive }) => isActive ? 'selected' : ''} to='/settings'>
            <Settings />

            <span>Settings</span>
          </NavLink>
        </ListItem>

      </List>
    </div>
  );
}

export default Sidebar;
