export class CfiDataModelConverter {
    private static groupDelimiter = ' ';

    static fromListToGroups(cfiCodesList: string[]): string[][] {
        if (!cfiCodesList?.length) {
            return [];
        }

        const groups: string[][] = [];
        let currentGroup: string[] = [];

        for (let i = 0; i < cfiCodesList.length; i++) {
            const cfiCode = cfiCodesList[i];

            if (cfiCode !== this.groupDelimiter) {
                currentGroup.push(cfiCode);

                if (i === cfiCodesList.length - 1) {
                    groups.push(currentGroup);
                    currentGroup = [];
                    continue;
                }
                else {
                    continue;
                }
            }

            if (i !== cfiCodesList.length - 1) {
                groups.push(currentGroup);
                currentGroup = [];
            }
        }

        return groups;
    }

    static fromGroupsToList(cfiCodeGroups: string[][]): string[] {
        if (!cfiCodeGroups?.length) {
            return [];
        }

        return cfiCodeGroups.reduce((cfiCodes, cfiGroup, index) => {
            const cfiCodeList = cfiCodes.concat(cfiGroup);
            if (index !== cfiCodeGroups.length - 1) {
                cfiCodeList.push(this.groupDelimiter);
            }

            return cfiCodeList;
        }, [])
    }
}