import axios from "axios";

const ocpApimSubscriptionKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY!;

const httpClient = axios.create({
    headers: {
        "Ocp-Apim-Subscription-Key": ocpApimSubscriptionKey
    }
});

httpClient.interceptors.request.use((config) => {
    if (config.headers) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config;
})

httpClient.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    if (error.response.status == 401) {
        localStorage.removeItem("token");
    }

    return Promise.reject(error);
})

export default httpClient;