import { Alert, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../core/services/auth.service";
import { useAuth } from "../auth";
import { Spinner } from "../spinner";
import { AccessTokenProviderService } from "../core/services/access-token-provider.service";

import "./login.css";

interface ILoginProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

export const Login = ({ onPageChanged }: ILoginProps) => {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => onPageChanged(false), []);

    useEffect(() => {
        if (user.isAuthenticated) navigate('/', { replace: true });
    }, [user.isAuthenticated]);

    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoginProgress, setLoginProgress] = useState<boolean>(false);

    const handleUserLogin = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        setLoginProgress(true);

        const loginResult = await AuthService.login(userName, password);
        if (Array.isArray(loginResult)) {
            setErrors(loginResult);

            setLoginProgress(false);

            return;
        }

        AccessTokenProviderService.addToken(loginResult.accessToken);

        setUser(loginResult.user);

        setLoginProgress(false);
    }

    return (
        <div className='login-container'>
            <Paper className='login-container-form' elevation={3}>
                <div>
                    IGM Tool
                </div>
                {
                    !isLoginProgress
                        ? <form onSubmit={handleUserLogin}>
                            <h6>
                                <TextField
                                    value={userName}
                                    onChange={event => setUserName(event.target.value)}
                                    className='login-control'
                                    label="User Name"
                                    variant="outlined"
                                />
                            </h6>

                            <h6>
                                <TextField
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    className='login-control'
                                    type="password"
                                    label="Password" variant="outlined"
                                />
                            </h6>

                            <Button
                                type="submit"
                                className='login-btn'
                                variant="outlined"
                            >
                                Log In
                            </Button>

                            {
                                errors.length
                                    ? <div className="login-error-container">
                                        {
                                            errors
                                                .map((error: string, idx: number) => (
                                                    <h6 key={idx}>
                                                        <Alert severity="error">
                                                            {error}
                                                        </Alert>
                                                    </h6>
                                                ))
                                        }
                                    </div>
                                    : ''
                            }
                        </form>

                        : <div className="spinner-container">
                            <Spinner />
                        </div>
                }
            </Paper>
        </div>
    )
}