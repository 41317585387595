import dayjs from 'dayjs';
import { IncidentStatusType } from '../../../core/enums/incident-status-type.enum';
import { IncidentStatus } from '../../../core/models/incident-status';
import './guideline-incident-status.css'

export const GuidelineIncidentStatus =
    (props: { status: IncidentStatus, mutedDate: null | Date }) => {
        const getFormattedDate = (date: Date) => {
            return dayjs(date).format("DD.MM.YYYY")
        }

        const getSinceDate = () => {
            return getFormattedDate(props.status.statusChangedDate);
        }

        const getStatusClassName = (status: IncidentStatusType) => {
            let className = 'incident-status-label';
            switch (status) {
                case IncidentStatusType.ActiveBreach:
                    className += ' active-breach';
                    break;
                case IncidentStatusType.PassiveBreach:
                case IncidentStatusType.Escalation:
                    className += ' escalation';
                    break;
                case IncidentStatusType.Resolved:
                    className += ' resolved';
                    break;
            }

            return className;
        }

        switch (props.status.status) {
            case IncidentStatusType.Open:
                return <span className={getStatusClassName(IncidentStatusType.Open)}>Since: {getSinceDate()}</span>
            case IncidentStatusType.Escalation:
                return <span className={getStatusClassName(IncidentStatusType.Escalation)}>Since: {getSinceDate()}</span>
            case IncidentStatusType.PassiveBreach:
                return <span className={getStatusClassName(IncidentStatusType.PassiveBreach)}>Muted until: {getFormattedDate(props.mutedDate!)}</span>
            case IncidentStatusType.ActiveBreach:
                return <span className={getStatusClassName(IncidentStatusType.ActiveBreach)}>Since: {getSinceDate()}</span>
            case IncidentStatusType.Resolved:
                return <span className={getStatusClassName(IncidentStatusType.Resolved)}>Since: {getSinceDate()}</span>
        }
    }