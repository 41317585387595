import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { GuidelineListItem } from "../core/models/guideline-list-item";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from "@mui/icons-material/Restore";
import ConfirmationDialog from "../modal-dialogs/confirmation-dialog";
import { GuidelineStatus } from "../core/enums/guideline-status.enum";
import { Box, Tab, Tabs } from "@mui/material";
import { Spinner } from "../spinner";
import { GuidelineReadModel } from "../core/models/guideline-read.model";
import { TabPanel } from "../common/tab-panel";
import { GuidelineService } from "../core/services/guideline.service";

interface IGuidelineTableProps {
    handleModeChange: (isListModeSet: boolean) => void;
    handelGuidelineEdit: (guidelineFormItem: GuidelineReadModel | null) => void;
}

interface IGuidelineActions {
    edit: (guidelineId: string) => void;
    additional: (guidelineId: string) => void;
}

interface ITabContentProps {
    gudelineRows: GuidelineListItem[];
    guidelineActions: IGuidelineActions;
    takeActionIfNeeded: (isGuidelineActionApproved: boolean) => void;
    additionalActionIcon: React.ReactNode;
    isConfirmationDialogOpened: boolean;
    confirmDialogTitle: string;
}

const TabContent = (
    {
        gudelineRows,
        guidelineActions,
        takeActionIfNeeded,
        additionalActionIcon,
        isConfirmationDialogOpened,
        confirmDialogTitle,
    }: ITabContentProps
) => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Id</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Funds</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            gudelineRows.map((row: GuidelineListItem) => (
                                <TableRow
                                    key={row.token}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{row.token}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.funds}</TableCell>
                                    <TableCell width={100} align="center">
                                        <IconButton
                                            onClick={() => guidelineActions.edit(row.token)}
                                            color="primary"
                                            component="span">
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => guidelineActions.additional(row.token)}
                                            component="span">
                                            {additionalActionIcon}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationDialog
                onClose={takeActionIfNeeded}
                open={isConfirmationDialogOpened}
                title={confirmDialogTitle}
            />
        </>
    )
}

export const GuidelineTable = ({ handleModeChange, handelGuidelineEdit }: IGuidelineTableProps) => {
    const [gudelineRows, setGudelineRows] = useState([] as GuidelineListItem[]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isConfirmationDialogOpened, setConfirmationDialogOpenedFlag] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [guidelineIdToAction, setGuidelineIdToAction] = useState<string>("");

    useEffect(() => loadFundGuidelines(tabValue + 1), [tabValue]);

    function loadFundGuidelines(status: GuidelineStatus) {
        if (dataLoaded) setDataLoaded(false);

        GuidelineService
            .getGuidelines(status)
            .then((results: GuidelineListItem[]) => {
                setGudelineRows(results);
                setDataLoaded(true);
            });
    };

    const editGuideline = (guidelineToken: string) => {
        GuidelineService
            .getGuidelineByToken(guidelineToken)
            .then((result: GuidelineReadModel | null) => {
                handelGuidelineEdit(result || null);
                handleModeChange(false);
            });
    };

    const removeGuideline = (guidelineId: string) => {
        setGuidelineIdToAction(guidelineId);
        setConfirmationDialogOpenedFlag(true);
    };

    const restoreGuideline = (guidelineId: string) => {
        setGuidelineIdToAction(guidelineId);
        setConfirmationDialogOpenedFlag(true);
    };

    const removeGuidelineIfNeeded = (isGuidelineRemovalApproved: boolean) => {
        if (!isGuidelineRemovalApproved) {
            setGuidelineIdToAction("");
            setConfirmationDialogOpenedFlag(false);
            return;
        }

        GuidelineService
            .removeGuideline(guidelineIdToAction)
            .then(() => {
                setGuidelineIdToAction("");
                setConfirmationDialogOpenedFlag(false);
                loadFundGuidelines(tabValue + 1);
            });
    };

    const restoreGuidelineIfNeeded = (isGuidelineRestoreApproved: boolean) => {
        if (!isGuidelineRestoreApproved) {
            setGuidelineIdToAction("");
            setConfirmationDialogOpenedFlag(false);
            return;
        }

        GuidelineService.restoreGuideline(guidelineIdToAction)
            .then(() => {
                setGuidelineIdToAction("");
                setConfirmationDialogOpenedFlag(false);
                loadFundGuidelines(tabValue + 1);
            });
    }

    const handleChangeTab = (event: React.SyntheticEvent, newTabValue: GuidelineStatus) => setTabValue(newTabValue);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}>
                    <Tab label="Active" id="active-tab" />
                    <Tab label="Archived" id="archived-tab" />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                {
                    dataLoaded
                        ? <TabContent
                            gudelineRows={gudelineRows}
                            guidelineActions={{ edit: editGuideline, additional: removeGuideline }}
                            takeActionIfNeeded={removeGuidelineIfNeeded}
                            isConfirmationDialogOpened={isConfirmationDialogOpened}
                            additionalActionIcon={<DeleteIcon color="error" />}
                            confirmDialogTitle={"Are you sure you want to delete ?"}
                        />
                        : <Spinner />
                }
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                {
                    dataLoaded
                        ?
                        <TabContent
                            gudelineRows={gudelineRows}
                            guidelineActions={{ edit: editGuideline, additional: restoreGuideline }}
                            takeActionIfNeeded={restoreGuidelineIfNeeded}
                            isConfirmationDialogOpened={isConfirmationDialogOpened}
                            additionalActionIcon={<RestoreIcon color="success" />}
                            confirmDialogTitle={"Are you sure you want to restore ?"}
                        />
                        : <Spinner />
                }
            </TabPanel>
        </Box>
    );
};