import './cfi-block-item-title.css';

export const CfiBlockItemTitle = (props: any) => {
    const { title, subtitle } = props;

    return (
        <div>
            <div className="cfi-block-item-title">
                {title}
            </div>

            <div className="cfi-block-item-subtitle">
                {subtitle}
            </div>
        </div>
    );
}