import { Grid } from "@mui/material";
import { GuidelineCheckResultSummary } from "../../core/models/guideline-check-result-summary";
import { IncidentReadModel } from "../../core/models/incident-read-model";
import { getMonitoringStatus } from "../../dashboard";
import { GuidelineCheckResultItem } from "../guideline-check-result-item";
import { GuidelineIncident } from "../guideline-incident";

import "./guideline-check-summary.css";

interface IGuidelineCheckSummaryProps {
    summary: GuidelineCheckResultSummary;
    incident: IncidentReadModel | null;
    fundToken: string;
    dailyHoldingsToken: string;
}

const GuidelineCheckSummary = ({ summary, incident, fundToken, dailyHoldingsToken }: IGuidelineCheckSummaryProps) => (
    <Grid item className="summary">
        <div className="summary-title">
            <h3>Check Summary</h3>
            
            <span>
                {
                    getMonitoringStatus(summary.checkResult.status)
                }
            </span>
        </div>

        <div className="summary-content">
            <div className="summary-content-row">
                <GuidelineCheckResultItem title="GUIDELINE NAME" subtitle={summary.guidelineName} />

                <GuidelineCheckResultItem title="FUND" subtitle={summary.fundName} />

                <GuidelineCheckResultItem title="Total AuM (USD)" subtitle={summary.totalDailyHoldingsCost} />
            </div>

            <div className="summary-content-row">
                <GuidelineCheckResultItem title="GUIDELINE ID and Version " subtitle={summary.guidelineToken} />

                <GuidelineCheckResultItem title="Total Value Exposed to Guideline" subtitle={summary.totalExposedValue} />
            </div>

            {
                incident && <GuidelineIncident
                    incident={incident}
                    fundToken={fundToken}
                    dailyHoldingsToken={dailyHoldingsToken}/>
            }
        </div>
    </Grid>
);

export { GuidelineCheckSummary }