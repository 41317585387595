import { CfiItem } from "./cfi-item";

export class CfiAttributeListModel {
    longDescription: string = '';
    values: CfiItem[] = [];

    constructor(
        longDescription: string,
        values: CfiItem[]) {
        this.longDescription = longDescription;
        this.values = values;
    }

    static createEmpty(): CfiAttributeListModel {
        return new CfiAttributeListModel('', []);
    }
}