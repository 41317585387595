import { Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useEffect, useState } from 'react';
import { IFundLookupItem } from "../../core/models/fund";
import { FundService } from "../../core/services/fund.service";
import { GuidelineStatus } from "../../core/enums/guideline-status.enum";
import { GuidelineListItem } from "../../core/models/guideline-list-item";
import { ILookUpItem } from "../../core/models/look-up-item";
import { GuidelineService } from "../../core/services/guideline.service";
import { DropdownOptionItem } from "../../core/models/dropdown-option-item";
import { DailyHoldingsCheckOverallResult } from "../../core/enums/daily-holdings-check-overall-result.enum";
import { CompanyService } from "../../core/services/company.service";

import "./table-filter.css";

interface IApplyFilterParams {
    status: number;
    fund?: string;
    guideline?: string;
    fundManager?: string;
    custodian?: string;
    assetManager?: string;
}

interface ITableFilterProps {
    onFilter: (params: IApplyFilterParams) => void;
    values: IApplyFilterParams;
}

const dropdownAllOptionItem = { name: "All", token: "00000000-0000-0000-0000-000000000000" };

const statusOptions = [
    new DropdownOptionItem<number>("All", 0),
    new DropdownOptionItem<number>("Passed", DailyHoldingsCheckOverallResult.Passed),
    new DropdownOptionItem<number>("Missing Data", DailyHoldingsCheckOverallResult.MissingData),
    new DropdownOptionItem<number>("Issue Detected", DailyHoldingsCheckOverallResult.IssueDetected),
]

const progressOptions = [
    new DropdownOptionItem<number>("All", 0),
    new DropdownOptionItem<number>("Completed", 1),
]

const TableFilter = ({ onFilter, values }: ITableFilterProps) => {
    useEffect(() => {
        loadFunds();
        loadGuidelines(GuidelineStatus.Active);
        loadRelatedCompanies();
    }, []);

    const statusValue = values.status ?? statusOptions[0].value;
    const [status, setStatus] = useState<number>(statusValue);

    const [progress, setProgress] = useState<number>(progressOptions[0].value);

    const fundValue = values.fund ?? dropdownAllOptionItem.token;
    const [fund, setFund] = useState<string>(fundValue);

    const guidelineValue = values.guideline ?? dropdownAllOptionItem.token;
    const [guideline, setGuideline] = useState<string>(guidelineValue);

    const fundManagerValue = values.fundManager ?? dropdownAllOptionItem.token;
    const [fundManager, setFundManager] = useState<string>(fundManagerValue);

    const custodianValue = values.custodian ?? dropdownAllOptionItem.token;
    const [custodian, setCustodian] = useState<string>(custodianValue);

    const assetManagerValue = values.assetManager ?? dropdownAllOptionItem.token;
    const [assetManager, setAssetManager] = useState<string>(assetManagerValue);

    const [fundsDataLoaded, setFundsDataLoaded] = useState<boolean>(false);
    const [fundOptions, setFundOptions] = useState<ILookUpItem[]>([
        dropdownAllOptionItem
    ]);

    const [guidelinesDataLoaded, setGuidelinesDataLoaded] = useState<boolean>(false);
    const [guidelineOptions, setGuidelineOptions] = useState<ILookUpItem[]>([
        dropdownAllOptionItem
    ]);

    const [relatedCompaniesDataLoaded, setRelatedCompaniesDataLoaded] = useState<boolean>(false);
    const [companyRelatedOptions, setCompanyRelatedOptions] = useState<ILookUpItem[]>([]);

    function loadFunds() {
        if (fundsDataLoaded) setFundsDataLoaded(false);

        FundService
            .getFunds(true)
            .then((result: IFundLookupItem[]) => {
                setFundOptions([...result.map(fund => {
                    return { token: fund.token, name: fund.name }
                }), dropdownAllOptionItem]);
                setFundsDataLoaded(true);
            })
    };

    function loadGuidelines(status: GuidelineStatus) {
        if (guidelinesDataLoaded) setGuidelinesDataLoaded(false);

        GuidelineService
            .getGuidelines(status, true)
            .then((results: GuidelineListItem[]) => {
                setGuidelineOptions([...results.map(({ token, name }) => ({ token, name })), dropdownAllOptionItem]);
                setGuidelinesDataLoaded(true);
            });
    };

    function loadRelatedCompanies() {
        if (relatedCompaniesDataLoaded) setRelatedCompaniesDataLoaded(false);

        CompanyService
            .getRelatedCompanies()
            .then((results: ILookUpItem[]) => {
                setCompanyRelatedOptions([...results.map(({ token, name }) => ({ token, name })), dropdownAllOptionItem]);
                setRelatedCompaniesDataLoaded(true);
            });
    };

    const changeStatusHandler = (event: SelectChangeEvent) => setStatus(+event.target.value);
    const changeProgressHandler = (event: SelectChangeEvent) => setProgress(+event.target.value);
    const changeFundHandler = (event: SelectChangeEvent) => setFund(event.target.value);
    const changeGuidelineHandler = (event: SelectChangeEvent) => setGuideline(event.target.value);
    const changeFundManagerHandler = (event: SelectChangeEvent) => setFundManager(event.target.value);
    const changeCustodianHandler = (event: SelectChangeEvent) => setCustodian(event.target.value);
    const changeAssetManagerHandler = (event: SelectChangeEvent) => setAssetManager(event.target.value);

    const applyFilter = () => {
        const params = {
        } as IApplyFilterParams;

        const isStatusSet = status !== statusOptions[0].value;
        if (isStatusSet) {
            params.status = status;
        }

        const isFundSet = fund !== dropdownAllOptionItem.token;
        if (isFundSet) {
            params.fund = fund;
        }

        const isGuidelineSet = guideline !== dropdownAllOptionItem.token;
        if (isGuidelineSet) {
            params.guideline = guideline;
        }

        const isFundManagerSet = fundManager !== dropdownAllOptionItem.token;
        if (isFundManagerSet) {
            params.fundManager = fundManager;
        }

        const isCustodianSet = custodian !== dropdownAllOptionItem.token;
        if (isCustodianSet) {
            params.custodian = custodian;
        }

        const isAssetManagerSet = assetManager !== dropdownAllOptionItem.token;
        if (isAssetManagerSet) {
            params.assetManager = assetManager;
        }

        onFilter(params);
    }

    return (
        <Paper className="filter">
            <div className="filter-row">
                <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status.toString()}
                        label="Status"
                        onChange={changeStatusHandler}
                    >
                        {
                            statusOptions.map(option => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>Monitoring Progress</InputLabel>
                    <Select
                        value={progress.toString()}
                        label="Monitoring Progress"
                        onChange={changeProgressHandler}>
                        {
                            progressOptions.map(option => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>Fund</InputLabel>
                    <Select
                        value={fund}
                        label="Fund"
                        onChange={changeFundHandler}>
                        {
                            fundOptions.map(option => {
                                return <MenuItem key={option.token} value={option.token}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>Guideline</InputLabel>
                    <Select
                        value={guideline}
                        label="Guideline"
                        onChange={changeGuidelineHandler}
                        disabled={!guidelinesDataLoaded}>
                        {
                            guidelineOptions.map(option => {
                                return <MenuItem key={option.token} value={option.token}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <div className="filter-row">
                <FormControl fullWidth>
                    <InputLabel>Fund Manager</InputLabel>
                    <Select
                        value={fundManager}
                        label="Fund Manager"
                        onChange={changeFundManagerHandler}
                        disabled={!relatedCompaniesDataLoaded}>
                        {
                            companyRelatedOptions.map(option => {
                                return <MenuItem key={option.token} value={option.token}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>Custodian</InputLabel>
                    <Select
                        value={custodian}
                        label="Custodian"
                        onChange={changeCustodianHandler}
                        disabled={!relatedCompaniesDataLoaded}>
                        {
                            companyRelatedOptions.map(option => {
                                return <MenuItem key={option.token} value={option.token}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>Asset Manager</InputLabel>
                    <Select
                        value={assetManager}
                        label="Asset Manager"
                        onChange={changeAssetManagerHandler}
                        disabled={!relatedCompaniesDataLoaded}>
                        {
                            companyRelatedOptions.map(option => {
                                return <MenuItem key={option.token} value={option.token}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <div className="filter-row">
                <Button variant="contained" onClick={() => applyFilter()}>
                    Apply
                </Button>
            </div>
        </Paper>
    );
}

export { TableFilter };
export type { IApplyFilterParams };