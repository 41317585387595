import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react"
import { SecMasterAttribute } from "../../core/models/sec-master-attribute"
import { SecMasterService } from "../../core/services/sec-master.service";
import { Spinner } from "../../spinner";

export const Secmaster = () => {
    const [secMasterAttributes, setSecMasterAttributes] = useState<SecMasterAttribute[]>([]);
    const [isSecmasterLoaded, setIsSecmasterLoaded] = useState<boolean>(false);

    useEffect(() => {
        SecMasterService.getAttributes()
            .then((attributes: SecMasterAttribute[]) => {
                setIsSecmasterLoaded(true);
                setSecMasterAttributes(attributes);
            })
    }, []);

    return (
        <>
            {
                !isSecmasterLoaded
                    ? <Spinner />
                    : <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650, marginTop: "20px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Values</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    secMasterAttributes.map((attr: SecMasterAttribute, index: number) => {
                                        return (
                                            <TableRow
                                                key={index}
                                            >
                                                <TableCell align="left">{attr.name}</TableCell>
                                                <TableCell align="left">{attr.dataType?.name}</TableCell>
                                                <TableCell align="left">{attr.values?.length ? attr.values.join(', ') : '-'}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </>
    )
}