import { DailyHolding } from "../../core/models/daily-holding";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import "./holdings-table.css";

interface IHoldingsTableProps {
    holdings: DailyHolding[];
}

const commonColumns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'isin', headerName: 'ISIN', width: 200 },
    { field: 'unitPrice', headerName: 'Unit Price', width: 200 },
    { field: 'unitPriceFundCurrency', headerName: 'Unit Price Fund Currency', width: 200 },
    { field: 'currency', headerName: 'Currency', width: 200 },
    { field: 'quantity', headerName: 'Quantity', width: 200 },
];

const HoldingsTable = ({ holdings }: IHoldingsTableProps) => {
    const uniqueHoldingsAttributes = Object.values(holdings.reduce((acc, { attributes }) => ({...acc, ...attributes.map(({ name }) => name)}), {}));
    
    const attributeColumns = uniqueHoldingsAttributes.map((key: any) => ({ field: key, headerName: key, minWidth: 250, flex: 1 }));

    const columns: GridColDef[] = [...commonColumns, ...attributeColumns];

    const rows = holdings.map((holding) => {
        const commonRows = {
            id: holding.token,
            isin: holding.isin,
            unitPrice: holding.unit_price,
            unitPriceFundCurrency: holding.unit_price_fund_currency,
            currency: holding.currency,
            quantity: holding.quantity,
        };

       const attributeRows = holding.attributes.reduce((acc, { name, value }) => uniqueHoldingsAttributes.indexOf(name) !== -1 ? {...acc, [name]: value} : acc, {});        

        return {
            ...commonRows,
            ...attributeRows,
        };
    });

    return (
        <div className="data-table">
            <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[100]}
            />
        </div>
    );
}

export { HoldingsTable };