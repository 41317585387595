import httpClient from "../../http/http-client";
import { SecMasterAttribute } from "../models/sec-master-attribute";

const secMasterApiUrl = process.env.REACT_APP_SECMASTER_API_URL!;

export const SecMasterService = {
    getAttributes: async (): Promise<SecMasterAttribute[]> => {
        const response =
            await httpClient.get(secMasterApiUrl);

        return response.data.secmasterAttributes;
    }
}