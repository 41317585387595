import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from "date-fns";
import { DailyHoldingsCheckResult } from '../core/enums/daily-holdings-check-result.enum';
import Chip from '@mui/material/Chip';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CheckHoldingsDialog from '../modal-dialogs/check-holdings-dialog';
import { FundService } from '../core/services/fund.service';
import { GuidelineMonitoringItem } from '../core/models/guideline-monitoring-item';
import { DailyHoldingsCheckOverallResult } from '../core/enums/daily-holdings-check-overall-result.enum';
import { useAuth } from '../auth';
import { useEffect, useRef, useState } from 'react';
import { IApplyFilterParams, TableFilter } from './table-filter';

import './dashboard.css';

interface IDashboardProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

const getMonitoringStatus = (result: DailyHoldingsCheckResult | DailyHoldingsCheckOverallResult) => {
    if (result == DailyHoldingsCheckResult.MissingData
        || result == DailyHoldingsCheckOverallResult.MissingData) {
        return <Chip sx={{ width: '100%', cursor: "pointer" }} label="Missing Data" color="warning" />;
    }

    if (
        result == DailyHoldingsCheckResult.MaxValidationFailed
        || result == DailyHoldingsCheckResult.MinValidationFailed
        || result == DailyHoldingsCheckOverallResult.IssueDetected
    ) {
        return <Chip sx={{ width: '100%', cursor: "pointer" }} label="Issue Detected" color="error" />;
    }

    return <Chip sx={{ width: '100%', cursor: "pointer" }} label="Clear" color="success" />
}


function Dashboard({ onPageChanged }: IDashboardProps) {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [rows, setRows] = useState([] as GuidelineMonitoringItem[]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [checkHoldingsDialogOpened, setCheckHoldingsDialogOpened] = useState(false);

    const filter = useRef({} as IApplyFilterParams);

    useEffect(() => {
        onPageChanged(true);
        loadCheckResults();
    }, []);

    useEffect(() => {
        if (!user.isAuthenticated) {
            navigate('/login', { replace: true });
        }
    }, [user.isAuthenticated]);

    let rowsCounter = 1;

    const formatDate = (date: Date) => {
        return format(date, "dd.MM.yyyy");
    };

    function loadCheckResults(params?: IApplyFilterParams) {
        if (dataLoaded) {
            setDataLoaded(false);
        }

        FundService
            .getDailyHoldingsCheckResults(params)
            .then((results: GuidelineMonitoringItem[]) => {
                setRows(results);
                setDataLoaded(true);
            });
    }

    const openCheckHoldingsDialog = () => setCheckHoldingsDialogOpened(true);

    const closeCheckHoldingsDialog = (result: boolean) => {
        setCheckHoldingsDialogOpened(false);
        if (!result) {
            return;
        }

        loadCheckResults();
    };

    const applyFilter = (params: IApplyFilterParams) => {
        filter.current = params;
        loadCheckResults(params);
    };

    let tableContainerInnerContent = dataLoaded
        ? <TableContainer component={Paper}>
            <TableFilter values={filter.current} onFilter={applyFilter} />

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Id</TableCell>
                        <TableCell align="left">Guideline name</TableCell>
                        <TableCell align="left">Fund Name</TableCell>
                        <TableCell align="left">Monitoring Progress</TableCell>
                        <TableCell align="left">Monitoring Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={++rowsCounter}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{row.guidelineMonitoringInfo.guidelineToken}</TableCell>
                            <TableCell align="left">{row.guidelineMonitoringInfo.guidelineName}</TableCell>
                            <TableCell align="left">{row.fundMonitoringInfo.fundName}</TableCell>
                            <TableCell align="left">
                                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <span style={{ color: '#00062B', fontWeight: 500, fontSize: '14px' }}>
                                        Completed
                                    </span>
                                    <span style={{ color: '#AEAEAE', fontWeight: 500, fontSize: '10px' }}>
                                        <span>Last updated </span>
                                        <span>{formatDate(new Date(row.timestamp))}</span>
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell align="left">
                                <Link to={`/monitoring/funds/${row.fundMonitoringInfo.fundToken}/guidelines/${row.guidelineMonitoringInfo.guidelineToken}`} className="status-link">
                                    {getMonitoringStatus(row.result)}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        : <div className='circular-progress-container'><CircularProgress size='5rem' /></div>

    return (
        <div className='dashboard-container'>
            <h5 style={{ textAlign: 'right' }}>
                <Button onClick={openCheckHoldingsDialog} variant="outlined">Check holdings</Button>
            </h5>

            {tableContainerInnerContent}

            <CheckHoldingsDialog
                open={checkHoldingsDialogOpened}
                onClose={closeCheckHoldingsDialog}
            />
        </div>
    );
}

export { Dashboard, getMonitoringStatus };