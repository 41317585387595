import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { DailyHoldings } from "../../core/models/daily-holdings";
import { FundService } from "../../core/services/fund.service";

export interface ModalDialogProps {
    open: boolean;
    onClose: (reult: boolean) => void;
}

export default function CheckHoldingsDialog(props: ModalDialogProps) {
    const { onClose, open } = props;

    const [dailyHoldingsJsonFile, setDailyHoldingsJsonFile] = useState<File>({} as File);
    const [dailyHoldingsJsonFileContent, setDailyHoldingsJsonFileContent] = useState<string>('');

    const [errors, setErrors] = useState<string[]>([]);

    const cancelDailyHoldingsCheck = () => {
        setDailyHoldingsJsonFileContent('');
        setDailyHoldingsJsonFile({} as File);
        setErrors([]);

        onClose(false);
    };

    const checkDailyHoldings = () => {
        const dailyHoldings = JSON.parse(dailyHoldingsJsonFileContent) as DailyHoldings;
        FundService.checkDailyHoldings(dailyHoldings)
            .then(() => {
                setErrors([]);
                onClose(true);
            })
            .catch(error => {
                setErrors(["Unable to find specified fund"]);
            });
    }

    const handleDailyHoldingsFileSelection = (event: React.FormEvent<HTMLInputElement>) => {
        const dailyHoldingsJson = (event.target as HTMLInputElement)!.files![0];
        setDailyHoldingsJsonFile(dailyHoldingsJson);

        const fileReader = new FileReader();
        fileReader.readAsText(dailyHoldingsJson, "UTF-8");

        fileReader.onload = () => {
            setDailyHoldingsJsonFileContent(fileReader.result as string);
        };
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Upload Daily Holdings File to Check</DialogTitle>
            <DialogContent>
                <input style={{ display: "none" }} onChange={handleDailyHoldingsFileSelection} id="ulpoad-file-button" type="file" />
                <label htmlFor="ulpoad-file-button">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                    <span style={{ marginLeft: '10px' }}>{dailyHoldingsJsonFile.name}</span>
                </label>

                {
                    errors.length
                        ? <div className="login-error-container">
                            {
                                errors
                                    .map((error: string, idx: number) => (
                                        <h6 key={idx}>
                                            <Alert severity="error">
                                                {error}
                                            </Alert>
                                        </h6>
                                    ))
                            }
                        </div>
                        : ''
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelDailyHoldingsCheck}>Cancel</Button>
                <Button onClick={checkDailyHoldings}>Check</Button>
            </DialogActions>
        </Dialog>
    );
}
