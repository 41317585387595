import { useState } from "react";
import { CfiAttributeList } from "../cfi-attribute-list";
import { CfiBlockItemList } from "../cfi-block-item-list";
import { cfiCategories } from "../core/constants/cfi-categories";
import { commonConstants } from "../core/constants/common.const";
import { CfiAttributeListModel } from "../core/models/cfi-attribute-list";
import { CfiCategory } from "../core/models/cfi-category";
import { CfiGroup } from "../core/models/cfi-group";
import { CfiLetterCodeStateSelection } from "../core/models/cfi-letter-code-state-selection";
import { cfiConverter } from "../core/services/cfi-converter";

import "./cfi-code-builder.css";

export const CfiCodeBuilder = (props: any) => {
    const { initialCodes, codesChanged } = props;

    const initialDataModel = setupDataModel();
    const initialSelection = initialDataModel.letterCodesSelection;

    const [categoryLetterCode, setCategoryLetterCode] = useState(initialSelection.categoryCode);
    const [groupLetterCode, setGroupLetterCode] = useState(initialSelection.groupCode);

    const [attributeList1LetterCodes, setAttributeList1LetterCodes] =
        useState(initialSelection.attributeList1);

    const [attributeList2LetterCodes, setAttributeList2LetterCodes] =
        useState(initialSelection.attributeList2);

    const [attributeList3LetterCodes, setAttributeList3LetterCodes] =
        useState(initialSelection.attributeList3);

    const [attributeList4LetterCodes, setAttributeList4LetterCodes] =
        useState(initialSelection.attributeList4);

    const [cfiGroups, setCfiGroups] = useState(initialDataModel.groups);

    const [attributeList1, setAttributeList1] = useState(initialDataModel.attributeList1);
    const [attributeList2, setAttributeList2] = useState(initialDataModel.attributeList2);
    const [attributeList3, setAttributeList3] = useState(initialDataModel.attributeList3);
    const [attributeList4, setAttributeList4] = useState(initialDataModel.attributeList4);

    const setDefaultGroupLetterCode = () => setGroupLetterCode(commonConstants.undefinedCfiLetterCode);

    const resetAttributeLists = () => {
        setAttributeList1(CfiAttributeListModel.createEmpty());
        setAttributeList2(CfiAttributeListModel.createEmpty());
        setAttributeList3(CfiAttributeListModel.createEmpty());
        setAttributeList4(CfiAttributeListModel.createEmpty());
    }

    const resetAttributeListsLetterCodes = () => {
        setAttributeList1LetterCodes([]);
        setAttributeList2LetterCodes([]);
        setAttributeList3LetterCodes([]);
        setAttributeList4LetterCodes([]);
    };

    const categoryLetterCodeChanged = (selectedCategoryLetterCode: string) => {
        setCategoryLetterCode(selectedCategoryLetterCode);

        const newCategoryGroups = cfiCategories
            .find((category: CfiCategory) => category.letterCode === selectedCategoryLetterCode)!.groups;    
        setCfiGroups(newCategoryGroups);

        const selectedAttributeList1LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList2LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList3LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList4LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const categoryDefaultGroup = newCategoryGroups
        .find(({ letterCode }) => letterCode === commonConstants.undefinedCfiLetterCode);
        setDefaultGroupLetterCode();

        resetAttributeLists();
        resetAttributeListsLetterCodes();

        setAttributeList1(categoryDefaultGroup!.attributeList1);
        setAttributeList2(categoryDefaultGroup!.attributeList2);
        setAttributeList3(categoryDefaultGroup!.attributeList3);
        setAttributeList4(categoryDefaultGroup!.attributeList4);

        setAttributeList1LetterCodes(selectedAttributeList1LetterCodes);
        setAttributeList2LetterCodes(selectedAttributeList2LetterCodes);
        setAttributeList3LetterCodes([commonConstants.undefinedCfiLetterCode]);
        setAttributeList4LetterCodes([commonConstants.undefinedCfiLetterCode]);        

        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: selectedCategoryLetterCode,
            groupCode: commonConstants.undefinedCfiLetterCode,
            attributeList1: selectedAttributeList1LetterCodes,
            attributeList2: selectedAttributeList2LetterCodes,
            attributeList3: selectedAttributeList3LetterCodes,
            attributeList4: selectedAttributeList4LetterCodes,
        });

        codesChanged(newCodes);
    }

    const groupLetterCodeChanged = (selectedGroupLetterCode: string) => {
        const selectedGroup = cfiCategories
            .find((cfiCategory: CfiCategory) => cfiCategory.letterCode === categoryLetterCode)!.groups
            .find((cfiGroup: CfiGroup) => cfiGroup.letterCode === selectedGroupLetterCode)!;

        const selectedAttributeList1LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList2LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList3LetterCodes = [commonConstants.undefinedCfiLetterCode];

        const selectedAttributeList4LetterCodes = [commonConstants.undefinedCfiLetterCode];

        setGroupLetterCode(selectedGroupLetterCode);

        setAttributeList1(selectedGroup.attributeList1);
        setAttributeList2(selectedGroup.attributeList2);
        setAttributeList3(selectedGroup.attributeList3);
        setAttributeList4(selectedGroup.attributeList4);

        setAttributeList1LetterCodes(selectedAttributeList1LetterCodes);
        setAttributeList2LetterCodes(selectedAttributeList2LetterCodes);
        setAttributeList3LetterCodes(selectedAttributeList3LetterCodes);
        setAttributeList4LetterCodes(selectedAttributeList4LetterCodes);

        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: categoryLetterCode,
            groupCode: selectedGroupLetterCode,
            attributeList1: selectedAttributeList1LetterCodes,
            attributeList2: selectedAttributeList2LetterCodes,
            attributeList3: selectedAttributeList3LetterCodes,
            attributeList4: selectedAttributeList4LetterCodes
        });

        codesChanged(newCodes);
    }

    const firstAttributeLetterCodesChanged = (selectedAttributeLetterCodesForList1: string[]) => {
        setAttributeList1LetterCodes(selectedAttributeLetterCodesForList1);

        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: categoryLetterCode,
            groupCode: groupLetterCode,
            attributeList1: selectedAttributeLetterCodesForList1,
            attributeList2: attributeList2LetterCodes,
            attributeList3: attributeList3LetterCodes,
            attributeList4: attributeList4LetterCodes
        });

        codesChanged(newCodes);
    }

    const secondAttributeLetterCodesChanged = (selectedAttributeLetterCodesForList2: string[]) => {
        setAttributeList2LetterCodes(selectedAttributeLetterCodesForList2);

        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: categoryLetterCode,
            groupCode: groupLetterCode,
            attributeList1: attributeList1LetterCodes,
            attributeList2: selectedAttributeLetterCodesForList2,
            attributeList3: attributeList3LetterCodes,
            attributeList4: attributeList4LetterCodes
        });

        codesChanged(newCodes);
    }

    const thirdAttributeLetterCodesChanged = (selectedAttributeLetterCodesForList3: string[]) => {
        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: categoryLetterCode,
            groupCode: groupLetterCode,
            attributeList1: attributeList1LetterCodes,
            attributeList2: attributeList2LetterCodes,
            attributeList3: selectedAttributeLetterCodesForList3,
            attributeList4: attributeList4LetterCodes
        });

        codesChanged(newCodes);
    }

    const fourthAttributeLetterCodesChanged = (selectedAttributeLetterCodesForList4: string[]) => {
        const newCodes = cfiConverter.fromLetterCodeStateSelectionToCodes({
            categoryCode: categoryLetterCode,
            groupCode: groupLetterCode,
            attributeList1: attributeList1LetterCodes,
            attributeList2: attributeList2LetterCodes,
            attributeList3: attributeList3LetterCodes,
            attributeList4: selectedAttributeLetterCodesForList4
        });

        codesChanged(newCodes);
    }

    function setupDataModel(): {
        letterCodesSelection: CfiLetterCodeStateSelection,
        attributeList1: CfiAttributeListModel,
        attributeList2: CfiAttributeListModel,
        attributeList3: CfiAttributeListModel,
        attributeList4: CfiAttributeListModel,
        groups: CfiGroup[]
    } {
        const selection = initialCodes.length
            ? cfiConverter.fromCodesToLetterCodeStateSelection(initialCodes)
            : new CfiLetterCodeStateSelection();

        let groups: CfiGroup[] = [];

        let initialAttributeList1 = CfiAttributeListModel.createEmpty();
        let initialAttributeList2 = CfiAttributeListModel.createEmpty();
        let initialAttributeList3 = CfiAttributeListModel.createEmpty();
        let initialAttributeList4 = CfiAttributeListModel.createEmpty();

        if (selection.categoryCode) {
            groups = cfiCategories
                .find((category: CfiCategory) => category.letterCode === selection.categoryCode)!.groups;

            if (selection.groupCode) {
                const initialGroup = groups
                    .find((cfiGroup: CfiGroup) => cfiGroup.letterCode === selection.groupCode)!;

                initialAttributeList1 = initialGroup.attributeList1;
                initialAttributeList2 = initialGroup.attributeList2;
                initialAttributeList3 = initialGroup.attributeList3;
                initialAttributeList4 = initialGroup.attributeList4;
            }
        }

        return {
            letterCodesSelection: selection,
            attributeList1: initialAttributeList1,
            attributeList2: initialAttributeList2,
            attributeList3: initialAttributeList3,
            attributeList4: initialAttributeList4,
            groups: groups
        }
    }

    return (
        <div style={{ height: '100%'}} className="block-items-container">
            <CfiBlockItemList
                items={cfiCategories}
                itemSelected={categoryLetterCodeChanged}
                letterCode={categoryLetterCode}
                title={'CFI Category'}>
            </CfiBlockItemList>



            <CfiBlockItemList
                items={cfiGroups}
                itemSelected={groupLetterCodeChanged}
                letterCode={groupLetterCode}
                title={'CFI Group'}>
            </CfiBlockItemList>



            <CfiAttributeList
                selectionChanged={firstAttributeLetterCodesChanged}
                selectedLetterCodes={attributeList1LetterCodes}
                list={attributeList1}
                title={'First Attribute'}>
            </CfiAttributeList>



            <CfiAttributeList
                selectionChanged={secondAttributeLetterCodesChanged}
                selectedLetterCodes={attributeList2LetterCodes}
                list={attributeList2}
                title={'Second Attribute'}>
            </CfiAttributeList>



            <CfiAttributeList
                selectionChanged={thirdAttributeLetterCodesChanged}
                selectedLetterCodes={attributeList3LetterCodes}
                list={attributeList3}
                title={'Third Attribute'}>
            </CfiAttributeList>



            <CfiAttributeList
                selectionChanged={fourthAttributeLetterCodesChanged}
                selectedLetterCodes={attributeList4LetterCodes}
                list={attributeList4}
                title={'Fourth Attribute'}>
            </CfiAttributeList>
        </div>
    )
}