import { commonConstants } from "../constants/common.const";
import { CfiLetterCodeStateSelection } from "../models/cfi-letter-code-state-selection";

class CfiConverterService {
    fromCodesToLetterCodeStateSelection(codes: string[]): CfiLetterCodeStateSelection {
        const cfiLetterCodeStateSelection = new CfiLetterCodeStateSelection();

        if (!codes?.length) {
            return cfiLetterCodeStateSelection;
        }

        const firstCode = codes[0];
        cfiLetterCodeStateSelection.categoryCode = firstCode.charAt(0);

        const groupCode = firstCode.charAt(1);
        cfiLetterCodeStateSelection.groupCode = groupCode;

        if (groupCode === commonConstants.undefinedCfiLetterCode) {
            cfiLetterCodeStateSelection.attributeList1 = [commonConstants.undefinedCfiLetterCode];
            cfiLetterCodeStateSelection.attributeList2 = [commonConstants.undefinedCfiLetterCode];
            cfiLetterCodeStateSelection.attributeList3 = [commonConstants.undefinedCfiLetterCode];
            cfiLetterCodeStateSelection.attributeList4 = [commonConstants.undefinedCfiLetterCode];

            return cfiLetterCodeStateSelection;
        }

        for (const code of codes) {
            for (let i = 2; i < 6; i++) {
                const attributeCodeValue = code.charAt(i);
                let attributeList;

                switch (i - 2) {
                    case 0:
                        attributeList = cfiLetterCodeStateSelection.attributeList1;
                        break;
                    case 1:
                        attributeList = cfiLetterCodeStateSelection.attributeList2;
                        break;
                    case 2:
                        attributeList = cfiLetterCodeStateSelection.attributeList3;
                        break;
                    case 3:
                        attributeList = cfiLetterCodeStateSelection.attributeList4;
                        break;
                    default:
                        throw new Error('Unexpected attribute list');
                }

                if (attributeList.indexOf(attributeCodeValue) === -1) {
                    attributeList.push(attributeCodeValue);
                }
            }
        }

        return cfiLetterCodeStateSelection;
    }

    fromLetterCodeStateSelectionToCodes(
        codeStateSelection: CfiLetterCodeStateSelection): string[] {
        const codes: string[] = [];

        if (!codeStateSelection.categoryCode) {
            return codes;
        }

        if (!codeStateSelection.groupCode) {
            codes.push(codeStateSelection.categoryCode);
            return codes;
        }

        const undefinedCode = commonConstants.undefinedCfiLetterCode;

        if (codeStateSelection.groupCode === undefinedCode) {
            codes.push(`${codeStateSelection.categoryCode}${undefinedCode}${undefinedCode}${undefinedCode}${undefinedCode}${undefinedCode}`);
            return codes;
        }

        const allAttributeGroups = [
            codeStateSelection.attributeList1,
            codeStateSelection.attributeList2,
            codeStateSelection.attributeList3,
            codeStateSelection.attributeList4
        ];

        const notEmptyAttributeGroups = [];

        let attributeGroupIndex = 0;
        let attributeGroup = allAttributeGroups[attributeGroupIndex];

        while (attributeGroup?.length) {
            notEmptyAttributeGroups.push(attributeGroup);
            attributeGroupIndex++;
            attributeGroup = allAttributeGroups[attributeGroupIndex];
        }

        if (!notEmptyAttributeGroups.length) {
            codes.push(`${codeStateSelection.categoryCode}${codeStateSelection.groupCode}`)
            return codes;
        }

        return notEmptyAttributeGroups
            .reduce((previousCodeParts: string[], currentLetterCodes: string[]) => {
                let nextCodeParts: string[] = [];

                for (const previousCodePart of previousCodeParts) {
                    const nextCodePartsWithCurrentLetter = currentLetterCodes
                        .map((letterCode: string) => `${previousCodePart}${letterCode}`);

                    nextCodeParts = nextCodeParts.concat(nextCodePartsWithCurrentLetter);
                }

                return nextCodeParts;
            }, [`${codeStateSelection.categoryCode}${codeStateSelection.groupCode}`]);
    }
}

export const cfiConverter = new CfiConverterService();