import httpClient from "../../http/http-client";
import { IUser } from "../models/user";
import { UserRegistrationResult } from "../models/user-registration-result";
import { UserResetPasswordResult } from "../models/user-reset-password-result";

const authUrl = process.env.REACT_APP_AUTH_URL;

export const UserService = {
    getAllUsers: async (searchString: string): Promise<IUser[]> => {
        const usersUrl = `${authUrl}users?search=${searchString}`;
        const response = await httpClient.get<IUser[]>(usersUrl);
        return response.data;
    },

    changeUserRole: async (id: string) => {
        const changeUserRoleUrl = `${authUrl}users/${id}/roles`;
        await httpClient.put<void>(changeUserRoleUrl);
    },

    changeUserStatus: async (id: string) => {
        const changeUserStatusUrl = `${authUrl}users/${id}/status`;
        await httpClient.put<void>(changeUserStatusUrl);
    },

    resetUserPassword: async (userId: string): Promise<UserResetPasswordResult> => {
        const changeUserPasswordUrl = `${authUrl}reset-password`;
        const response =
            await httpClient.post<UserRegistrationResult>(
                changeUserPasswordUrl,
                { id: userId });

        return response.data;
    },

    getCurrentUser: async () => {
        const currentUserUrl = `${authUrl}user`;

        const response =
            await httpClient.get<IUser>(currentUserUrl);

        return response.data;
    }
}