import "./guideline-check-result-item.css";

interface IGuidelineInfoProps {
    title: string;
    subtitle: string | number;
}

const GuidelineCheckResultItem = ({ title, subtitle }: IGuidelineInfoProps) => (
    <div className="guideline-check-result-item">
        <p className="title">
            {title}
        </p>

        <p className="subtitle">
            {subtitle}
        </p>
    </div>
);

export { GuidelineCheckResultItem };