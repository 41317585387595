import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../auth";
import { AccessTokenProviderService } from "../core/services/access-token-provider.service";

interface IReportsProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

const Reports = ({ onPageChanged }: IReportsProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const token = AccessTokenProviderService.getToken();

        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);

    useEffect(() => {
        onPageChanged(true);
    }, []);

    return (
        <div style={{"height" : "100%"}}>
            <iframe 
                title='qlerk' 
                width='100%' 
                height='100%' 
                src='https://app.powerbi.com/reportEmbed?reportId=3e1c6bf4-2a78-41cb-8e60-2b7a1057ae77&autoAuth=true&ctid=7f3f6854-b054-46cd-aa92-edc806a5d94f' 
                frameBorder='0' 
                allowFullScreen={true}>
            </iframe>
        </div>
    )
}

export { Reports };