import httpClient from "../../http/http-client";
import { GuidelineStatus } from "../enums/guideline-status.enum";
import { Guideline } from "../models/guideline";
import { GuidelineCheckHistoryItem } from "../models/guideline-check-history-item";
import { GuidelineListItem } from "../models/guideline-list-item";
import { GuidelineReadModel } from './../models/guideline-read.model';

const guidelineApiUrl = process.env.REACT_APP_GUIDELINE_API_URL!;

export const GuidelineService = {
    createGuideline: (
        guideline: Guideline): Promise<void> => {
        return httpClient.post(guidelineApiUrl, guideline);
    },

    getGuidelineByToken: async (guidelineToken: string): Promise<GuidelineReadModel | null> => {
        const getGuideLineByTokenUrl = `${guidelineApiUrl}/${guidelineToken}`;
        const result = await httpClient.get(getGuideLineByTokenUrl);
        return result.data;
    },

    updateGuideline: (guideline: Guideline): Promise<void> => {
        return httpClient.put(guidelineApiUrl, guideline);
    },

    getGuidelines: async (
        guidelineStatus: GuidelineStatus,
        includeFromConnectedCompanies: boolean = false): Promise<GuidelineListItem[]> => {
        const queryParameters: any = {
            status: guidelineStatus
        };

        if (includeFromConnectedCompanies) {
            queryParameters.includeFromConnectedCompanies = true;
        }

        const response =
            await httpClient.get(guidelineApiUrl, { params: queryParameters });

        return response.data;
    },

    restoreGuideline: (
        guidelineToken: string): Promise<void> => {
        const restoreGuideLineUrl = `${guidelineApiUrl}/${guidelineToken}/status`;
        return httpClient.post(restoreGuideLineUrl, { status: GuidelineStatus.Active });
    },

    removeGuideline: (guidelineToken: string): Promise<void> => {
        const archiveGuideLineUrl = `${guidelineApiUrl}/${guidelineToken}/status`;
        return httpClient.post(archiveGuideLineUrl, { status: GuidelineStatus.Archived });
    },

    getGuidelineCheckHistoryByToken: async (
        guidelineToken: string,
        fundToken: string): Promise<GuidelineCheckHistoryItem[]> => {
        const guidelineCheckHistoryUrl = `${guidelineApiUrl}/${guidelineToken}/check-history?fundToken=${fundToken}`;

        const response =
            await httpClient.get(guidelineCheckHistoryUrl);

        return response.data;
    }
}