import { Button, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { UserStatus } from "../../../core/enums/user-status.enum";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserRoles } from "../../../core/constants/user-role.const";

import "./actions.css";

interface IActionsProps {
    userId: string;
    userRole: string;
    userStatus: UserStatus;
    changeUserRole: (id: string) => void;
    changeUserStatus: (id: string) => void;
    resetUserPassword: (id: string) => void;
}

const Actions = ({
    userId,
    userRole,
    userStatus,
    changeUserRole,
    changeUserStatus,
    resetUserPassword,
}: IActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const userActivationChangeTo = userStatus === UserStatus.Active
        ? "Deactivate"
        : "Activate";

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: 'px',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem className='action-menu-item'>
                    <Button
                        onClick={() => changeUserRole(userId)}
                        className='action-button'
                    >
                        {`Change to ${userRole === UserRoles.admin ? UserRoles.user : UserRoles.admin}`}
                    </Button>
                </MenuItem>

                <Divider />

                <MenuItem className='action-menu-item'>
                    <Button
                        onClick={() => changeUserStatus(userId)}
                        className='action-button'
                    >
                        {userActivationChangeTo}
                    </Button>
                </MenuItem>

                <Divider />

                <MenuItem className='action-menu-item'>
                    <Button
                        onClick={() => resetUserPassword(userId)}
                        className='action-button'
                    >
                        Reset password
                    </Button>
                </MenuItem>
            </Menu>
        </>
    )
}

export { Actions }; 