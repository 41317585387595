import { Box, Button, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { CompanyFormField } from './company-form-field';
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';
import { useAuth } from '../auth';
import { ICompanyConnection } from '../core/models/company-connection';
import { GuidelineStatus } from '../core/enums/guideline-status.enum';
import { FundService } from '../core/services/fund.service';
import { GuidelineListItem } from '../core/models/guideline-list-item';
import { CompanyService } from '../core/services/company.service';
import { Spinner } from '../spinner';
import { AccessTokenProviderService } from '../core/services/access-token-provider.service';
import { CompanyFundRelationTypes } from '../core/constants/company-relation-types.const';
import "./fund-creator.css";
import { GuidelineService } from '../core/services/guideline.service';
import { IFund } from '../core/models/fund';

interface IFundCreatorProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

interface IGuidelineRow {
    id: string;
    name: string;
}

const columns = [
    { field: "id", headerName: "Guideline ID", minWidth: 300 },
    { field: "name", headerName: "Guideline Name", minWidth: 500 },
]

const FundCreator = ({ onPageChanged }: IFundCreatorProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const { fundToken = "" } = useParams();

    const [isDataLoaded, setIsDataLoadedFlag] = useState<boolean>(false);
    const [guidelineRows, setGuidelineRows] = useState<IGuidelineRow[]>([]);
    const [checkedGuidelineRows, setCheckedGuidelineRows] = useState<string[]>([]);

    const [name, setName] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const [isNameError, setNameError] = useState<boolean>(false);
    const [nameHelperText, setNameHelperText] = useState<string>("");

    const [companies, setCompanies] = useState<ICompanyConnection[]>([]);
    const [companyConnections, setCompanyConnections] = useState<{ id: number, companyToken: string, relationType: CompanyFundRelationTypes }[]>([]);

    useEffect(() => {
        const token = AccessTokenProviderService.getToken();
        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);

    useEffect(() => {
        onPageChanged(false);
        const fundRequest = fundToken
            ? FundService.getFundByToken(fundToken)
            : Promise.resolve(null);

        Promise.all(
            [
                fundRequest,
                CompanyService.getCompanyConnections(),
                GuidelineService.getGuidelines(GuidelineStatus.Active)
            ]
        ).then((results) => {
            const fund = results[0];
            if (fund) {
                setToken(fund.token);
                setName(fund.name);
                setCheckedGuidelineRows(fund.guidelines);

                if (fund?.connections?.length) {
                    const connections =
                        fund.connections.map((connection, index) => ({
                            id: index + 1,
                            companyToken: connection.companyToken,
                            relationType: connection.relationType
                        }));

                    setCompanyConnections(connections);
                }
            }

            const companyConnections = results[1];
            setCompanies(companyConnections);

            const guidelines = results[2];
            const guidelineRows = guidelines.map(({ token, name }) => ({ id: token, name }));

            setGuidelineRows(guidelineRows);
            setIsDataLoadedFlag(true);
        })
    }, []);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);

        if (!event.target.value.trim()) {
            setNameError(true);
            setNameHelperText("Invalid fund name");

            return;
        }

        setNameError(false);
        setNameHelperText("");
    }

    const handleAddCompany = () => {
        const nextId = companyConnections.length + 1;

        const newCompanyConnection =
        {
            id: nextId,
            companyToken: "",
            relationType: CompanyFundRelationTypes.Custodian
        }

        companyConnections
            .push(newCompanyConnection);

        setCompanyConnections(companyConnections.slice());
    };

    const handleDeleteCompanyConnection = (id: number) => {
        const companyConnectionIndexToDelete =
            companyConnections.findIndex(c => c.id === id);

        companyConnections.splice(companyConnectionIndexToDelete, 1);

        companyConnections.forEach(c => c.id--);

        setCompanyConnections(companyConnections.slice());
    }

    const handleCheckGuidelineRows = (
        selectionModel: GridSelectionModel
    ) => setCheckedGuidelineRows(selectionModel as string[]);

    const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isNameError) return;

        const newCompanyConnections =
            companyConnections
                .map(connection => ({ companyToken: connection.companyToken, relationType: connection.relationType }));

        FundService
            .saveFund(token, name, newCompanyConnections, checkedGuidelineRows)
            .then(() => {
                navigate('/funds', { replace: true });
            });
    }

    return (
        <div className="container">
            <div className="left-column">
                <Link to={"/funds"} className="back-to-button">
                    <Button type="button" variant="outlined">
                        Back to fund list
                    </Button>
                </Link>
            </div>

            <div className="right-column">
                {
                    !isDataLoaded
                        ? <Spinner />
                        : <>
                            <span className="form-title">
                                {
                                    token
                                        ? 'Update Fund'
                                        : 'New Fund'
                                }
                            </span>

                            <Box
                                component={"form"}
                                className="form"
                                onSubmit={handleSubmitForm}
                            >
                                <TextField
                                    required
                                    value={name}
                                    onChange={handleChangeName}
                                    label="FUND NAME"
                                    placeholder="Please enter fund legal name"
                                    helperText={nameHelperText}
                                    error={isNameError}
                                />

                                <div className="companies-field-container">
                                    <span className="field-label">
                                        Participating companies
                                    </span>

                                    <div className="companies-field">
                                        <p>Following companies are participating in the fund</p>

                                        {
                                            companyConnections.map((connection) => (
                                                <CompanyFormField
                                                    key={connection.id}
                                                    connection={connection}
                                                    companies={companies}
                                                    onDeleteConnection={handleDeleteCompanyConnection}
                                                    onCompanyParticipantChanged={(index: number, companyToken: string) => {
                                                        const connection = companyConnections[index - 1];
                                                        connection.companyToken = companyToken;
                                                        setCompanyConnections(companyConnections.slice());
                                                    }}
                                                    onCompanyRelationTypeChanged={(index: number, relationType: number) => {
                                                        const connection = companyConnections[index - 1];
                                                        connection.relationType = relationType;
                                                        setCompanyConnections(companyConnections.slice());
                                                    }}
                                                />
                                            ))
                                        }

                                        <Button
                                            variant="outlined"
                                            className="add-button"
                                        >
                                            <AddIcon />

                                            <span
                                                className="add-button-text"
                                                onClick={handleAddCompany}
                                            >
                                                Add company
                                            </span>
                                        </Button>
                                    </div>
                                </div>

                                <div className="guidelines-field-container">
                                    <span className="field-label">
                                        APPlicable guidelines
                                    </span>

                                    <div className="guidelines-field">
                                        <p>Following guidelines apply to the fund</p>

                                        <Paper className="guidelines-list-container">
                                            <div className="guidelines-table-header">
                                                <span className="guidelines-table-title">
                                                    Guidelines
                                                </span>

                                                <Link to={"/guideline-creator"} className="button-link">
                                                    <Button variant="contained">
                                                        New Guideline
                                                    </Button>
                                                </Link>

                                            </div>

                                            <div className="data-table">
                                                <DataGrid
                                                    rows={guidelineRows}
                                                    columns={columns}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    checkboxSelection
                                                    onSelectionModelChange={handleCheckGuidelineRows}
                                                    selectionModel={checkedGuidelineRows}
                                                />
                                            </div>
                                        </Paper>
                                    </div>
                                </div>

                                <div>
                                    <Button variant="contained" type="submit" className="save-button">
                                        Save
                                    </Button>
                                </div>
                            </Box>
                        </>
                }
            </div>
        </div>
    )
};

export { FundCreator };