import { Autocomplete, Button, Card, CardContent, Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Restriction } from "../../core/models/restriction";
import { useEffect, useRef, useState } from "react";
import { SecMasterAttribute } from "../../core/models/sec-master-attribute";
import { RestrictionMetadataItem } from "../../core/models/restriction-metadata-item";
import { GuidelineRestrictionService } from "../../core/services/guideline-restriction.service";
import { INumericLookUpItem } from "../../core/models/numeric-look-up-item";
import { commonConstants } from "../../core/constants/common.const";
import { CfiGroupDataGrid } from "../../cfi-group-data-grid";

interface IGuidLineFormRestrictionsProps {
    secMasterAttributes: SecMasterAttribute[];

    restrictions: Restriction[];

    newRestrictionAdded: () => void;

    restrictionRemoved: (restrictionIndex: number) => void;

    restrictionChanged: (
        restriction: Restriction,
        restrictionIndex: number) => void;

    disabled: boolean;
}

const GuidLineRestrictions = ({
    secMasterAttributes,
    restrictions,
    newRestrictionAdded,
    restrictionRemoved,
    restrictionChanged,
    disabled,
}: IGuidLineFormRestrictionsProps) => {
    const [restrictionMetadata, setRestrictionMetadata] = useState<RestrictionMetadataItem[]>([]);

    useEffect(() => {
        GuidelineRestrictionService.getMetadata()
            .then((metadata) => {
                setRestrictionMetadata(metadata);

                // all possible operation types
                operationTypes.current = metadata
                    .flatMap((metadataItem: RestrictionMetadataItem) => metadataItem.availableOperations)
                    .reduce((allOperationTypes: INumericLookUpItem[], curentOperationType: INumericLookUpItem) => {
                        const alreadyAddedOperationType =
                            allOperationTypes.find((operationType: INumericLookUpItem) => curentOperationType.token === operationType.token);

                        if (!alreadyAddedOperationType) {
                            allOperationTypes.push(curentOperationType);
                        }

                        return allOperationTypes;
                    }, []);
            })
    }, []);

    const operationTypes = useRef<INumericLookUpItem[]>([]);

    const secMasterAttributeChanged = (
        attributeName: string | null,
        restrictionIndex: number) => {
        const restriction = restrictions[restrictionIndex];
        restriction.values = [];
        
        if (!attributeName) {
            restriction.code = null;
            restriction.restrictionDataType = null;
            restriction.restrictionOperationType = null;
        }
        else {
            const secMasterAttribute = secMasterAttributes
                .find((attr: SecMasterAttribute) => attr.name === attributeName);

            restriction.code = secMasterAttribute!.name;
            const restrictionMetadataItem =
                restrictionMetadata.find(r => r.dataType.token === secMasterAttribute!.dataType!.type)!;

            restriction.restrictionDataType = restrictionMetadataItem.dataType.token;
            restriction.restrictionOperationType = restrictionMetadataItem.availableOperations[0].token;
        }

        restrictionChanged(restriction, restrictionIndex);
    }

    const getDataTypeName = (dataType: number) => {
        return restrictionMetadata
            .find((item: RestrictionMetadataItem) => item.dataType.token === dataType)!.dataType?.name;
    }

    const getDataTypeAvailableOperations = (dataType: number): number[] => {
        return restrictionMetadata
            .find((r: RestrictionMetadataItem) => r.dataType.token === dataType)!.availableOperations
            .map((operation: INumericLookUpItem) => operation.token);
    }

    const getOperationName = (
        operation: number) => {
        if (!operation) {
            return '';
        }

        return operationTypes.current
            .find((operationType: INumericLookUpItem) => operationType.token === operation)!.name;
    }

    const operationTypeChanged = (
        operationType: number,
        restrictionIndex: number) => {
        const restriction = restrictions[restrictionIndex];
        restriction.restrictionOperationType = operationType;
        restrictionChanged(restriction, restrictionIndex);
    }

    const getSecMasterAttributeValues = (secMasterAttributeCode: string | null) => {
        if (!secMasterAttributeCode) {
            return [];
        }

        const attributeValues = secMasterAttributes
            .find((attr: SecMasterAttribute) => attr.name === secMasterAttributeCode)?.values ?? [];

        return attributeValues;
    }

    const restrictionValueChanged = (
        value: string | string[] | null,
        restrictionIndex: number) => {
        const restriction = restrictions[restrictionIndex];
        if (!value) {
            restriction.values = [];
        }

        if (Array.isArray(value)) {
            restriction.values = value;
        } else {
            restriction.values = value ? [value] : [];
        }

        restrictionChanged(restriction, restrictionIndex);
    }

    const isRestrictionReadyForSettingValue = (restriction: Restriction) => {
        if (!restriction.code || !restriction.restrictionDataType || !restriction.restrictionOperationType) {
            return false;
        }

        return true;
    }

    const getValueTextEditorForRestriction = (
        restriction: Restriction,
        restrictionIndex: number) => {
        if (restriction.code === commonConstants.cfiCodeAttributeKey) {
            return <CfiGroupDataGrid
                readOnly={disabled}
                cfiCodes={restriction.values}
                cfiCodesStateChanged={(codes: string[]) => restriction.values = codes} />
        }

        // next conditions will based on sec master
        // attribute data type
        // Integer = 1,
        // Float,
        // Text,
        // Rating

        if (restriction.restrictionDataType === 1 || restriction.restrictionDataType === 2) {
            return <TextField
                type="number"
                margin="normal"
                size="small"
                fullWidth
                variant="outlined"
                value={restriction.values[0] ?? ''}
                onChange={(event) => restrictionValueChanged([event.target.value], restrictionIndex)}
            />
        }

        if (restriction.restrictionDataType === 3 || restriction.restrictionDataType === 4) {
            // rating data type is allowed only single value selection
            return <Autocomplete
                size="small"
                filterSelectedOptions
                multiple={restriction.restrictionDataType === 3}
                disabled={disabled}
                options={getSecMasterAttributeValues(restriction.code)}
                onChange={(event, newValue) => restrictionValueChanged(newValue, restrictionIndex)}
                value={restriction.restrictionDataType === 3 ? restriction.values : restriction.values[0] ?? null}
                renderInput={params => (
                    <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                    />
                )}
            />
        }
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent sx={{ background: '#F2F2F2' }}>
                    <div>Restrictions:</div>
                    {
                        !restrictionMetadata.length
                            ? ''
                            : restrictions.map((restriction, index) =>
                                <Grid
                                    key={index}
                                    spacing={1}
                                    container
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="center">
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            size="small"
                                            filterSelectedOptions
                                            options={secMasterAttributes.map((attr: SecMasterAttribute) => attr.name)}
                                            onChange={(event, value) => secMasterAttributeChanged(value, index)}
                                            value={restriction.code}
                                            disabled={disabled}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Code"
                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {
                                        !restriction.restrictionDataType
                                            ? ''
                                            : <Grid item xs={1}>
                                                <TextField
                                                    size="small"
                                                    placeholder="Type"
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    value={getDataTypeName(restriction.restrictionDataType)}
                                                />
                                            </Grid>
                                    }

                                    {
                                        !restriction.restrictionDataType
                                            ? ''
                                            : <Grid item xs={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={getDataTypeAvailableOperations(restriction.restrictionDataType)}
                                                    onChange={(event, value) => operationTypeChanged(value!, index)}
                                                    value={restriction.restrictionOperationType}
                                                    getOptionLabel={(option: number) => getOperationName(option)}
                                                    disabled={disabled}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Code"
                                                            margin="normal"
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                    }

                                    {
                                        !isRestrictionReadyForSettingValue(restriction)
                                            ? ''
                                            : <Grid item xs={4}>
                                                {
                                                    getValueTextEditorForRestriction(restriction, index)
                                                }
                                            </Grid>
                                    }

                                    <Grid item xs={1}>
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => restrictionRemoved(index)}
                                            component="span"
                                            disabled={disabled}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                    }
                    <Grid item xs={12}>
                        <Button style={{ marginTop: '10px' }}
                            onClick={() => newRestrictionAdded()}
                            type="button"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            disabled={disabled}
                        >
                            ADD REQUIREMENT
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid >
    )
}

export { GuidLineRestrictions };

