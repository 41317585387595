export class CfiItem {
    letterCode: string = '';
    shortDescription: string = '';

    get name(): string {
        return `${this.letterCode}. ${this.shortDescription}`;
    }

    constructor(
        letterCode: string,
        shortDescription: string) {
        this.letterCode = letterCode;
        this.shortDescription = shortDescription;
    }
}