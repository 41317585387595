class AccessTokenProviderService {
   static addToken(token: string): void {
        localStorage.setItem("token", token);
    }

    static getToken(): string | null {
        const token = localStorage.getItem("token");

        return token;
    }

    static resetToken(): void {
        localStorage.removeItem("token");
    }
}

export { AccessTokenProviderService };