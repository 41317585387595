import axios from "axios";
import httpClient from "../../http/http-client";
import { UserLoginResult } from "../models/user-login-result";
import { UserRegistrationResult } from "../models/user-registration-result";

const authApiUrl = process.env.REACT_APP_AUTH_URL;
const ocpApimSubscriptionKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY!;

class AuthService {
    static async login(
        email: string,
        password: string): Promise<UserLoginResult | string[]> {
        const loginUrl = `${authApiUrl}login`;
        try {
            const response =
                await axios.post<UserLoginResult>(
                    loginUrl,
                    { email, password },
                    { headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey } });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                return error.response.data;
            }

            throw error;
        }
    }

    static async register(
        firstName: string,
        lastName: string,
        email: string,
        role: string): Promise<UserRegistrationResult | string[]> {
        const registerNewUserUrl = `${authApiUrl}register`;
        try {
            const response =
                await httpClient.post<UserRegistrationResult>(
                    registerNewUserUrl,
                    { email, firstName, lastName, role })
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                return error.response.data;
            }

            throw error;
        }
    }
}

export { AuthService };
