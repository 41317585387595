import { Breadcrumbs, Link } from "@mui/material";
import { GuidelineHierarchyItem } from "../../core/models/guideline-hierarchy-item";

import "./breadcrumb-menu.css";

interface IBreadCrumbMenuItem {
    token: string;
    name: string;
}

interface IBreadcrumbMenuProps {
    menuItem: GuidelineHierarchyItem;
    menuItemSelected: (guidelineId: string) => void;
}

const BreadcrumbMenu = ({ menuItem, menuItemSelected }: IBreadcrumbMenuProps) => {
    const menuItems: IBreadCrumbMenuItem[] = [];
    const setMenuItems = (guidelineHierarchy: GuidelineHierarchyItem | null, items: IBreadCrumbMenuItem[]): void => {
        if (!guidelineHierarchy) {
            return;
        }

        items.unshift({ token: guidelineHierarchy.token, name: guidelineHierarchy.version });
        setMenuItems(guidelineHierarchy.parent, items)
    }

    setMenuItems(menuItem, menuItems);

    return (
        <div className="menu-item-container">
            <h4 className="title">
                Previous versions:
            </h4>

            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {
                    menuItems.map((menuItem: IBreadCrumbMenuItem, idx) =>
                    (
                        <Link
                            underline="hover"
                            key={idx}
                            onClick={() => menuItemSelected(menuItem.token)}
                            color="primary"
                            className="menu-item"
                        >
                            {menuItem.name}
                        </Link>
                    ))
                }
            </Breadcrumbs>
        </div >
    )
}

export { BreadcrumbMenu };