import { DropdownOptionItem } from "../models/dropdown-option-item";

export enum IncidentStatusType {
    Open = 1,
    Escalation,
    ActiveBreach,
    PassiveBreach,
    Resolved
}

export const IncidentStatusItems = [
    new DropdownOptionItem("Open", IncidentStatusType.Open),
    new DropdownOptionItem("Escalation", IncidentStatusType.Escalation),
    new DropdownOptionItem("Active Breach", IncidentStatusType.ActiveBreach),
    new DropdownOptionItem("Passive Breach", IncidentStatusType.PassiveBreach),
    new DropdownOptionItem("Resolved", IncidentStatusType.Resolved),
];