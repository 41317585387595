import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { DailyHoldingsCheckResultItem } from "../../core/models/daily-holdings-check-result-item";
import { getMonitoringStatus } from "../../dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GuidelineCheckResultItem } from "../guideline-check-result-item";
import { HoldingsTable } from "../holdings-table";

import "./daily-holdings-check-result.css";

interface IDailyHoldingsCheckResultProps {
    result: DailyHoldingsCheckResultItem;
}

const DailyHoldingsCheckResult = ({ result }: IDailyHoldingsCheckResultProps) => (
    <Grid item className="summary">
        <div className="summary-title">
            <h3>
                {result.name}
            </h3>

            <span>
                {
                    getMonitoringStatus(result.checkResult.status)
                }
            </span>
        </div>

        <Accordion className="expandable-section">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="expandable-summary">
                <div className="summary-content-row">
                    <GuidelineCheckResultItem title={`${result.name} Total Exposure Value (USD)`} subtitle={result.totalExposedValue} />

                    <GuidelineCheckResultItem title="% of Total Value Exposed to Guideline" subtitle={result.calculatedPercentage} />

                    <GuidelineCheckResultItem title="Min %" subtitle={result.minPercentage} />

                    <GuidelineCheckResultItem title="Max %" subtitle={result.maxPercentage} />

                    <GuidelineCheckResultItem title="Grouped By" subtitle={result.groupByAttributeValues?.join(", ")} />
                </div>
            </AccordionSummary>

            <AccordionDetails>
                 <HoldingsTable holdings={result.holdings} />
            </AccordionDetails>
        </Accordion>
    </Grid>
);

export { DailyHoldingsCheckResult };
