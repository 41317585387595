import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useAuth } from "../../../auth";
import { UserRoles } from "../../../core/constants/user-role.const";
import { UserStatus } from "../../../core/enums/user-status.enum";
import { IUser } from "../../../core/models/user";
import { Actions } from "../actions";

interface IUsersTableProps {
    data: IUser[];
    changeRoleAction: (id: string) => void;
    changeStatusAction: (id: string) => void;
    resetPasswordAction: (id: string) => void;
}

const UsersTable = ({
    data,
    changeRoleAction,
    changeStatusAction,
    resetPasswordAction,
}: IUsersTableProps) => {
    const auth = useAuth();

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, marginTop: "20px" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Full name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Access</TableCell>
                        <TableCell align="left">Status</TableCell>
                        {
                            auth.user.role === UserRoles.admin
                                ? <TableCell align="left">Actions</TableCell>
                                : null
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        data.map((user) => {
                            return (
                                <TableRow
                                    key={user.id}
                                >
                                    <TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
                                    <TableCell align="left">{user.email}</TableCell>
                                    <TableCell align="left">{user.role}</TableCell>
                                    <TableCell align="left">{user.status === UserStatus.Active ? 'Active' : 'Inactive'}</TableCell>
                                    {
                                        auth.user.role === UserRoles.admin
                                            ? <TableCell align="left">
                                                <Actions
                                                    userId={user.id}
                                                    userRole={user.role}
                                                    userStatus={user.status}
                                                    changeUserRole={changeRoleAction}
                                                    changeUserStatus={changeStatusAction}
                                                    resetUserPassword={resetPasswordAction}
                                                />
                                            </TableCell>
                                            : null
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export { UsersTable };