export enum CompanyFundRelationTypes {
    Custodian = 1,
    AssetManager
}

export const CompanyFundRelationTypeOptions = [
    {
        label: "Custodian",
        type: CompanyFundRelationTypes.Custodian,
    },
    {
        label: "Asset Manager",
        type: CompanyFundRelationTypes.AssetManager
    }
]