import { createContext, useContext, useState } from 'react';
import { IUser } from '../core/models/user';
import { AccessTokenProviderService } from '../core/services/access-token-provider.service';
import { UserService } from './../core/services/user.service';

interface IAuthContext {
   user: IUser;
   setUser: (user: IUser) => void;
}

let isCurrentUserSet = false;
let isSubscriptionOnLocalstorageEventAdded = false;

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = (props: any) => {
   const [user, setUser] = useState<IUser>({ isAuthenticated: false } as IUser);

   if (!isSubscriptionOnLocalstorageEventAdded) {
      window.addEventListener("storage", () => {
         const token = AccessTokenProviderService.getToken();

         if (!token) {
            setUser({ isAuthenticated: false } as IUser);
         }
      });

      isSubscriptionOnLocalstorageEventAdded = true;
   }

   const token = AccessTokenProviderService.getToken();

   if (token && !isCurrentUserSet) {
      UserService.getCurrentUser().then((result) => {
         isCurrentUserSet = true;
         setUser(result);
      });
   }

   return (
      <AuthContext.Provider value={{ setUser: (currentUser: IUser) => setUser(currentUser), user }}>
         {props.children}
      </AuthContext.Provider>
   )
};

export const useAuth = (): IAuthContext => {
   return useContext(AuthContext);
}