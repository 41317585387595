import { IApplyFilterParams } from "../../dashboard/table-filter";
import httpClient from "../../http/http-client";
import { CompanyFundRelationTypes } from "../constants/company-relation-types.const";
import { DailyHoldings } from "../models/daily-holdings";
import { IFund, IFundLookupItem } from "../models/fund";
import { GuidelineCheckResult } from "../models/guideline-check-result";
import { GuidelineMonitoringItem } from "../models/guideline-monitoring-item";

const fundsApiUrlWithoutSlash = process.env.REACT_APP_FUNDS_API_URL!;
const fundsApiUrl = `${fundsApiUrlWithoutSlash}/`;

export const FundService = {
    getDailyHoldingsCheckResults: async (params?: IApplyFilterParams): Promise<GuidelineMonitoringItem[]> => {
        const resultsUrl = `${fundsApiUrl}daily-holdings-check-results`;

        const result =
            await httpClient.get(resultsUrl, { params });

        return result.data;
    },

    checkDailyHoldings: (
        dailyHoldings: DailyHoldings): Promise<void> => {
        const checkDailyHoldingsUrl =
            `${fundsApiUrl}daily-holdings-check-requests`;

        return httpClient.post(checkDailyHoldingsUrl, dailyHoldings);
    },

    getDailyHoldingsCheckResult: async (
        guidelineToken: string,
        fundToken: string,
        dailyHoldingsToken: string): Promise<GuidelineCheckResult> => {

        const guidelineCheckHistoryUrl = `${fundsApiUrl}guidelines/${guidelineToken}/check-results?dailyHoldingsToken=${dailyHoldingsToken}&fundToken=${fundToken}`;

        const response =
            await httpClient.get(guidelineCheckHistoryUrl);

        return response.data;
    },

    getFundByToken: async (fundToken: string): Promise<IFund> => {
        const fundUrl = `${fundsApiUrl}${fundToken}`;
        const response =
            await httpClient.get(fundUrl);

        return response.data;
    },

    getFunds: async (includeFromConnectedCompanies: boolean = false): Promise<IFundLookupItem[]> => {
        const response =
            await httpClient.get(fundsApiUrlWithoutSlash, { params: { includeFromConnectedCompanies } });

        return response.data;
    },

    saveFund: async (
        token: string,
        fundName: string,
        connections: { companyToken: string; relationType: CompanyFundRelationTypes }[],
        guidelines: string[],
    ): Promise<void> => {
        const fund = {
            token: token,
            name: fundName,
            connections: connections,
            guidelines: guidelines
        }

        if (token) {
            await httpClient.put(fundsApiUrl, fund);
            return;
        }

        await httpClient.post(fundsApiUrl, fund);
    }
}