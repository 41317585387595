export enum DailyHoldingsCheckOverallResult {
    Passed = 1,
    MissingData,
    IssueDetected
}

export enum DailyHoldingsCheckResult {
    Passed = 1,
    MissingData,
    MinValidationFailed,
    MaxValidationFailed
}