import { CfiGroup } from "./cfi-group";
import { CfiItem } from "./cfi-item";

export class CfiCategory extends CfiItem {
    longDescription: string = '';
    groups: CfiGroup[] = [];

    constructor(
        letterCode: string,
        shortDescription: string,
        longDescription: string,
        groups: CfiGroup[]) {
        super(letterCode, shortDescription);
        this.groups = groups;
        this.longDescription = longDescription;
    }
}