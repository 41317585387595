import { GuidelineStatus } from "../enums/guideline-status.enum";
import { Restriction } from "./restriction";

export class Guideline {
    token: string = '';
    name: string = '';
    fund_name: string = '';
    min_percentage: number = 0;
    max_percentage: number = 0;
    group_by: string[] = [];
    description: string = '';
    restrictions: Restriction[] = [];
    status: GuidelineStatus = GuidelineStatus.Active;
}