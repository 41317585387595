import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import { GuidelineReadModel } from '../core/models/guideline-read.model';
import { AccessTokenProviderService } from '../core/services/access-token-provider.service';
import { GuidelineForm } from '../guideline-form';
import { GuidelineHeader } from '../guideline-header';
import { GuidelineTable } from '../guideline-table';
import { useEffect } from 'react';


import './guideline-creator.css';

interface IGuidelineCreatorProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

export const GuidelineCreator = ({ onPageChanged }: IGuidelineCreatorProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [isListMode, setIsListModeFlag] = useState<boolean>(true);
    const [guidelineFormItem, setGuidelineFormItem] = useState<GuidelineReadModel | null>(null);

    useEffect(() => onPageChanged(true), []);

    useEffect(() => {
        const token = AccessTokenProviderService.getToken();
        
        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);

    const handleModeChange = (isListModeSet: boolean) => {
        if (!isListMode) setGuidelineFormItem(null);
        setIsListModeFlag(isListModeSet);
    }

    const handelGuidelineEdit = (guidelineFormItem: GuidelineReadModel | null) =>
        setGuidelineFormItem(guidelineFormItem);

    const openGuidelineHierarchyItem = (guidelineFormItem: GuidelineReadModel | null) =>
        setGuidelineFormItem(guidelineFormItem);

    return (
        <Card>
            <CardContent>
                <GuidelineHeader
                    handleModeChange={handleModeChange}
                    isListMode={isListMode}
                />

                <div className='guideline-container'>
                    {
                        isListMode
                            ? <GuidelineTable
                                handleModeChange={handleModeChange}
                                handelGuidelineEdit={handelGuidelineEdit}
                            />
                            : <GuidelineForm
                                guidelineFormItem={guidelineFormItem}
                                handleModeChange={handleModeChange}
                                openGuidelineHierarchyItem={openGuidelineHierarchyItem}
                            />
                    }
                </div>                
            </CardContent>
        </Card>);
};