import { Box } from "@mui/material";

interface ITabPanelProps {
    index: number;
    value: number;
    children?: React.ReactNode;
}

const TabPanel = ({ children, value, index, ...other }: ITabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {
                value === index &&
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            }
        </div>
    )
};

export { TabPanel };
