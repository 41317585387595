import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import makeStyles from "@mui/styles/makeStyles";
import { CfiBlockItemTitle } from "../cfi-block-item-title";
import { CfiCategory } from "../core/models/cfi-category";
import { CfiGroup } from "../core/models/cfi-group";

import "./cfi-block-item-list.css";

const useStyles = makeStyles({
    root: {
        "& .Mui-selected": {
            backgroundColor: '#D0D7FF',
            color: '#1636FF'
        },
        "& .Mui-selected:hover": {
            backgroundColor: '#D0D7FF'
        },
        "& .category-name": {
            fontSize: '16px',
            fontWeight: 600,
            margin: '0 16px',
            lineHeight: '23px',
            marginBottom: '8px',
        },
        "& .category-description": {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '17px',
            marginLeft: '16px',
        },
    }

});

export const CfiBlockItemList = (props: any) => {
    const { letterCode, itemSelected, items, title } = props;

    const styles = useStyles();

    const itemSelectionChanged = (itemLetterCode: string) => {
        itemSelected(itemLetterCode);
    }

    return (
        <div className="block-item-list">
            <CfiBlockItemTitle
                title={title}
                subtitle={''}
            />

            <Card sx={{ height: '80%' }}>
                <CardContent sx={{ height: '100%', padding: "0" }}>
                    <List sx={{ height: '100%', overflow: 'auto' }} className={styles.root}>
                        {
                            items
                                .map((item: CfiCategory | CfiGroup, index: number) =>
                                (<ListItemButton
                                    key={index}
                                    selected={letterCode === item.letterCode}
                                    onClick={() => itemSelectionChanged(item.letterCode)}
                                    sx={{ paddingLeft: '5px' }}
                                >
                                    <div>
                                        <div className="category-name">
                                            {item.name}
                                        </div>
                                        {
                                            letterCode === item.letterCode
                                                ? <div className="category-description">
                                                    {item.longDescription}
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </ListItemButton>))
                        }
                    </List>
                </CardContent>
            </Card>
        </div>
    )
}