import httpClient from "../../http/http-client";
import { ICompany } from "../models/company";
import { ICompanyConnection } from "../models/company-connection";
import { ILookUpItem } from "../models/look-up-item";

const companyApiUrl = process.env.REACT_APP_COMPANY_API_URL;

export const CompanyService = {
    getCompany: async (): Promise<ICompany> => {
        const companyUrl = `${companyApiUrl}company`;
        const response = await httpClient.get<ICompany>(companyUrl);

        return response.data;
    },

    updateCompany: async (
        name?: string,
        website?: string,
        addressLine1?: string,
        addressLine2?: string,
        postcode?: string,
        city?: string,
        county?: string,
        country?: string,
    ): Promise<ICompany> => {
        const updateCompanyUrl = `${companyApiUrl}company`;

        const response = await httpClient.put<ICompany>(
            updateCompanyUrl,
            { name, website, addressLine1, addressLine2, postcode, city, county, country }
        );

        return response.data;
    },

    getCompanyConnections: async (): Promise<ICompanyConnection[]> => {
        const companiesParticipantUrl =
            `${companyApiUrl}company/connections`;

        const response = await httpClient.get(companiesParticipantUrl);
        return response.data;
    },

    getRelatedCompanies: async (): Promise<ILookUpItem[]> => {
        const companiesParticipantUrl =
            `${companyApiUrl}company/related-companies`;

        const response = await httpClient.get(companiesParticipantUrl);
        return response.data;
    }
};