import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import { CfiCodesBuilderDialog } from "../modal-dialogs/cfi-codes-builder-dialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import './cfi-group-data-grid.css';
import { CfiDataModelConverter } from "../core/services/cfi-data-model-converter";

export const CfiGroupDataGrid = (props: any) => {
    const { cfiCodesStateChanged, cfiCodes, readOnly } = props;

    const [isCfiBuilderOpened, setCfiBuilderIsOpenedFlag] = useState<boolean>(false);
    const [groups, setGroups] = useState<string[][]>(CfiDataModelConverter.fromListToGroups(cfiCodes));
    const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(-1);

    const emitCfiCodeStateChanged = (cfiCodeGroups: string[][]) => {
        const cfiCodeList = CfiDataModelConverter
            .fromGroupsToList(cfiCodeGroups);

        cfiCodesStateChanged(cfiCodeList);
    }

    const removeGroup = (groupIndex: number) => {
        groups.splice(groupIndex, 1);
        const newGroups = groups.slice();
        setGroups(newGroups);
        emitCfiCodeStateChanged(newGroups);
    }

    const editGroup = (groupIndex: number) => {
        setSelectedGroupIndex(groupIndex);
        setSelectedGroup([...groups[groupIndex]]);
        setCfiBuilderIsOpenedFlag(true);
    }

    const resetModalDialogPreviousSelection = () => {
        setSelectedGroup([]);
        setSelectedGroupIndex(-1);
    }

    const onCfiBuilderClosed = (codes: string[]) => {
        if (!codes.length) {
            resetModalDialogPreviousSelection();
            setCfiBuilderIsOpenedFlag(false);
            return;
        }
        else {
            if (selectedGroupIndex !== -1) {
                groups[selectedGroupIndex] = codes;
                const modifiedGroups = groups.slice();
                setGroups(modifiedGroups);
                emitCfiCodeStateChanged(modifiedGroups);
            }
            else {
                groups.push(codes);
                const newGroups = groups.slice();
                setGroups(newGroups);
                emitCfiCodeStateChanged(newGroups);
            }
        }

        resetModalDialogPreviousSelection();
        setCfiBuilderIsOpenedFlag(false);
    }

    return (
        <>
            <div>
                {
                    groups.map((group, index) =>
                        <div className="group" key={`group-${index}`}>
                            <span className="group-name">{group.join(', ')}</span>
                            <span className="group-actions">
                                <IconButton
                                    size="small"
                                    onClick={() => editGroup(index)}
                                    disabled={readOnly}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => removeGroup(index)}
                                    disabled={readOnly}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </div>
                    )
                }
            </div>

            <div className="add-button-container">
                <Button
                    size="small"
                    type="button"
                    variant="outlined"
                    startIcon={<AddIcon fontSize="small"/>}
                    onClick={() => setCfiBuilderIsOpenedFlag(true)}
                    disabled={readOnly}
                >
                    Add CFI CODE
                </Button>
            </div>

            <CfiCodesBuilderDialog
                initialCfiCodes={selectedGroup}
                onClose={onCfiBuilderClosed}
                open={isCfiBuilderOpened}
            />
        </>
    );
}
