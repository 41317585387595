import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Guideline } from "../core/models/guideline";
import { Restriction } from "../core/models/restriction";
import { GuidLineRestrictions } from "./guideline-form-restrictions";
import { BreadcrumbMenu } from "./breadcrumb-menu";
import { GuidelineStatus } from "../core/enums/guideline-status.enum";
import { Spinner } from "../spinner";
import { GuidelineReadModel } from "../core/models/guideline-read.model";
import { GuidelineService } from "../core/services/guideline.service";

import './guideline-form.css';
import Autocomplete from "@mui/material/Autocomplete";
import { SecMasterAttribute } from "../core/models/sec-master-attribute";
import { SecMasterService } from "../core/services/sec-master.service";
interface IGuidelineFormProps {
    guidelineFormItem: GuidelineReadModel | null;
    handleModeChange: (isListModeSet: boolean) => void;
    openGuidelineHierarchyItem: (guidelineFormItem: GuidelineReadModel | null) => void;
}

const GuidelineForm = (
    {
        guidelineFormItem,
        handleModeChange,
        openGuidelineHierarchyItem,
    }: IGuidelineFormProps
) => {
    const [name, setName] = useState<string>(guidelineFormItem?.guideline.name || '');
    const [minPercentage, setMinPercentage] = useState<number>(guidelineFormItem?.guideline.min_percentage || 0);
    const [maxPercentage, setMaxPercentage] = useState<number>(guidelineFormItem?.guideline.max_percentage || 0);
    const [description, setDescription] = useState<string>(guidelineFormItem?.guideline.description || "");
    const [restrictions, setRestrictions] = useState<Restriction[]>(guidelineFormItem?.guideline.restrictions || []);
    const [groupByAttributes, setGroupByAttributes] = useState<string[]>(guidelineFormItem?.guideline.group_by || []);
    const [isGuidelineSaving, setIsGuidelineSavingFlag] = useState<boolean>(false);

    const [secMasterAttributes, setSecMasterAttributes] = useState<SecMasterAttribute[]>([]);

    useEffect(() => {
        SecMasterService.getAttributes()
            .then((attributes) => setSecMasterAttributes(attributes))
    }, []);

    let isDisabledForm = true;

    if (!guidelineFormItem || (guidelineFormItem?.guideline.status === GuidelineStatus.Active)) {
        isDisabledForm = false;
    }

    const isGuidelineInEditMode = !!guidelineFormItem?.guideline.token;

    const resetGuidelineForm = () => {
        setName('');
        setMinPercentage(0);
        setMaxPercentage(0)
        setDescription('');
        setRestrictions([]);
        setGroupByAttributes([]);
    }

    const handleGuidelineSaving = (event: React.SyntheticEvent) => {
        event.preventDefault();

        const guideline = new Guideline();
        guideline.token = guidelineFormItem?.guideline.token || '';
        guideline.name = name;
        guideline.min_percentage = minPercentage;
        guideline.max_percentage = maxPercentage;
        guideline.group_by = groupByAttributes ? groupByAttributes : [];
        guideline.description = description;
        guideline.restrictions = restrictions;

        setIsGuidelineSavingFlag(true);

        const saveGuidelinePromise = guidelineFormItem?.guideline.token
            ? GuidelineService.updateGuideline(guideline)
            : GuidelineService.createGuideline(guideline);


        saveGuidelinePromise.then(() => {
            resetGuidelineForm();
            setIsGuidelineSavingFlag(false);
            handleModeChange(true);
        });
    };

    const addRestriction = () => {
        restrictions.push(new Restriction());
        setRestrictions(restrictions.slice());
    }

    const restrictionChanged = (
        restriction: Restriction,
        restrictionIndex: number) => {
        const modefiedRestriction = {
            code: restriction.code,
            restrictionDataType: restriction.restrictionDataType,
            restrictionOperationType: restriction.restrictionOperationType,
            values: restriction.values
        } as Restriction;

        restrictions[restrictionIndex] = modefiedRestriction;
        setRestrictions(restrictions.slice());
    };

    const removeRestriction = (restrictionIndex: number) => {
        restrictions.splice(restrictionIndex, 1);
        setRestrictions(restrictions.slice());
    };

    const openGuideline = (guidelineToken: string) => {
        GuidelineService
            .getGuidelineByToken(guidelineToken)
            .then((result: GuidelineReadModel | null) => {
                openGuidelineHierarchyItem(result || null);
                return result;
            })
            .then((result) => {
                if (result) {
                    const { guideline: { name, min_percentage, max_percentage, description, restrictions, group_by } } = result;

                    setName(name || '');
                    setMinPercentage(min_percentage || 0);
                    setMaxPercentage(max_percentage || 0)
                    setDescription(description || '');
                    setRestrictions(restrictions || []);
                    setGroupByAttributes(group_by || []);
                }
            });
    };

    const onRestore = (guidelineToken: string | undefined) => {
        if (guidelineToken) {
            GuidelineService
                .restoreGuideline(guidelineToken)
                .then(() => handleModeChange(true));
        }
    };

    const onCancel = () => handleModeChange(true);

    return (
        <div>
            {
                isGuidelineSaving
                    ? <Spinner />
                    : <>
                        {
                            guidelineFormItem?.guidelineHierarchy &&
                            <BreadcrumbMenu
                                menuItem={guidelineFormItem.guidelineHierarchy}
                                menuItemSelected={openGuideline}
                            />
                        }

                        <form onSubmit={handleGuidelineSaving}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) => setName(event.target.value)}
                                        className="form-control"
                                        label="NAME"
                                        variant="outlined"
                                        value={name}
                                        disabled={isDisabledForm || isGuidelineInEditMode}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        onChange={(event) => setMinPercentage(parseFloat(event.target.value))}
                                        className="form-control"
                                        type='number'
                                        label="MINIMUM PERCENTAGE"
                                        variant="outlined"
                                        value={minPercentage}
                                        disabled={isDisabledForm}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        onChange={(event) => setMaxPercentage(parseFloat(event.target.value))}
                                        className="form-control"
                                        type='number'
                                        label="MAXIMUM PERCENTAGE"
                                        variant="outlined"
                                        value={maxPercentage}
                                        disabled={isDisabledForm}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) => setDescription(event.target.value)}
                                        className="form-control"
                                        label="DESCRIPTION"
                                        variant="outlined"
                                        value={description}
                                        disabled={isDisabledForm}
                                    />
                                </Grid>

                                <GuidLineRestrictions
                                    secMasterAttributes={secMasterAttributes}
                                    restrictions={restrictions}
                                    newRestrictionAdded={addRestriction}
                                    restrictionRemoved={removeRestriction}
                                    restrictionChanged={restrictionChanged}
                                    disabled={isDisabledForm}
                                />

                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent sx={{ background: '#F2F2F2' }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <span>
                                                        Requirements are sorted according to the grouping requirement (such as issuer, country or other group keys).
                                                        The monitored fund holdings will be summarized according to the the grouping requirements.
                                                    </span>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        id="group-by-attributes"
                                                        disabled={isDisabledForm}
                                                        filterSelectedOptions
                                                        options={secMasterAttributes.map((attr: SecMasterAttribute) => attr.name)}
                                                        onChange={(e, groupByAttributeOption) => setGroupByAttributes(groupByAttributeOption)}
                                                        getOptionLabel={option => option}
                                                        value={groupByAttributes}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="Group by attribute"
                                                                margin="normal"
                                                                fullWidth
                                                                variant="outlined" />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {
                                    !isDisabledForm
                                        ? (
                                            <Grid item xs={12}>
                                                <Button
                                                    type="submit"
                                                    variant="outlined">
                                                    Save
                                                </Button>
                                            </Grid>

                                        )
                                        : (
                                            <Grid item xs={12}>
                                                <Button
                                                    className="restore-button"
                                                    variant="outlined"
                                                    onClick={() => onRestore(guidelineFormItem?.guideline.token)}>
                                                    Restore
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    onClick={onCancel}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        )
                                }
                            </Grid>
                        </form>
                    </>
            }
        </div>
    );
}

export { GuidelineForm }