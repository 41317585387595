import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ModalDialogProps } from "../check-holdings-dialog";

interface IConfirmationDialogProps extends ModalDialogProps {
    title: string;
}

export default function ConfirmationDialog(props: IConfirmationDialogProps) {
    const { onClose, open, title } = props;

    return (
        <Dialog open={open}>
            <DialogTitle>
                {title}
            </DialogTitle>

            <DialogActions>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <Button onClick={() => onClose(true)}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}