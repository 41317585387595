import { useEffect, useState } from "react";
import { useAuth } from "../auth";
import { useNavigate } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Paper, Tab, Tabs } from "@mui/material";
import { ESettings } from "../core/enums/settings.enum";
import { TabPanel } from "../common/tab-panel";
import { UsersTab } from "./users-tab";
import { CompanyTab } from "./company-tab";
import { AccessTokenProviderService } from "../core/services/access-token-provider.service";
import { ICompanyConnection } from "../core/models/company-connection";
import { CompanyService } from "../core/services/company.service";

import "./settings.css";
import { Secmaster } from "./secmaster";

const CompanyConnectionsTab = () => {
    const [companyConnections, setCompanyConnections] = useState<ICompanyConnection[]>([]);

    useEffect(() => {
        CompanyService.getCompanyConnections()
            .then((companyConnections: ICompanyConnection[]) => setCompanyConnections(companyConnections));
    }, [])

    return <List>
        {
            companyConnections.map((companyConnection: ICompanyConnection) => {
                return <ListItem key={companyConnection.token}>
                    <ListItemText
                        key={companyConnection.token}
                        primary={companyConnection.name}
                    />
                </ListItem>
            })
        }
    </List>
}

interface ISettingsProps {
    onPageChanged: (isShowSidebar: boolean) => void;
}

const Settings = ({ onPageChanged }: ISettingsProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => onPageChanged(true), []);

    useEffect(() => {
        const token = AccessTokenProviderService.getToken();

        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);

    const [tabValue, setTabValue] = useState(0);

    const handleChangeTab =
        (event: React.SyntheticEvent, newTabValue: ESettings) => setTabValue(newTabValue);

    return (
        <Paper sx={{ width: '100%', marginLeft: '35px', marginTop: '45px' }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                    >
                        <Tab label="Users" id="users-tab" />
                        <Tab label="Company" id="company-tab" />
                        <Tab label="Company Connections" id="companyConnections-tab" />
                        <Tab label="Secmaster" id="secmaster-tab" />
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <UsersTab />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <CompanyTab />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <CompanyConnectionsTab />
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <Secmaster />
                </TabPanel>
            </Box>
        </Paper>
    )
}

export { Settings };