export const notFormattedCategories: { [key: string]: any } = {
  E: {
    name: "Equities",
    description: "Financial instruments representing an ownership interest in an entity or pool of assets.",
    groups: {
      S: {
        name: "Common/ordinary shares",
        description: "Holders are typically entitled to vote and receive dividends. In the event of liquidation, holders of shares usually rank behind the entity's creditors and holders of preferred/preference shares.",
        attributes: {
          attribute_1: {
            description: "Voting right (indicates the kind of voting power conferred to the shareholder)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Voting (each share has one vote)",
                letter: "V"
              },
              {
                description: "Non-voting (the shareholder has no voting right)",
                letter: "N"
              },
              {
                description: "Restricted voting (the shareholder may be entitled to less than one vote per share)",
                letter: "R"
              },
              {
                description: "Enhanced voting (the shareholder is entitled to more than one vote per share)",
                letter: "E"
              }
            ]
          },
          attribute_2: {
            description: "Ownership/transfer/sales restrictions (the ownership or transfer of the security is subject to special conditions including country-specific restrictions)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Restrictions",
                letter: "T"
              },
              {
                description: "Free (unrestricted)",
                letter: "U"
              }
            ]
          },
          attribute_3: {
            description: "Payment status",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Nil paid",
                letter: "O"
              },
              {
                description: "Partly paid",
                letter: "P"
              },
              {
                description: "Fully paid",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      P: {
        name: "Preferred/preference shares",
        description: "Payment of dividends to holders normally takes preference over the payment of dividends to other classes of shares. In the event of liquidation, preferred/preference shares normally rank above ordinary shares but behind creditors of the company.",
        attributes: {
          attribute_1: {
            description: "Voting right (indicates the kind of voting power conferred to the shareholder)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Voting (each share has one vote)",
                letter: "V"
              },
              {
                description: "Non-voting (the shareholder has no voting right)",
                letter: "N"
              },
              {
                description: "Restricted voting (the shareholder may be entitled to less than one vote per share)",
                letter: "R"
              },
              {
                description: "Enhanced voting (the shareholder is entitled to more than one vote per share)",
                letter: "E"
              }
            ]
          },
          attribute_2: {
            description: "Redemption (indicates the retirement provisions made for the shares)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Redeemable (the shares may be redeemed at the option of the issuer and/or of the shareholder)",
                letter: "R"
              },
              {
                description: "Extendible (the redemption date can be extended at the issuer or holder option)",
                letter: "E"
              },
              {
                description: "Redeemable/extendible (the issuer and/or holders of redeemable shares with a fixed maturity date have the option to extend the maturity date)",
                letter: "T"
              },
              {
                description: "Exchangeable (the shares may be exchanged for securities of another issuer)",
                letter: "G"
              },
              {
                description: "Redeemable/exchangeable/extendible (the issuer and/or holders of redeemable shares with a fixed maturity date have the option to extend the maturity date and the shares may be exchanged for securities of another issuer)",
                letter: "A"
              },
              {
                description: "Redeemable/exchangeable (the shares may be redeemed at the option of the issuer and/or of the shareholder and may be exchanged for securities of another issuer)",
                letter: "C"
              },
              {
                description: "Perpetual (the share has no fixed maturity date)",
                letter: "N"
              }
            ]
          },
          attribute_3: {
            description: "Income (indicates the kind of dividend income the shareholders are entitled to)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate income (the shareholder periodically receives a stated income)",
                letter: "F"
              },
              {
                description: "Cumulative, fixed rate income (the shareholder periodically receives a stated amount; dividends not paid in any year accumulate and shall be paid at a later date before dividends can be paid on the common/ordinary shares)",
                letter: "C"
              },
              {
                description: "Participating income (preferred/preference shareholders, in addition to receiving their fixed rate of prior dividend, share with the common shareholders in further dividend distributions and in capital distributions)",
                letter: "P"
              },
              {
                description: "Cumulative, participating income (shareholders are entitled to dividends in excess of the stipulated preferential rate under specified conditions; dividends not paid in any year accumulate and shall be paid at a later date before dividends can be paid on the common/ordinary shares)",
                letter: "Q"
              },
              {
                description: "Adjustable/variable rate income (the dividend rate is set periodically, usually based on a certain yield)",
                letter: "A"
              },
              {
                description: "Normal rate income (shareholders are entitled to the same dividends as common/ordinary shareholders, but have other privileges, for example as regards distribution of assets upon dissolution)",
                letter: "N"
              },
              {
                description: "Auction rate income (dividend is adjusted through an auction, such as the Dutch auction)",
                letter: "U"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      C: {
        name: "Common/ordinary convertible shares",
        description: "Shares (common/ordinary) which, at the discretion of the holder, are convertible into other securities, at a designated rate. The conversion privilege may be perpetual or limited to a specific period.",
        attributes: {
          attribute_1: {
            description: "Voting right (indicates the kind of voting power conferred to the shareholder)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Voting (each share has one vote)",
                letter: "V"
              },
              {
                description: "Non-voting (the shareholder has no voting right)",
                letter: "N"
              },
              {
                description: "Restricted voting (the shareholder may be entitled to less than one vote per share)",
                letter: "R"
              },
              {
                description: "Enhanced voting (the shareholder is entitled to more than one vote per share)",
                letter: "E"
              }
            ]
          },
          attribute_2: {
            description: "Ownership/transfer/sales restrictions (the ownership or transfer of the security is subject to special conditions including country-specific restrictions)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Restrictions",
                letter: "T"
              },
              {
                description: "Free (unrestricted)",
                letter: "U"
              }
            ]
          },
          attribute_3: {
            description: "Payment status",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Nil paid",
                letter: "O"
              },
              {
                description: "Partly paid",
                letter: "P"
              },
              {
                description: "Fully paid",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      F: {
        name: "Preferred/preference convertible shares",
        description: "Preferred/preference shares which, at the discretion of the holder, are convertible into other securities, usually common/ordinary shares, at a designated rate. The conversion privilege may be perpetual or limited to a specified period.",
        attributes: {
          attribute_1: {
            description: "Voting right (indicates the kind of voting power conferred to the shareholder)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Voting (each share has one vote)",
                letter: "V"
              },
              {
                description: "Non-voting (the shareholder has no voting right)",
                letter: "N"
              },
              {
                description: "Restricted voting (the shareholder may be entitled to less than one vote per share)",
                letter: "R"
              },
              {
                description: "Enhanced voting (the shareholder is entitled to more than one vote per share)",
                letter: "E"
              }
            ]
          },
          attribute_2: {
            description: "Redemption (indicates the retirement provisions made for the shares)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Redeemable (the shares may be redeemed at the option of the issuer and/or of the shareholder)",
                letter: "R"
              },
              {
                description: "Extendible (the redemption date can be extended at the issuer or holder option)",
                letter: "E"
              },
              {
                description: "Redeemable/extendible (the issuer and/or holders of redeemable shares with a fixed maturity date have the option to extend the maturity date)",
                letter: "T"
              },
              {
                description: "Exchangeable (the shares may be exchanged for securities of another issuer)",
                letter: "G"
              },
              {
                description: "Redeemable/exchangeable/extendible (the issuer and/or holders of redeemable shares with a fixed maturity date have the option to extend the maturity date and the shares may be exchanged for securities of another issuer)",
                letter: "A"
              },
              {
                description: "Redeemable/exchangeable (the shares may be redeemed at the option of the issuer and/or of the shareholder and may be exchanged for securities of another issuer)",
                letter: "C"
              },
              {
                description: "Perpetual (the share has no fixed maturity date)",
                letter: "N"
              }
            ]
          },
          attribute_3: {
            description: "Income (indicates the kind of dividend income the shareholders are entitled to)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate income (the shareholder periodically receives a stated income)",
                letter: "F"
              },
              {
                description: "Cumulative, fixed rate income (the shareholder periodically receives a stated amount; dividends not paid in any year accumulate and shall be paid at a later date before dividends can be paid on the common/ordinary shares)",
                letter: "C"
              },
              {
                description: "Participating income (preferred/preference shareholders, in addition to receiving their fixed rate of prior dividend, share with the common shareholders in further dividend distributions and in capital distributions)",
                letter: "P"
              },
              {
                description: "Cumulative, participating income (shareholders are entitled to dividends in excess of the stipulated preferential rate under specified conditions; dividends not paid in any year accumulate and shall be paid at a later date before dividends can be paid on the common/ordinary shares)",
                letter: "Q"
              },
              {
                description: "Adjustable/variable rate income (the dividend rate is set periodically, usually based on a certain yield)",
                letter: "A"
              },
              {
                description: "Normal rate income (shareholders are entitled to the same dividends as common/ordinary shareholders, but have other privileges, for example as regards distribution of assets upon dissolution)",
                letter: "N"
              },
              {
                description: "Auction rate income (dividend is adjusted through an auction, such as the Dutch auction)",
                letter: "U"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      L: {
        name: "Limited partnership units",
        description: "A limited partnership is a form of partnership similar to a general partnership, except that in addition to one or more general partners (GPs), there are one or more limited partners (LPs).\nLike shareholders in a corporation, the LPs have limited liability, i.e. they are only liable on debts incurred by the firm to the extent of their registered investment and they have no management authority. The GPs pay the LPs the equivalent of a dividend on their investment, the nature and extent of which is usually defined in the partnership agreement.",
        attributes: {
          attribute_1: {
            description: "Voting right (indicates the kind of voting power conferred to the shareholder)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Voting (each share has one vote)",
                letter: "V"
              },
              {
                description: "Non-voting (the shareholder has no voting right)",
                letter: "N"
              },
              {
                description: "Restricted voting (the shareholder may be entitled to less than one vote per share)",
                letter: "R"
              },
              {
                description: "Enhanced voting (the shareholder is entitled to more than one vote per share)",
                letter: "E"
              }
            ]
          },
          attribute_2: {
            description: "Ownership/transfer/sales restrictions (the ownership or transfer of the security is subject to special conditions including country-specific restrictions)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Restrictions",
                letter: "T"
              },
              {
                description: "Free (unrestricted)",
                letter: "U"
              }
            ]
          },
          attribute_3: {
            description: "Payment status",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Nil paid",
                letter: "O"
              },
              {
                description: "Partly paid",
                letter: "P"
              },
              {
                description: "Fully paid",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      D: {
        name: "Depositary receipts on equities",
        description: "Depository receipts are securities that facilitate the ownership of securities traded in other jurisdictions. Depository receipts are widely used in order to allow the trading of shares in jurisdictions other than the one where the original shares were issued.",
        attributes: {
          attribute_1: {
            description: "Instrument dependency (represents the ownership of an instrument provided in this table)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Common/ordinary shares",
                letter: "S"
              },
              {
                description: "Preferred/preference shares",
                letter: "P"
              },
              {
                description: "Common/ordinary convertible shares",
                letter: "C"
              },
              {
                description: "Preferred/preference convertible shares",
                letter: "F"
              },
              {
                description: "Limited partnership units",
                letter: "L"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Redemption/conversion of the underlying assets (Guideline: for common/ordinary shares and limited partnership units, only the values N (perpetual) and X (not applicable/undefined) may be used. All values apply for other underlying instruments.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Redeemable",
                letter: "R"
              },
              {
                description: "Perpetual",
                letter: "N"
              },
              {
                description: "Convertible",
                letter: "B"
              },
              {
                description: "Convertible/redeemable",
                letter: "D"
              }
            ]
          },
          attribute_3: {
            description: "Income (indicates the kind of dividend income the shareholders are entitled to)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate income",
                letter: "F"
              },
              {
                description: "Cumulative, fixed rate income",
                letter: "C"
              },
              {
                description: "Participating income",
                letter: "P"
              },
              {
                description: "Cumulative, participating income",
                letter: "Q"
              },
              {
                description: "Adjustable/variable rate income",
                letter: "A"
              },
              {
                description: "Normal rate income",
                letter: "N"
              },
              {
                description: "Auction rate income",
                letter: "U"
              },
              {
                description: "Dividends",
                letter: "N"
              }
            ]
          },
          attribute_4: {
            description: "Form",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer",
                letter: "B"
              },
              {
                description: "Registered",
                letter: "R"
              },
              {
                description: "Bearer/registered",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      Y: {
        name: "Structured instruments (participation)",
        description: "The construction is generally based on a low exercise price option (LEPO) (base value less discounted future dividends) which in some cases might be comparable to a direct investment in the underlying asset(s) or a LEPO combined with other options, which together provide the desired disbursement profile.",
        attributes: {
          attribute_1: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Tracker certificate [participation in development of the underlying asset(s); reflects underlying price moves 1:1 (adjusted by conversion ratio and any related fees); risk is comparable to direct investment in the underlying asset(s)]",
                letter: "A"
              },
              {
                description: "Outperformance certificate [participation in development of the underlying asset(s); disproportionate participation (outperformance) in positive performance above the strike; reflects underlying price moves 1:1 (adjusted by conversion ratio and any related fees); risk is comparable to direct investment in the underlying asset(s)]",
                letter: "B"
              },
              {
                description: "Bonus certificate [participation in development of the underlying asset(s); minimum redemption is equal to the nominal value provided the barrier has not been breached; if the barrier is breached the product changes into a tracker certificate; with greater risk multiple underlying asset(s) (worst-of) allow for a higher bonus level or lower barrier; reduced risk compared to a direct investment into the underlying asset(s)]",
                letter: "C"
              },
              {
                description: "Outperformance bonus certificate [participation in development of the underlying asset(s); disproportionate participation (outperformance) in positive performance above the strike; minimum redemption is equal to the nominal value provided the barrier has not been breached; if the barrier is breached the product changes into an outperformance certificate; with greater risk multiple underlying asset(s) (worst-of) allow for a higher bonus level or lower barrier; reduced risk compared to a direct investment into the underlying asset(s)]",
                letter: "D"
              },
              {
                description: "Twin-win-certificate [participation in development of the underlying asset(s); profits possible with rising and falling underlying asset values; falling underlying asset price converts into profit up to the barrier; minimum redemption is equal to the nominal value provided the barrier has not been breached; if the barrier is breached the product changes into a tracker certificate; with higher risk levels, multiple underlying asset(s) (worst-of) allow for a higher bonus level or lower barrier; reduced risk compared to a direct investment into the underlying asset(s)]",
                letter: "E"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution (indicates the cash distribution provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Dividend payments (depending on strategy of the structured instrument)",
                letter: "D"
              },
              {
                description: "No payments",
                letter: "Y"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Repayment (indicates the repayment form provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash repayment",
                letter: "F"
              },
              {
                description: "Physical repayment",
                letter: "V"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Underlying assets  (indicates the type of underlying assets in which the structured instrument participates)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets (group of securities that have been put together for a specific investment purpose)",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Derivatives (options, futures, swaps, spot, forwards, strategies, financing)",
                letter: "G"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies (specified exchange rate)",
                letter: "C"
              },
              {
                description: "Indices (the performance of an index)",
                letter: "I"
              },
              {
                description: "Interest rates (specified amount based on the future level of interest rates)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Equities that do not fit into any of the other Equity Groups",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  C: {
    name: "CIVs",
    description: "Securities representing a portion of assets pooled by investors run by a management company whose share capital remains separate from such assets and includes issues of shares or units in the form of, for example, a unit trust, mutual fund, OICVM, OPCVM, SICAV or SICAF.",
    groups: {
      I: {
        name: "Standard (vanilla) investment funds/mutual funds",
        description: "An investment vehicle that is made up of a pool of funds collected from many investors for the purpose of investing in securities such as stocks, bonds, money market instruments and similar assets.",
        attributes: {
          attribute_1: {
            description: "Closed/open-end (indicates whether units are traded or whether funds continually stand ready to sell new units and redeem the outstanding units on demand)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed-end [units are sold on either an organized exchange or in the over-the-counter (OTC) market and are usually not redeemed]",
                letter: "C"
              },
              {
                description: "Open-end (funds permanently sell new units to the public and redeem outstanding units on demand, resulting in an increase or decrease of outstanding capital)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution policy (indicates the fund's normal distribution policy)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Income funds (the fund regularly distributes its investment profits)",
                letter: "I"
              },
              {
                description: "Accumulation funds (the fund normally reinvests its investment profits)",
                letter: "G"
              },
              {
                description: "Mixed funds (investment profits are partly distributed, partly reinvested)",
                letter: "J"
              }
            ]
          },
          attribute_3: {
            description: "Assets (indicates the underlying assets in which the fund invests)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Real estate",
                letter: "R"
              },
              {
                description: "Debt instruments (fund invests in debt instrument regardless of maturity)",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "E"
              },
              {
                description: "Convertible securities",
                letter: "V"
              },
              {
                description: "Mixed (fund invests in different assets)",
                letter: "L"
              },
              {
                description: "Commodities",
                letter: "C"
              },
              {
                description: "Derivatives",
                letter: "D"
              },
              {
                description: "Referential instruments excluding commodities",
                letter: "F"
              },
              {
                description: "Credits [contractual agreement in which a borrower receives something of value (good, service or money) now and agrees to repay the lender at some date in the future, generally with interest; CIVs normally invest in credits originated by third parties; credits are not freely transferable like debt securities]",
                letter: "K"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares (retail and/or qualified/institutional/professional investors)",
                letter: "S"
              },
              {
                description: "Shares for QI (qualified/institutional/professional investors only)",
                letter: "Q"
              },
              {
                description: "Units (retail and/or qualified/institutional/professional investors)",
                letter: "U"
              },
              {
                description: "Units for QI (qualified/institutional/professional investors only)",
                letter: "Y"
              }
            ]
          }
        }
      },
      H: {
        name: "Hedge funds",
        description: "Type of investment fund which pursues a total return and is usually open to qualified investors only.",
        attributes: {
          attribute_1: {
            description: "Investment strategy  (the investment process describes core hedge fund strategy characteristics)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Directional [the two biggest constituents of directional are macro and commodity trading advisor (CTA)/managed futures; macro describes directional strategies that are based upon the direction of market prices of currencies, commodities, equities, fixed income and includes futures and cash markets; CTA/managed futures describe strategies that are based upon futures contracts across all asset classes only]",
                letter: "D"
              },
              {
                description: "Relative value (strategies focusing on the spread relationships across various financial assets or commodities; they often utilize leverage and avoid market risk, although spread risk may often be large)",
                letter: "R"
              },
              {
                description: "Security selection (strategies typically equity-based and including long/short equity; the manager attempts to make money from superior stock selection by building some combination of long and short positions in such a way to mitigate systematic market risks)",
                letter: "S"
              },
              {
                description: "Event-driven (combination of investment strategies focusing on securities that are expected to experience a change in valuation due to corporate transactions or events such as bankruptcies)",
                letter: "E"
              },
              {
                description: "Arbitrage (in economics and finance, arbitrage is the practice of taking advantage of a price difference between two or more markets, striking a combination of matching deals that capitalize upon the imbalance, the profit being the difference between the market prices)",
                letter: "A"
              },
              {
                description: "Multi-strategy (multi-strategy as a separate set of investment strategies is broad and by it the manager is expected to maintain approximately 25 % of portfolio exposure in two or more strategies that are distinct from one another)",
                letter: "N"
              },
              {
                description: "Asset-based lending (strategy based on providing loans against assets to companies, including the ones viewed as not being creditworthy by commercial banks; the amount of the loan is secured by claims against the borrower’s assets and as such it is directly determined by the assets' value)",
                letter: "L"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      B: {
        name: "Real estate investment trust (REITs)",
        description: "A REIT is a real estate company that offers shares/units to the public and invests in real estate directly, either through properties or mortgages.",
        attributes: {
          attribute_1: {
            description: "Closed/open-end (indicates whether units are traded or whether funds continually stand ready to sell new units and redeem the outstanding units on demand)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed-end [units are sold on either an organized exchange or in the over-the-counter (OTC) market and are usually not redeemed]",
                letter: "C"
              },
              {
                description: "Open-end (funds permanently sell new units to the public and redeem outstanding units on demand, resulting in an increase or decrease of outstanding capital)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution policy (indicates the fund's normal distribution policy)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Income funds (the fund regularly distributes its investment profits)",
                letter: "I"
              },
              {
                description: "Accumulation funds (the fund normally reinvests its investment profits)",
                letter: "G"
              },
              {
                description: "Mixed funds (investment profits are partly distributed, partly reinvested)",
                letter: "J"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares (retail and/or qualified/institutional/professional investors)",
                letter: "S"
              },
              {
                description: "Shares for QI (qualified/institutional/professional investors only)",
                letter: "Q"
              },
              {
                description: "Units (retail and/or qualified/institutional/professional investors)",
                letter: "U"
              },
              {
                description: "Units for QI (qualified/institutional/professional investors only)",
                letter: "Y"
              }
            ]
          }
        }
      },
      E: {
        name: "Exchange traded funds (ETFs)",
        description: "An ETF is an investment fund traded on stock exchanges, much like stocks. An ETF holds assets such as stocks, commodities or bonds, and trades close to its net asset value over the course of the trading day. Most ETFs track an index, such as a stock, bond or commodity. index",
        attributes: {
          attribute_1: {
            description: "Closed/open-end (indicates whether units are traded or whether funds continually stand ready to sell new units and redeem the outstanding units on demand)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed-end [units are sold on either an organized exchange or in the over-the-counter (OTC) market and are usually not redeemed]",
                letter: "C"
              },
              {
                description: "Open-end (funds permanently sell new units to the public and redeem outstanding units on demand, resulting in an increase or decrease of outstanding capital)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution policy (indicates the fund's normal distribution policy)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Income funds (the fund regularly distributes its investment profits)",
                letter: "I"
              },
              {
                description: "Accumulation funds (the fund normally reinvests its investment profits)",
                letter: "G"
              },
              {
                description: "Mixed funds (investment profits are partly distributed, partly reinvested)",
                letter: "J"
              }
            ]
          },
          attribute_3: {
            description: "Assets (indicates the underlying assets in which the fund invests)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Real estate",
                letter: "R"
              },
              {
                description: "Debt instruments (fund invests in debt instrument regardless of maturity)",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "E"
              },
              {
                description: "Convertible securities",
                letter: "V"
              },
              {
                description: "Mixed (fund invests in different assets)",
                letter: "L"
              },
              {
                description: "Commodities",
                letter: "C"
              },
              {
                description: "Derivatives",
                letter: "D"
              },
              {
                description: "Referential instruments excluding commodities",
                letter: "F"
              },
              {
                description: "Credits [contractual agreement in which a borrower receives something of value (good, service or money) now and agrees to repay the lender at some date in the future, generally with interest; CIVs normally invest in credits originated by third parties; credits are not freely transferable like debt securities]",
                letter: "K"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares",
                letter: "S"
              },
              {
                description: "Units",
                letter: "U"
              }
            ]
          }
        }
      },
      S: {
        name: "Pension funds",
        description: "A pension fund is run by a financial intermediary for the company and its employees. The pension fund is a common asset pool meant to generate stable growth over the long term.",
        attributes: {
          attribute_1: {
            description: "Closed/open",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed (pension funds supporting only pension plans that are limited to certain employees)",
                letter: "C"
              },
              {
                description: "Open (pension funds supporting at least one pension plan with no restriction on membership)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Strategy/style",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Balanced/conservative",
                letter: "B"
              },
              {
                description: "Growth",
                letter: "G"
              },
              {
                description: "Life style (strategy changes depending on age group of members)",
                letter: "L"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Defined benefit",
                letter: "R"
              },
              {
                description: "Defined contribution",
                letter: "B"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares",
                letter: "S"
              },
              {
                description: "Units",
                letter: "U"
              }
            ]
          }
        }
      },
      F: {
        name: "Funds of funds",
        description: "A fund of funds is a CIV that invests directly in other investment funds rather than investing in stocks, bonds or other securities.",
        attributes: {
          attribute_1: {
            description: "Closed/open-end (indicates whether units are traded or whether funds continually stand ready to sell new units and redeem the outstanding units on demand)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed-end [units are sold on either an organized exchange or in the over-the-counter (OTC) market and are usually not redeemed]",
                letter: "C"
              },
              {
                description: "Open-end (funds permanently sell new units to the public and redeem outstanding units on demand, resulting in an increase or decrease of outstanding capital)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution policy (indicates the fund's normal distribution policy)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Income funds (the fund regularly distributes its investment profits)",
                letter: "I"
              },
              {
                description: "Accumulation funds (the fund normally reinvests its investment profits)",
                letter: "G"
              },
              {
                description: "Mixed funds (investment profits are partly distributed, partly reinvested)",
                letter: "J"
              }
            ]
          },
          attribute_3: {
            description: "Type of funds ( indicates the type of funds in which the fund invests)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Standard (vanilla) investment funds/mutual funds",
                letter: "I"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              },
              {
                description: "Hedge funds",
                letter: "H"
              },
              {
                description: "REITs",
                letter: "B"
              },
              {
                description: "ETFs",
                letter: "E"
              },
              {
                description: "Private equity funds",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares (retail and/or qualified/institutional/professional investors)",
                letter: "S"
              },
              {
                description: "Shares for QI (qualified/institutional/professional investors only)",
                letter: "Q"
              },
              {
                description: "Units (retail and/or qualified/institutional/professional investors)",
                letter: "U"
              },
              {
                description: "Units for QI (qualified/institutional/professional investors only)",
                letter: "Y"
              }
            ]
          }
        }
      },
      P: {
        name: "Private equity funds",
        description: "A private equity fund is normally structured as a limited partnership or a limited liability company (investors are limited partners) managed by a GP.",
        attributes: {
          attribute_1: {
            description: "Closed/open-end (indicates whether units are traded or whether funds continually stand ready to sell new units and redeem the outstanding units on demand)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Closed-end [units are sold on either an organized exchange or in the over-the-counter (OTC) market and are usually not redeemed]",
                letter: "C"
              },
              {
                description: "Open-end (funds permanently sell new units to the public and redeem outstanding units on demand, resulting in an increase or decrease of outstanding capital)",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution policy (indicates the fund's normal distribution policy)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Income funds (the fund regularly distributes its investment profits)",
                letter: "I"
              },
              {
                description: "Accumulation funds (the fund normally reinvests its investment profits)",
                letter: "G"
              },
              {
                description: "Mixed funds (investment profits are partly distributed, partly reinvested)",
                letter: "J"
              }
            ]
          },
          attribute_3: {
            description: "Assets (indicates the underlying assets in which the fund invests)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Real estate",
                letter: "R"
              },
              {
                description: "Debt instruments (fund invests in debt instrument regardless of maturity)",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "E"
              },
              {
                description: "Convertible securities",
                letter: "V"
              },
              {
                description: "Mixed (fund invests in different assets)",
                letter: "L"
              },
              {
                description: "Commodities",
                letter: "C"
              },
              {
                description: "Derivatives",
                letter: "D"
              },
              {
                description: "Referential instruments excluding commodities",
                letter: "F"
              },
              {
                description: "Credits [contractual agreement in which a borrower receives something of value (good, service or money) now and agrees to repay the lender at some date in the future, generally with interest; CIVs normally invest in credits originated by third parties; credits are not freely transferable like debt securities]",
                letter: "K"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares (retail and/or qualified/institutional/professional investors)",
                letter: "S"
              },
              {
                description: "Shares for QI (qualified/institutional/professional investors only)",
                letter: "Q"
              },
              {
                description: "Units (retail and/or qualified/institutional/professional investors)",
                letter: "U"
              },
              {
                description: "Units for QI (qualified/institutional/professional investors only)",
                letter: "Y"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "CIVs which do not fit into any of the above Groups.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Security type and investor restrictions",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Shares (retail and/or qualified/institutional/professional investors)",
                letter: "S"
              },
              {
                description: "Shares for QI (qualified/institutional/professional investors only)",
                letter: "Q"
              },
              {
                description: "Units (retail and/or qualified/institutional/professional investors)",
                letter: "U"
              },
              {
                description: "Units for QI (qualified/institutional/professional investors only)",
                letter: "Y"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  D: {
    name: "Debt instruments",
    description: "Financial instruments evidencing monies owed by the issuer to the holder on terms as specified.",
    groups: {
      B: {
        name: "Bonds",
        description: "Any interest-bearing or discounted security that normally obliges the issuer to pay the bondholder a contracted sum of money and to repay the principal amount of the debt.",
        attributes: {
          attribute_1: {
            description: "Type of interest or cash payment",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate (all interest payments are known at issuance and remain constant for the life of the issue)",
                letter: "F"
              },
              {
                description: "Zero rate/discounted [no periodical interest payments are made; the interest charge (discount) is the difference between maturity value and proceeds at time of acquisition]",
                letter: "Z"
              },
              {
                description: "Variable (the interest rate is subject to adjustment through the life of the issue; includes graduated, i.e. step-up/step-down, floating and indexed interest rates)",
                letter: "V"
              },
              {
                description: "Cash payment (this attribute applies only for sukuk certificates; a sukuk takes place when a set of investors pool their wealth to invest in accordance with sharia principles to earn profits which are then distributed pro rata)",
                letter: "C"
              },
              {
                description: "Payment in kind (pays interest using other assets instead of cash)",
                letter: "K"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      C: {
        name: "Convertible bonds",
        description: "A bond that can be converted into other securities.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              },
              {
                description: "Payment in kind",
                letter: "K"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      W: {
        name: "Bonds with warrants attached",
        description: "A bond that is issued together with one or more warrant(s) attached as part of the offer, the warrant(s) granting the holder the right to purchase a designated security, often the common stock of the issuer of the debt, at a specified price.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              },
              {
                description: "Payment in kind",
                letter: "K"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      T: {
        name: "Medium-term notes",
        description: "Negotiable debt instruments offered under a program agreement through one or more dealers upon request of the issuer. The program defines the terms and conditions of the notes.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              },
              {
                description: "Payment in kind",
                letter: "K"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      Y: {
        name: "Money market instruments",
        description: "Financial instruments designated at issuance as such with a short-term life, for instance treasury bills and commercial paper including municipal money market instruments.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              },
              {
                description: "Payment in kind",
                letter: "K"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      S: {
        name: "Structured instruments (capital protection)",
        description: "Capital protected structured instruments offer investors exposure to chosen underlying assets using various approaches and offering a large variety of asymmetric pay-off profiles. There are one or more reference entities underlying the product. Redemption is made at least in the amount of the conditional capital protection at maturity, provided that no credit event by the reference entity has occurred. Conditional capital protection only applies to the nominal amount and not to the purchase price. The general functioning of a capital guaranteed structured instrument is as follows: the notional amount is split into a zero bond, that will deliver the capital guarantee at maturity, and the difference between the zero bond’s value (= present value of the guarantee level at maturity) and the notional amount is used for structuring the performance component with options which deliver the agreed pay-off profile of the structured instrument.",
        attributes: {
          attribute_1: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Capital protection certificate with participation [minimum redemption at expiry equivalent to the capital protection; capital protection is defined as a percentage of the nominal amount (e.g. 100 %); capital protection refers to the nominal amount only, and not to the purchase price; the value of the product may fall below its capital protection value during its lifetime; participation is in the underlying price increase above the strike]",
                letter: "A"
              },
              {
                description: "Capital protection convertible certificate [minimum redemption at expiry equivalent to the capital protection; capital protection is defined as a percentage of the nominal price (e.g. 100 %); capital protection refers to the nominal price only, and not to the purchase price; the value of the product may fall below its capital protection value during its lifetime; participation is in the underlying price increase above the conversion price; coupon payment is possible]",
                letter: "B"
              },
              {
                description: "Barrier capital protection certificate [minimum redemption at expiry equivalent to the capital protection; capital protection is defined as a percentage of the nominal price (e.g. 100 %); capital protection refers to the nominal amount only, and not to the purchase price; the value of the product may fall below its capital protection value during its lifetime; participation is in the underlying price increase above the strike up to the barrier; possibility of rebate payment once barrier is breached; limited profit potential]",
                letter: "C"
              },
              {
                description: "Capital protection certificate with coupons [minimum redemption at expiry equivalent to the capital protection; capital protection is defined as a percentage of the nominal price (e.g. 100 %); capital protection refers to the nominal amount only, and not to the purchase price; the value of the product may fall below its capital protection value during its lifetime; the coupon amount is dependent on the development of the underlying asset; periodic coupon payment is expected]",
                letter: "D"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution (indicates the cash distribution provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed interest payments",
                letter: "F"
              },
              {
                description: "Dividend payments",
                letter: "D"
              },
              {
                description: "Variable interest payments",
                letter: "V"
              },
              {
                description: "No payments",
                letter: "Y"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Repayment (indicates the repayment form provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed cash repayment (only protected capital level)",
                letter: "F"
              },
              {
                description: "Variable cash repayment (protected capital level and additional performance capital depending on the underlying)",
                letter: "V"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Underlying assets (indicates the type of underlying assets in which the structured instrument participates)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies (specified exchange rate)",
                letter: "C"
              },
              {
                description: "Indices (the performance of an index)",
                letter: "I"
              },
              {
                description: "Interest rates (specified amount based on the future level of interest rates)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      E: {
        name: "Structured instruments (without capital protection)",
        description: "A structured instrument without capital protection is a short-term note linked to an underlying stock. The security offers a steady stream of income due to the payment of a coupon rate. The redemption at the end of the term is determined on the basis of the performance and final fixing of the underlying asset: a redemption at the nominal value is guaranteed as long as the underlying asset has not touched its barrier during relevant barrier monitoring. If the underlying asset has touched its barrier but is again above the strike price at final fixing, the nominal price is also repaid. Nevertheless, if the underlying asset has touched its barrier during barrier monitoring and closes below the strike price at final fixing, the underlying asset is delivered or cash compensation paid, provided that no credit event by the reference entity has occurred. Depending on the characteristics of the product, either a coupon or a discount to the underlying asset can apply. A coupon is paid out regardless of the performance of the underlying asset, provided that no credit event by the reference entity has occurred.",
        attributes: {
          attribute_1: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Discount certificate [should the underlying asset close below the strike on expiry, the underlying asset(s) and/or a cash amount is redeemed; discount certificates enable investors to acquire the underlying asset at a lower price; it corresponds to a buy-write-strategy; it has reduced risk compared to a direct investment into the underlying asset; with higher risk levels multiple underlying assets (worst-of) allow for higher discounts; limited profit opportunity (Cap)]",
                letter: "A"
              },
              {
                description: "Barrier discount certificate [the maximum redemption amount (Cap) is paid out if the barrier is never breached; barrier discount certificates enable investors to acquire the underlying asset(s) at a lower price; due to the barrier, the probability of maximum redemption is higher; the discount, however, is smaller than for a discount certificate; if the barrier is breached the product changes into a discount certificate; it has reduced risk compared to a direct investment into the underlying asset; limited profit potential (Cap); with higher risk levels multiple underlying assets (worst-of) allow for higher discounts or a lower barrier]",
                letter: "B"
              },
              {
                description: "Reverse convertible [should the underlying asset close below the strike on expiry, the underlying asset(s) and/or a cash amount is redeemed; should the underlying asset close above the strike at expiry, the nominal amount plus the coupon is paid at redemption; the coupon is paid regardless of the underlying development; it has reduced risk compared to a direct investment into the underlying asset; with higher risk levels, multiple underlying assets (worst-of) allow for higher coupons; limited profit potential (Cap)]",
                letter: "C"
              },
              {
                description: "Barrier reverse convertible [should the barrier never be breached, the nominal price plus coupon is paid at redemption; due to the barrier, the probability of maximum redemption is higher; the coupon, however, is smaller than for a reverse convertible; if the barrier is breached the product changes into a reverse convertible; the coupon is paid regardless of the underlying development; it has reduced risk compared to a direct investment into the underlying asset(s); with higher risk levels, multiple underlying assets (worst-of) allow for higher coupons or lower barriers; limited profit potential (Cap)]",
                letter: "D"
              },
              {
                description: "Express certificate [should the underlying trade above the strike on the observation date, an early redemption consisting of nominal price plus an additional coupon amount is paid; it offers the possibility of an early redemption combined with an attractive yield opportunity; it has reduced risk compared to a direct investment into the underlying asset(s); with higher risk levels, multiple underlying assets (worst-of) allow for higher coupons or lower barriers; limited profit opportunity (Cap)]",
                letter: "E"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Distribution (indicates the cash distribution provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed interest payments",
                letter: "F"
              },
              {
                description: "Dividend payments",
                letter: "D"
              },
              {
                description: "Variable interest payments",
                letter: "V"
              },
              {
                description: "No payments",
                letter: "Y"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Repayment (indicates the repayment form provided by the structured instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Repayment in cash (depending on the underlying, if the barrier is not breached)",
                letter: "R"
              },
              {
                description: "Repayment in assets",
                letter: "S"
              },
              {
                description: "Repayment in assets and cash",
                letter: "C"
              },
              {
                description: "Repayment in assets or cash",
                letter: "T"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Underlying assets (indicates the type of underlying assets in which the structured instrument participates)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies (specified exchange rate)",
                letter: "C"
              },
              {
                description: "Indices (the performance of an index)",
                letter: "I"
              },
              {
                description: "Interest rates (specified amount based on the future level of interest rates)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      G: {
        name: "Mortgage-backed securities",
        description: "Mortgage-backed securities are debt obligations that represent claims to the cash flows from pools of mortgage loans, most commonly on residential property. Mortgage loans are purchased from banks, mortgage companies and other originators, and then assembled into pools by a governmental, quasi-governmental or private entity. The entity then issues securities that represent claims on the principal and interest payments made by borrowers on the loans in the pool, a process known as securitization.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking \nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      A: {
        name: "Asset-backed securities",
        description: "Debt instruments backed by receivables other than those arising out of real estate, loans or mortgages.",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      N: {
        name: "Municipal bonds",
        description: "Bond issued by a state, provincial, city or local government excluding municipal money market securities, which shall be classified as debt, money market instruments (see money market instruments).",
        attributes: {
          attribute_1: {
            description: "Type of interest",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              }
            ]
          },
          attribute_2: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "State guarantee (any level below sovereign or national government)",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      D: {
        name: "Depositary receipts on debt instruments",
        description: "Depository receipts are securities that facilitate the ownership of instruments traded in other jurisdictions. Depository receipts are widely used in order to allow the trading of debt instruments in jurisdictions other than the one where the original debt instruments were issued.",
        attributes: {
          attribute_1: {
            description: "Instrument dependency",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bonds",
                letter: "B"
              },
              {
                description: "Convertible bonds",
                letter: "C"
              },
              {
                description: "Bonds with warrants attached",
                letter: "W"
              },
              {
                description: "Medium-term notes",
                letter: "T"
              },
              {
                description: "Money market instruments",
                letter: "Y"
              },
              {
                description: "Mortgage-backed securities",
                letter: "G"
              },
              {
                description: "Asset-backed securities",
                letter: "A"
              },
              {
                description: "Municipal bonds",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Type of interest/cash payment",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed rate",
                letter: "F"
              },
              {
                description: "Zero rate/discounted",
                letter: "Z"
              },
              {
                description: "Variable",
                letter: "V"
              },
              {
                description: "Cash payment",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Guarantee or ranking (indicates, in the case of the issuer's inability to settle, whether the debt issue is additionally secured\nGuideline: the values N (senior), O (senior subordinated), Q (junior) and J (junior subordinated) may only be used for unsecured securities. P (negative pledge) may only be used for unsecured securities that are neither senior nor junior. U (unsecured) may be used only if one of these codes does not apply to the relevant security.)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Government guarantee [the debt instrument is guaranteed by a federal, state, (semi)-government, sovereigns, agencies]",
                letter: "T"
              },
              {
                description: "Joint guarantee [the debt instrument is guaranteed by an entity (e.g. corporation) other than the issuer; not a federal or state government]",
                letter: "G"
              },
              {
                description: "Secured (debt issue against which specific assets are pledged to secure the obligation, e.g. mortgage or receivables)",
                letter: "S"
              },
              {
                description: "Unsecured/unguaranteed (the direct obligations of the issuer rest solely on its general credit)",
                letter: "U"
              },
              {
                description: "Negative pledge (the borrower agrees not to pledge any assets if such pledging would result in less security for the agreement's bondholders)",
                letter: "P"
              },
              {
                description: "Senior (applies to senior debts that are placed before senior subordinated, junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "N"
              },
              {
                description: "Senior subordinated (applies to senior subordinated debts that are placed before junior and junior subordinated in the ranking in the event of liquidation)",
                letter: "O"
              },
              {
                description: "Junior (applies to junior debts that are placed before junior subordinated in the ranking in the event of liquidation)",
                letter: "Q"
              },
              {
                description: "Junior subordinated (applies to junior subordinated debts in the ranking in the event of liquidation)",
                letter: "J"
              },
              {
                description: "Supranational (organization defined as being beyond the scope or borders of any one nation such as two or more central banks or two or more central governments. Examples of supranational include the United Nations, the European Union, the European Investment Bank and the World Bank)",
                letter: "C"
              }
            ]
          },
          attribute_4: {
            description: "Redemption/reimbursement (indicates the retirement provisions made for the debt issue)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Fixed maturity (the principal amount is repaid in full at maturity)",
                letter: "F"
              },
              {
                description: "Fixed maturity with call feature (the issue may be called for redemption prior to the fixed maturity date)",
                letter: "G"
              },
              {
                description: "Fixed maturity with put feature (the holder may request the reimbursement of his or her bonds prior to the maturity date)",
                letter: "C"
              },
              {
                description: "Fixed maturity with put and call",
                letter: "D"
              },
              {
                description: "Amortization plan (reduction of principal by regular payments)",
                letter: "A"
              },
              {
                description: "Amortization plan with call feature (the redemption of principal may occur as the result of the outstanding portion of the bond being called)",
                letter: "B"
              },
              {
                description: "Amortization plan with put feature",
                letter: "T"
              },
              {
                description: "Amortization plan with put and call",
                letter: "L"
              },
              {
                description: "Perpetual (the debt instrument has no fixed maturity date and is only due for redemption in the case of the issuer's liquidation)",
                letter: "P"
              },
              {
                description: "Perpetual with call feature (the issue may be called for redemption at some time in the future)",
                letter: "Q"
              },
              {
                description: "Perpetual with put feature (the issue may be puttable for redemption at some time in the future)",
                letter: "R"
              },
              {
                description: "Extendible",
                letter: "E"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Debt instruments that do not fit into any of the above Groups.",
        attributes: {
          attribute_1: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bank loan (an amount of money loaned at interest by a bank to a borrower, usually on collateral security, for a certain period of time)",
                letter: "B"
              },
              {
                description: "Promissory note (written promise by one party to pay another party a definite sum of money either on demand or at a specified future date)",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  R: {
    name: "Entitlement (rights)",
    description: "Financial instruments providing the holder with the privilege to subscribe to or receive specific assets on terms specified.",
    groups: {
      A: {
        name: "Allotment (bonus) rights",
        description: "Privileges allotted to existing security holders, entitling them to receive new securities free of charge.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      S: {
        name: "Subscription rights",
        description: "Privileges allotted to existing security holders, entitling them to subscribe to new securities at a price normally lower than the prevailing market price.",
        attributes: {
          attribute_1: {
            description: "Assets (indicates the type of assets that the rights holder is entitled to acquire)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Common/ordinary shares",
                letter: "S"
              },
              {
                description: "Preferred/preference shares",
                letter: "P"
              },
              {
                description: "Common/ordinary convertible shares",
                letter: "C"
              },
              {
                description: "Preferred/preference convertible shares",
                letter: "F"
              },
              {
                description: "Bonds",
                letter: "B"
              },
              {
                description: "Combined instruments",
                letter: "I"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      P: {
        name: "Purchase rights",
        description: "Anti-takeover device that gives a prospective acquiree's shareholders the right to buy shares of the firm or shares of anyone who acquires the firm at a deep discount to their fair market value.",
        attributes: {
          attribute_1: {
            description: "Assets (indicates the type of assets that the rights holder is entitled to acquire)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Common/ordinary shares",
                letter: "S"
              },
              {
                description: "Preferred/preference shares",
                letter: "P"
              },
              {
                description: "Common/ordinary convertible shares",
                letter: "C"
              },
              {
                description: "Preferred/preference convertible shares",
                letter: "F"
              },
              {
                description: "Bonds",
                letter: "B"
              },
              {
                description: "Combined instruments",
                letter: "I"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      W: {
        name: "Warrants",
        description: "Financial instruments which permit the holder to purchase a specified amount of a financial instrument, commodity, currency or other during a specified period at a specified price.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (indicates the type of underlying assets that the warrant holder is entitled to acquire)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets (the warrant holder is entitled to acquire a package or group of assets)",
                letter: "B"
              },
              {
                description: "Equities (the warrant holder is entitled to acquire equity)",
                letter: "S"
              },
              {
                description: "Debt instruments/interest rates (the warrant holder is entitled to acquire debt instruments)",
                letter: "D"
              },
              {
                description: "Commodities (the warrant holder is entitled to acquire a specific commodity)",
                letter: "T"
              },
              {
                description: "Currencies (the warrant holder is entitled to acquire a specified amount in a certain currency at a specified exchange rate)",
                letter: "C"
              },
              {
                description: "Indices (the warrant holder is entitled to acquire a specified amount based on the performance of an index)",
                letter: "I"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Type (indicates whether the warrant is issued by the issuer of the underlying instrument or by a third party)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Traditional warrants (issued by the issuer of the underlying instrument)",
                letter: "T"
              },
              {
                description: "Naked warrants (issued by a third party which is not the issuer of the underlying securities to which the warrant refers; warrant issuer does not hold as many securities as would be required if all the warrants are exercised)",
                letter: "N"
              },
              {
                description: "Covered warrants (issued by a third party which is not the issuer of the underlying securities to which the warrant refers; warrant issuer holds as many securities as would be required if all the warrants are exercised)",
                letter: "C"
              }
            ]
          },
          attribute_3: {
            description: "Call/put (indicates whether the warrant entitles the holder to acquire assets at specified terms or to acquire cash in exchange for specific underlying assets)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Call (in most cases, the warrant entitles the holder to acquire specific underlying assets during a specified period at a specified price)",
                letter: "C"
              },
              {
                description: "Put (the warrant entitles the holder to acquire cash in exchange for specific underlying assets)",
                letter: "P"
              },
              {
                description: "Call and put (warrants with neither call nor put feature or warrants with call and put feature)",
                letter: "B"
              }
            ]
          },
          attribute_4: {
            description: "Exercise option style",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European (warrant that can only be exercised for a short, specified period of time just prior to its expiration, usually a single day)",
                letter: "E"
              },
              {
                description: "American (warrant that can be exercised at any time between the purchase date and the expiration date)",
                letter: "A"
              },
              {
                description: "Bermudan (warrant that can only be exercised on predetermined dates, usually every month)",
                letter: "B"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      F: {
        name: "Mini-future certificates, constant leverage certificates",
        description: "Mini-futures combine the structure of open-end certificates with leverage option. Mini-futures have no fixed term. The leverage is therefore available without a term restriction. The price of a mini-future always corresponds to its intrinsic value, i.e. the capital outlay, plus the bid-ask spread. The financing costs associated with building up the leverage effect are offset against the capital outlay on a daily basis, thereby eliminating the need for a premium. Investors have to pay only financing costs they actually utilize. In contrast to options, factors like volatility have no influence at all on the price of mini-futures.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (indicates the type of underlying assets that the warrant holder is entitled to acquire)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets (the warrant holder is entitled to acquire a package or group of assets)",
                letter: "B"
              },
              {
                description: "Equities (the warrant holder is entitled to acquire equity)",
                letter: "S"
              },
              {
                description: "Debt instruments/interest rates (the warrant holder is entitled to acquire debt instruments)",
                letter: "D"
              },
              {
                description: "Commodities (the warrant holder is entitled to acquire a specific commodity)",
                letter: "T"
              },
              {
                description: "Currencies (the warrant holder is entitled to acquire a specified amount in a certain currency at a specified exchange rate)",
                letter: "C"
              },
              {
                description: "Indices (the warrant holder is entitled to acquire a specified amount based on the performance of an index)",
                letter: "I"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Barrier dependency type (indicates whether the instrument barrier depends on the underlying level or on the instrument trading price level)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Barrier underlying based (the instrument immediately expires if the barrier underlying level is breached during product lifetime)",
                letter: "T"
              },
              {
                description: "Barrier instrument based (the instrument immediately expires if the barrier instrument trading price level is breached during product lifetime)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Long/short (indicates whether the instrument entitles the holder to acquire assets at specified terms or to acquire cash in exchange for specific underlying assets)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Long (in most cases, the instrument entitles the holder to acquire specific underlying assets during a specified period at a specified price)",
                letter: "C"
              },
              {
                description: "Short (the instrument entitles the holder to acquire cash in exchange for specific underlying assets)",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Exercise option style",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European (warrant that can only be exercised for a short, specified period of time just prior to its expiration, usually a single day)",
                letter: "E"
              },
              {
                description: "American (warrant that can be exercised at any time between the purchase date and the expiration date)",
                letter: "A"
              },
              {
                description: "Bermudan (warrant that can only be exercised on predetermined dates, usually every month)",
                letter: "B"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      D: {
        name: "Depositary receipts on entitlements",
        description: "Depository receipts are securities that facilitate the ownership of instruments traded in other jurisdictions. Depository receipts are widely used in order to allow the trading of entitlements in jurisdictions other than the one where the original entitlements were issued.",
        attributes: {
          attribute_1: {
            description: "Instrument dependency",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Allotment (bonus) rights",
                letter: "A"
              },
              {
                description: "Subscription rights",
                letter: "S"
              },
              {
                description: "Purchase rights",
                letter: "P"
              },
              {
                description: "Warrants",
                letter: "W"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Entitlements (rights) that do not fit into any of the above Groups described.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  O: {
    name: "Listed options",
    description: "This Category classifies listed options, which are contracts that grant to the holder either the privilege to purchase or the privilege to sell the assets specified at a predetermined price or formula at or within a time in the future. Where a listed option cannot be classified within this Category, refer to non-listed and complex listed options.",
    groups: {
      C: {
        name: "Call options",
        description: "Contracts between a buyer and a seller giving the buyer (holder) the right, but not the obligation, to buy the assets specified at a fixed price or formula, on or before a specified date. The seller of the call option assumes the obligation of delivering the assets specified should the buyer exercise his or her option.",
        attributes: {
          attribute_1: {
            description: "Exercise option style",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European",
                letter: "E"
              },
              {
                description: "American",
                letter: "A"
              },
              {
                description: "Bermudan",
                letter: "B"
              }
            ]
          },
          attribute_2: {
            description: "Underlying assets (indicates the type of underlying assets that the option holder is entitled to acquire)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Stock-equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies",
                letter: "C"
              },
              {
                description: "Indices",
                letter: "I"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Swaps",
                letter: "W"
              },
              {
                description: "Interest rates",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Delivery (indicates whether the settlement of the option, when exercised, is made in cash or whether the underlying instruments are delivered)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical [the meeting of a settlement obligation under a derivative contract through the receipt or delivery of the actual underlying instrument(s) instead of through cash settlement]",
                letter: "P"
              },
              {
                description: "Cash (the discharge of an obligation by payment or receipt of a net cash amount instead of payment or delivery by both parties)",
                letter: "C"
              },
              {
                description: "Non-deliverable [synthetic options on foreign exchange forwards that are based on non-convertible or thinly traded currencies]",
                letter: "N"
              },
              {
                description: "Elect at exercise (the method of delivery of the underlying instrument when the option is exercised shall be determined at the time of exercise)",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Standardized/non-standardized (indicates whether the terms of the contract are standardized or not)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Standardized (the underlying instruments, exercise price, expiration date and contract size of the options are standardized; these options are traded on special option exchanges)",
                letter: "S"
              },
              {
                description: "Non-standardized (options traded on option exchanges which have non-standard delivery or expiry terms)",
                letter: "N"
              }
            ]
          }
        }
      },
      P: {
        name: "Put options",
        description: "Contracts between a buyer and a seller giving the buyer (holder) the right, but not the obligation, to sell the assets specified at a fixed price or formula, on or before a specified date. The seller of the put option assumes the obligation of buying the assets specified should the buyer exercise his or her option.",
        attributes: {
          attribute_1: {
            description: "Exercise option style",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European",
                letter: "E"
              },
              {
                description: "American",
                letter: "A"
              },
              {
                description: "Bermudan",
                letter: "B"
              }
            ]
          },
          attribute_2: {
            description: "Underlying assets (indicates the type of underlying assets that the option holder is entitled to sell)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Stock-equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies",
                letter: "C"
              },
              {
                description: "Indices",
                letter: "I"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Swaps",
                letter: "W"
              },
              {
                description: "Interest rates",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Delivery (indicates whether the settlement of the option, when exercised, is made in cash or whether the underlying instruments are delivered)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical [the meeting of a settlement obligation under a derivative contract through the receipt or delivery of the actual underlying instrument(s) instead of through cash settlement]",
                letter: "P"
              },
              {
                description: "Cash (the discharge of an obligation by payment or receipt of a net cash amount instead of payment or delivery by both parties)",
                letter: "C"
              },
              {
                description: "Non-deliverable [synthetic options on foreign exchange forwards that are based on non-convertible or thinly traded currencies]",
                letter: "N"
              },
              {
                description: "Elect at exercise (the method of delivery of the underlying instrument when the option is exercised shall be determined at the time of exercise)",
                letter: "E"
              }
            ]
          },
          attribute_4: {
            description: "Standardized/non-standardized (indicates whether the terms of the contract are standardized or not)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Standardized (the underlying instruments, exercise price, expiration date and contract size of the options are standardized; these options are traded on special option exchanges)",
                letter: "S"
              },
              {
                description: "Non-standardized (options traded on option exchanges which have non-standard delivery or expiry terms)",
                letter: "N"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Options that do not fit into any of the above Groups.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  F: {
    name: "Futures",
    description: "Contracts, listed on an exchange or regulated market, which obligate the buyer to receive and the seller to deliver in the future the assets specified at an agreed price. This includes forwards on regulated markets.",
    groups: {
      F: {
        name: "Financial futures",
        description: "Futures contracts based on underlying assets excluding commodities.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (indicates the type of underlying assets that the futures buyer receives, and that the seller delivers)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Stock-equities",
                letter: "S"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "Currencies",
                letter: "C"
              },
              {
                description: "Indices",
                letter: "I"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Swaps",
                letter: "W"
              },
              {
                description: "Interest rates",
                letter: "N"
              },
              {
                description: "Stock dividends",
                letter: "V"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Delivery (indicates whether the settlement of the future is made in cash or whether the underlying instruments are delivered)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Non-deliverable",
                letter: "N"
              }
            ]
          },
          attribute_3: {
            description: "Standardized/non-standardized (indicates whether the terms of the contract are standardized or not)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Standardized (the underlying instruments, exercise price, expiration date and contract size of the options are standardized; these options are traded on special option exchanges)",
                letter: "S"
              },
              {
                description: "Non-standardized (options traded on option exchanges which have non-standard delivery or expiry terms)",
                letter: "N"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      C: {
        name: "Commodities futures",
        description: "Futures contracts based on bulk goods.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Extraction resources (metals, precious metals, coal, oil, gas)",
                letter: "E"
              },
              {
                description: "Agriculture (commodities which include forestry, fishing, livestock, grain, dairy, corn, cocoa, soybeans, sugar, coffee)",
                letter: "A"
              },
              {
                description: "Industrial products (construction, manufacturing)",
                letter: "I"
              },
              {
                description: "Services (transportation, communication, trade)",
                letter: "S"
              },
              {
                description: "Environmental (includes carbon-related, emission reduction, weather)",
                letter: "N"
              },
              {
                description: "Polypropylene products (includes plastics)",
                letter: "P"
              },
              {
                description: "Generated resources (includes electricity, renewable energy, or any power/energy delivered through a utility network or provider)",
                letter: "H"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Delivery (indicates whether the settlement of the future is made in cash or whether the underlying instruments are delivered)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Non-deliverable",
                letter: "N"
              }
            ]
          },
          attribute_3: {
            description: "Standardized/non-standardized (indicates whether the terms of the contract are standardized or not)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Standardized (the underlying instruments, exercise price, expiration date and contract size of the options are standardized; these options are traded on special option exchanges)",
                letter: "S"
              },
              {
                description: "Non-standardized (options traded on option exchanges which have non-standard delivery or expiry terms)",
                letter: "N"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  S: {
    name: "Swaps",
    description: "A swap is an agreement or contract where two counterparties agree to exchange periodic streams of cash flows with each other. Swaps can be executed with a variety of asset classes, as listed below.",
    groups: {
      R: {
        name: "Rates",
        description: "A rates swap is a contract in which two counterparties each agree to pay the other cash flows on defined dates during an agreed period, based on a specified notional amount and a floating interest, floating inflation or fixed interest rate.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Basis swap (float-float) [a rate swap where the cash flows that are exchanged between each party are based on different floating interest rates or prices (i.e. one party pays an agreed floating rate multiplied by a notional amount, in exchange for receipt of periodic payments based on another agreed floating rate multiplied by the same notional amount, from the other party)], except those swaps covered by the definitions below for attributes G or H",
                letter: "A"
              },
              {
                description: "Fixed-floating [a rate swap in which one party (the fixed rate payer) agrees to make fixed payments (the fixed leg) on set dates for an agreed period to another party (the floating rate payer), based on a fixed interest rate multiplied by a notional amount, in exchange for receipt of periodic payments (the floating leg), from the floating rate payer, based on a floating interest rate index multiplied by the same notional amount (in most cases) upon which the fixed rate payments are based], except those swaps covered by the definitions below for attributes G, H or Z",
                letter: "C"
              },
              {
                description: "Fixed-fixed (a rate swap in which both parties pay a fixed interest rate that they could not otherwise obtain outside of a swap arrangement; for example, if each counterparty uses a different native currency, but wants to borrow money in the other counterparty’s native currency; fixed-fixed swaps generally take the form of either a zero coupon swap or a cross-currency swap), except those swaps covered by the definitions below for attributes G or Z",
                letter: "D"
              },
              {
                description: "Inflation swap (a rate swap in which one party pays an amount calculated using an inflation rate index, and the other party pays an amount calculated using another inflation rate index, or a fixed or floating interest rate)",
                letter: "G"
              },
              {
                description: "Overnight index swap (OIS) [a rate swap in which one party (the fixed rate payer) makes periodic payments to another party (the floating rate payer) based on a fixed interest rate (other than zero) or floating interest rate multiplied by a notional amount in exchange for receipt of periodic payments based on an overnight interest rate index multiplied by the same notional amount upon which the fixed rate payments are based]",
                letter: "H"
              },
              {
                description: "Zero coupon [a rate swap in which the fixed rate cash flows are compounded and paid once on the expiration data, rather than periodically; the payments on the other side (which can be based on a floating interest rate or a fixed rate) follow typical swap payment schedules]",
                letter: "Z"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Notional (indicates the face amount of a swap upon which the payment streams for that swap are based)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Constant (the notional amount is constant through the life of the contract)",
                letter: "C"
              },
              {
                description: "Accreting (the notional amount increases through the life of the contract)",
                letter: "I"
              },
              {
                description: "Amortizing (the notional amount decreases through the life of the contract)",
                letter: "D"
              },
              {
                description: "Custom (customized notional step schedule)",
                letter: "Y"
              }
            ]
          },
          attribute_3: {
            description: "Single or multi-currency (indicates whether the swap is single or multi-currency)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single currency",
                letter: "S"
              },
              {
                description: "Cross-currency (multi-currency)",
                letter: "C"
              }
            ]
          },
          attribute_4: {
            description: "Delivery (indicates whether the payment currency for each leg of the swap is the same as the reference currency for that leg)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Deliverable (the settlement, i.e. payment, currency amounts are paid in the respective reference currency for each leg of the swap for which the payments are being made)",
                letter: "D"
              },
              {
                description: "Non-deliverable (the settlement, i.e. payment, currency amounts are paid in a single currency that either reflects the currency of either leg of the swap or in a currency other than the respective reference currency for each leg of the swap for which the payments are being made)",
                letter: "N"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "A commodity swap is a derivative contract where the value of the contract is derived from an underlying commodity or commodity index. Commodity derivatives can be physically settled or cash settled. Primary underliers include metals, agricultural goods and energy.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Energy [an energy-related product, or a derivative of an energy-related product, including electricity, renewable energy, or any power/energy delivered through a utility network of provider; diesel fuel, fuel oil, gas oil, gasoline, heating oil, jet fuel, kerosene, natural gas, oil (Brent, Tapis, Dubai, WTI)]",
                letter: "J"
              },
              {
                description: "Metals (a precious or industrial metal, such as aluminium, copper, gold, lead, nickel, platinum, silver, tin, zinc)",
                letter: "K"
              },
              {
                description: "Agriculture (commodities which include forestry, fishing, livestock, grain, dairy, corn, cocoa, soybeans, sugar, coffee)",
                letter: "A"
              },
              {
                description: "Environmental (includes carbon-related, emission reduction, weather)",
                letter: "N"
              },
              {
                description: "Freight (the specified commodity is a freight index route)",
                letter: "G"
              },
              {
                description: "Polypropylene products",
                letter: "P"
              },
              {
                description: "Fertilizer [ammonia, diammonium phosphate (DAP), potash, sulphur, urea, urea and ammonium nitrate (UAN)]",
                letter: "S"
              },
              {
                description: "Paper (containerboard, newsprint, pulp, recovered paper)",
                letter: "T"
              },
              {
                description: "Index – single-commodity (an index containing constituents from one of the underlying assets identified for this attribute)",
                letter: "I"
              },
              {
                description: "Index – multi-commodity (an index containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "H"
              },
              {
                description: "Basket – single-commodity (a custom basket containing constituents from one of the underlying assets identified for this attribute)",
                letter: "B"
              },
              {
                description: "Basket – multi-commodity (a custom basket containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "C"
              },
              {
                description: "Multi-commodity (each leg of the swap references a different respective commodity than the other leg)",
                letter: "Q"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Return or payout trigger (method used to determine contract value)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Contract for difference (CFD) (a cash-settled total return swap or forward where the parties agree to exchange on the maturity of the contract the difference between the opening price and closing price of the underlying)",
                letter: "C"
              },
              {
                description: "Total return [the total economic return of an underlying asset is transferred from one party (total return buyer) to another (total return seller); total return seller takes on the risk of negative changes in market value of the reference asset, and pays any positive cash flow to the buyer such as coupon, capital gains or dividends of the reference asset]",
                letter: "T"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      E: {
        name: "Equity",
        description: "An equity swap is a derivative contract where payments are linked to the change in value of an underlying equity (e.g. shares, basket of equities or index). Equity swaps can be physically or cash settled.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single stock (single name security)",
                letter: "S"
              },
              {
                description: "Index (a synthetic portfolio of underlying assets whose components have been set by a third-party administrator)",
                letter: "I"
              },
              {
                description: "Basket (a bespoke, synthetic portfolio of underlying assets whose components have been agreed to for a specific OTC derivative by the parties to the transaction)",
                letter: "B"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Return or payout trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Price (price return equity swap; similar to a total return swap, except that dividends are not passed through to the buyer)",
                letter: "P"
              },
              {
                description: "Dividend (a fixed-term contract between two parties where one party will make an interest rate payment for each interval and the other party will pay the total dividends received as pay-out by a selected underlying asset)",
                letter: "D"
              },
              {
                description: "Variance [a forward swap that uses the variance (being the volatility squared) of an underlying’s price movement over a period as the basis for the payoff calculation]",
                letter: "V"
              },
              {
                description: "Volatility (the variability of movements in a security or underlying instrument’s price; it is a measure of the amount by which an asset’s price is expected to fluctuate over a given period of time; it is normally measured by the annual standard deviation of daily price changes)",
                letter: "L"
              },
              {
                description: "Total return",
                letter: "T"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      C: {
        name: "Credit",
        description: "A credit swap references a value or event related to a debt product or debt issuer.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single name (the underlying risk is a single reference entity or reference obligation)",
                letter: "U"
              },
              {
                description: "Index tranche [a synthetic collateralized debt obligation (CDO) based on a credit index where each tranche references a different segment of the loss distribution of the underlying index; each tranche has a different priority of claims on the principal and interest flows from the collateral pool, and is traditionally portioned into rising levels of seniority]",
                letter: "V"
              },
              {
                description: "Index [family of standardized credit derivative indices, where the underlying reference entities are a defined basket of credit from a particular geographic region (e.g. Asia, North America, Europe), and/or credit rating level (e.g. emerging markets, high yield, investment grade); credit default indices trade in standard maturities, and the reference entities are typically the most liquid; the reference portfolio is reassessed periodically to maintain this]",
                letter: "I"
              },
              {
                description: "Basket (a bespoke, synthetic portfolio of underlying assets whose components have been agreed to for a specific OTC derivative by the parties to the transaction)",
                letter: "B"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Return or payout trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Credit default [a credit default swap (CDS) is a contract in which one party (protection seller) agrees to provide payment to the other party (protection buyer) should a credit event occur against the underlying, which could be a specified debt (the reference obligation), a specific debt issuer (reference entity), a basket of reference entities and/or reference obligations or a credit index (reference index)]",
                letter: "C"
              },
              {
                description: "Total return",
                letter: "T"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Underlying issuer type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Corporate entity [the underlying exposure is a corporate (a private sector entity)]",
                letter: "C"
              },
              {
                description: "Sovereign entity [the underlying exposure is a sovereign, e.g. country; thus, investor’s risk is that a country may not (be able to) pay its debt obligations; supranationals would be included here]",
                letter: "S"
              },
              {
                description: "Local (a municipality or local government authority)",
                letter: "L"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Auction (an independently administered synthetic auction process on a set of defined deliverable obligations that sets a reference final price that can be used to facilitate cash settlement of all covered transactions following a credit event)",
                letter: "A"
              }
            ]
          }
        }
      },
      F: {
        name: "Foreign exchange",
        description: "A foreign exchange swap is a foreign exchange agreement between two parties to exchange a given amount of one currency for another currency for spot delivery or for forward delivery at an agreed rate after a specified period of time.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Spot-forward swap (a transaction that involves both an exchange of two currencies on the spot settlement date at a fixed rate that is agreed upon at the inception of the contract covering the exchange; and a reverse exchange of the same two currencies at a later date and at a fixed rate that is agreed upon at the inception of the contract covering the exchange)",
                letter: "A"
              },
              {
                description: "Forward-forward swap (a transaction that involves both an exchange of two currencies on a specified future date at a fixed rate that is agreed upon at the inception of the contract covering the exchange; and a reverse exchange of the same two currencies at a further future date, at a fixed rate that is agreed upon at the inception of the contract covering the exchange, e.g. a swap between the 3-month forward and 6-month forward dates)",
                letter: "C"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical (delivery of traded currencies on settlement date)",
                letter: "P"
              },
              {
                description: "Cash (on settlement date of the trade, if the net settlement amount is positive, then the currency buyer will pay that amount in the settlement currency to the currency seller; if that amount is negative, the seller will make that payment to the buyer)",
                letter: "C"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Swaps that do not fit into any of the Swaps Groups",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Commercial property (or property derivative) [a derivative where the underlying is commercial property; property derivatives are mostly in the form of swaps where one party pays the return on the index if positive versus the other party paying LIBOR (London interbank offered rate)]",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  H: {
    name: "Non-listed and complex listed options",
    description: "This Category includes OTC or unlisted options and also includes any listed option which is not captured by the listed options Category (see \"Others\"). An option grants the holder either the privilege to purchase or the privilege to sell the assets specified at a predetermined price or formula at or within a time in the future.",
    groups: {
      R: {
        name: "Rates",
        description: "An option where the holder of the option has the right but not the obligation to enter into the underlying contract, or pay or receive payment related to the underlying rate on a specified future date based on a specified future rate and term.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Basis swap (float-float)",
                letter: "A"
              },
              {
                description: "Fixed-floating swap",
                letter: "C"
              },
              {
                description: "Fixed-fixed swap",
                letter: "D"
              },
              {
                description: "Interest rate index",
                letter: "E"
              },
              {
                description: "Inflation rate index",
                letter: "G"
              },
              {
                description: "OIS",
                letter: "H"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Forwards (derivatives involving the exchange of two rates on a defined future date, as agreed by the two parties to the transaction)",
                letter: "R"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price only on the expiration date of the call]",
                letter: "A"
              },
              {
                description: "American-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price at any time during the term of the call option, up to and including the expiration date of the call]",
                letter: "B"
              },
              {
                description: "Bermudan-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the call]",
                letter: "C"
              },
              {
                description: "European-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price only on the expiration date of the put]",
                letter: "D"
              },
              {
                description: "American-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price at any time during the term of the put option, up to and including the expiration date of the put]",
                letter: "E"
              },
              {
                description: "Bermudan-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the put]",
                letter: "F"
              },
              {
                description: "European-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price, only on the contract's expiration date; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "G"
              },
              {
                description: "American-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price at any time during the term of the contract, up to and including the expiration date of the call or put; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "H"
              },
              {
                description: "Bermudan-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the contract; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "I"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla (an option for which all terms are standardized)",
                letter: "V"
              },
              {
                description: "Asian (an option where either the strike price or the settlement price is the average level of an underlying instrument over a predetermined period; the averaging can be either a geometric or arithmetic average)",
                letter: "A"
              },
              {
                description: "Digital (Binary) (an option that has a pre-determined payout if the option is in-the-money and the payoff condition is satisfied; also referred to as a “binary option” or an “all-or-nothing option”)",
                letter: "D"
              },
              {
                description: "Barrier (an option whose final exercise depends upon the path taken by the price of an underlying instrument; for a “knock-out” barrier option, the option is cancelled if the underlying price crosses a predetermined barrier level; for a “knock-in” barrier option, the option becomes available-for-exercise if the underlying price crosses a predetermined barrier level)",
                letter: "B"
              },
              {
                description: "Digital barrier (a digital option embedded with a barrier option; there are different variations of this type of option; as an example, a down-and-out digital call option will pay a fixed payoff, or the underlying, at any time before maturity that the underlying price is equal to or greater than the barrier level; it will pay zero if the underlying price is less than the barrier level)",
                letter: "G"
              },
              {
                description: "Lookback (an option that minimizes the uncertainties related to the timing of market entry; there are two types of lookback options: fixed and floating; the fixed option strike is determined at purchase, and the floating option strike is determined at maturity)",
                letter: "L"
              },
              {
                description: "Other path dependent (an option on a contract whose payoff is directly related to the price pattern the underlying asset follows during the life of the contract)",
                letter: "P"
              },
              {
                description: "Cap (an option in which the payment is triggered when the value of the underlier exceeds a specified level)",
                letter: "C"
              },
              {
                description: "Floor (an option in which the payment is triggered when the value of the underlier falls below a specified level)",
                letter: "F"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "An option where the option buyer has the right to buy or sell specified commodities assets at a fixed price or formula, on or before a specified date.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Energy",
                letter: "J"
              },
              {
                description: "Metals",
                letter: "K"
              },
              {
                description: "Agriculture",
                letter: "A"
              },
              {
                description: "Environmental",
                letter: "N"
              },
              {
                description: "Freight",
                letter: "G"
              },
              {
                description: "Polypropylene products",
                letter: "P"
              },
              {
                description: "Fertilizer",
                letter: "S"
              },
              {
                description: "Paper",
                letter: "T"
              },
              {
                description: "Index – single-commodity (an option where the underlying reference entity is a commodity index)",
                letter: "I"
              },
              {
                description: "Index – multi-commodity (an index containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "H"
              },
              {
                description: "Basket – single-commodity (a custom basket containing constituents from one of the underlying assets identified for this attribute)",
                letter: "B"
              },
              {
                description: "Basket – multi-commodity (a custom basket containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "C"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Forwards",
                letter: "R"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Swaps",
                letter: "W"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price only on the expiration date of the call]",
                letter: "A"
              },
              {
                description: "American-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price at any time during the term of the call option, up to and including the expiration date of the call]",
                letter: "B"
              },
              {
                description: "Bermudan-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the call]",
                letter: "C"
              },
              {
                description: "European-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price only on the expiration date of the put]",
                letter: "D"
              },
              {
                description: "American-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price at any time during the term of the put option, up to and including the expiration date of the put]",
                letter: "E"
              },
              {
                description: "Bermudan-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the put]",
                letter: "F"
              },
              {
                description: "European-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price, only on the contract's expiration date; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "G"
              },
              {
                description: "American-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price at any time during the term of the contract, up to and including the expiration date of the call or put; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "H"
              },
              {
                description: "Bermudan-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the contract; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "I"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla",
                letter: "V"
              },
              {
                description: "Asian",
                letter: "A"
              },
              {
                description: "Digital (Binary)",
                letter: "D"
              },
              {
                description: "Barrier",
                letter: "B"
              },
              {
                description: "Digital barrier",
                letter: "G"
              },
              {
                description: "Lookback",
                letter: "L"
              },
              {
                description: "Other path dependent",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      E: {
        name: "Equity",
        description: "An option where the underlying asset is an equity-linked instrument (i.e. shares, depository receipts, ETFs, indices, baskets).",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single stock (an option on a contract which gives the holder the right to buy, and to sell, single-named equity)",
                letter: "S"
              },
              {
                description: "Index (an option on a contract which gives the holder the right to buy, and to sell, specified equity indices)",
                letter: "I"
              },
              {
                description: "Basket (an option on a contract that may be exercised based on the weighted average performance of several underlying equities instruments)",
                letter: "B"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Forwards",
                letter: "R"
              },
              {
                description: "Futures",
                letter: "F"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price only on the expiration date of the call]",
                letter: "A"
              },
              {
                description: "American-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price at any time during the term of the call option, up to and including the expiration date of the call]",
                letter: "B"
              },
              {
                description: "Bermudan-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the call]",
                letter: "C"
              },
              {
                description: "European-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price only on the expiration date of the put]",
                letter: "D"
              },
              {
                description: "American-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price at any time during the term of the put option, up to and including the expiration date of the put]",
                letter: "E"
              },
              {
                description: "Bermudan-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the put]",
                letter: "F"
              },
              {
                description: "European-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price, only on the contract's expiration date; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "G"
              },
              {
                description: "American-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price at any time during the term of the contract, up to and including the expiration date of the call or put; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "H"
              },
              {
                description: "Bermudan-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the contract; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "I"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla",
                letter: "V"
              },
              {
                description: "Asian",
                letter: "A"
              },
              {
                description: "Digital (Binary)",
                letter: "D"
              },
              {
                description: "Barrier",
                letter: "B"
              },
              {
                description: "Digital barrier",
                letter: "G"
              },
              {
                description: "Lookback",
                letter: "L"
              },
              {
                description: "Other path dependent",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      C: {
        name: "Credit",
        description: "An option to buy or sell a credit product which is a contract in which one party (protection seller) agrees to provide payment to the other party (protection buyer) should a credit event occur against the underlying, which could be a specified debt (the reference obligation), a specific debt issuer (reference entity), a basket of reference entities and/or reference obligations, or a credit index (reference index).",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CDS on a single name (a CDS where the underlying risk is a single reference entity or single reference obligation)",
                letter: "U"
              },
              {
                description: "CDS on an index tranche (a synthetic CDO based on a CDS index where each tranche references a different segment of the loss distribution of the underlying CDS index; each tranche has a different priority of claims on the principal and interest flows from the collateral pool, and is traditionally portioned into rising levels of seniority)",
                letter: "V"
              },
              {
                description: "CDS on an index [family of standardized credit derivative indices, where the underlying reference entities are a defined basket of credit from a particular geographic region (e.g. Asia, North America, Europe), and/or credit rating level (e.g. emerging markets, high yield, investment grade); credit default indices trade in standard maturities, and the reference entities are typically the most liquid; the reference portfolio is reassessed periodically to maintain this]",
                letter: "I"
              },
              {
                description: "Swaps (a swap other than a CDS)",
                letter: "W"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price only on the expiration date of the call]",
                letter: "A"
              },
              {
                description: "American-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price at any time during the term of the call option, up to and including the expiration date of the call]",
                letter: "B"
              },
              {
                description: "Bermudan-Call [an option on a contract which allows its holder (buyer) to exercise the right to buy specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the call]",
                letter: "C"
              },
              {
                description: "European-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price only on the expiration date of the put]",
                letter: "D"
              },
              {
                description: "American-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price at any time during the term of the put option, up to and including the expiration date of the put]",
                letter: "E"
              },
              {
                description: "Bermudan-Put [an option on a contract which allows its holder (buyer) to exercise the right to sell specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the put]",
                letter: "F"
              },
              {
                description: "European-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price, only on the contract's expiration date; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "G"
              },
              {
                description: "American-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price at any time during the term of the contract, up to and including the expiration date of the call or put; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "H"
              },
              {
                description: "Bermudan-Chooser [an option on a contract which allows its holder (buyer) to exercise the right to buy (call) or sell (put) specified assets (interest rates product) at a fixed price on a number of specific dates within the exercise period of the contract; the buyer does not have to decide whether the contract will be a put or a call until an agreed future date, prior to expiration]",
                letter: "I"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla",
                letter: "V"
              },
              {
                description: "Asian",
                letter: "A"
              },
              {
                description: "Digital (Binary)",
                letter: "D"
              },
              {
                description: "Barrier",
                letter: "B"
              },
              {
                description: "Digital barrier",
                letter: "G"
              },
              {
                description: "Lookback",
                letter: "L"
              },
              {
                description: "Other path dependent",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      F: {
        name: "Foreign exchange",
        description: "An option to buy or sell a foreign exchange agreement between two parties to exchange a given amount of one currency for another currency for spot delivery or for forward delivery at an agreed rate after a specified period of time.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Forwards – Single Currency Pair",
                letter: "R"
              },
              {
                description: "Futures – Single Currency Pair",
                letter: "F"
              },
              {
                description: "Spot – Single Currency Pair (an option on a foreign exchange transaction in which two parties agree to buy one currency against selling another currency at an agreed price for settlement on the spot date)",
                letter: "T"
              },
              {
                description: "Volatility – Single Currency Pair (please refer to 6.8.4)",
                letter: "V"
              },
              {
                description: "Forwards – Currency Index",
                letter: "B"
              },
              {
                description: "Futures – Currency Index",
                letter: "C"
              },
              {
                description: "Spot – Currency Index",
                letter: "D"
              },
              {
                description: "Volatility – Currency Index",
                letter: "E"
              },
              {
                description: "Forwards – Custom Basket of Currencies",
                letter: "Q"
              },
              {
                description: "Futures – Custom Basket of Currencies",
                letter: "U"
              },
              {
                description: "Spot – Custom Basket of Currencies",
                letter: "W"
              },
              {
                description: "Volatility – Custom Basket of Currencies",
                letter: "Y"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European (an option which allows its holder to exercise the right to buy/sell the specified call/put currency at a specified price only on the expiration date",
                letter: "J"
              },
              {
                description: "American (an option which allows its holder to exercise the right to buy/sell the specified call/put currency at a specified price at any time during the term of the option, up to and including the expiration date)",
                letter: "K"
              },
              {
                description: "Bermudan (an option which allows its holder to exercise the right to buy/sell the specified call/put currency at a specified price on a number of specific dates within the exercise period of the option)",
                letter: "L"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla",
                letter: "V"
              },
              {
                description: "Asian",
                letter: "A"
              },
              {
                description: "Digital (Binary)",
                letter: "D"
              },
              {
                description: "Barrier",
                letter: "B"
              },
              {
                description: "Digital barrier",
                letter: "G"
              },
              {
                description: "Lookback",
                letter: "L"
              },
              {
                description: "Other path dependent",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at settlement (determined at the time of settlement)",
                letter: "E"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Options that do not fit into any of the above Groups.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Commercial property (or property derivative)",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Option style and type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "European-Call",
                letter: "A"
              },
              {
                description: "American-Call",
                letter: "B"
              },
              {
                description: "Bermudan-Call",
                letter: "C"
              },
              {
                description: "European-Put",
                letter: "D"
              },
              {
                description: "American-Put",
                letter: "E"
              },
              {
                description: "Bermudan-Put",
                letter: "F"
              },
              {
                description: "European-Chooser",
                letter: "G"
              },
              {
                description: "American-Chooser",
                letter: "H"
              },
              {
                description: "Bermudan-Chooser",
                letter: "I"
              },
              {
                description: "European (an option which allows its holder to exercise the right to buy/sell the specified call/put asset at a specified price only on the expiration date)",
                letter: "J"
              },
              {
                description: "American (an option which allows its holder to exercise the right to buy/sell the specified call/put asset at a specified price at any time during the term of the option, up to and including the expiration date)",
                letter: "K"
              },
              {
                description: "Bermudan (an option which allows its holder to exercise the right to buy/sell the specified call/put asset at a specified price on a number of specific dates within the exercise period of the option)",
                letter: "L"
              }
            ]
          },
          attribute_3: {
            description: "Valuation method or trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Vanilla",
                letter: "V"
              },
              {
                description: "Asian",
                letter: "A"
              },
              {
                description: "Digital (Binary)",
                letter: "D"
              },
              {
                description: "Barrier",
                letter: "B"
              },
              {
                description: "Digital barrier",
                letter: "G"
              },
              {
                description: "Lookback",
                letter: "L"
              },
              {
                description: "Other path dependent",
                letter: "P"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash",
                letter: "C"
              },
              {
                description: "Physical",
                letter: "P"
              },
              {
                description: "Elect at exercise",
                letter: "E"
              },
              {
                description: "Non-deliverable",
                letter: "N"
              },
              {
                description: "Auction",
                letter: "A"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  I: {
    name: "Spot",
    description: "Contracts conducted on the spot market which are bought and sold for cash with immediate delivery based on market convention for the asset.",
    groups: {
      F: {
        name: "Foreign exchange",
        description: "Spot contracts to deliver the agreed foreign currency at settlement.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Physical",
                letter: "P"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "Spot contracts to physically deliver the commodity asset at settlement.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (indicates the type of underlying assets that the spot contract buyer receives, and that the seller delivers)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Agriculture",
                letter: "A"
              },
              {
                description: "Energy",
                letter: "J"
              },
              {
                description: "Metals",
                letter: "K"
              },
              {
                description: "Environmental (e.g. carbon credits and similar products)",
                letter: "N"
              },
              {
                description: "Polypropylene products",
                letter: "P"
              },
              {
                description: "Fertilizer",
                letter: "S"
              },
              {
                description: "Paper",
                letter: "T"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  J: {
    name: "Forwards",
    description: "Contracts, which are not exchange traded or listed, entered between two parties to buy or sell the underlying asset at a specified future date at the price specified at the outset of the contract.",
    groups: {
      E: {
        name: "Equity",
        description: "Forward contracts to buy or sell the underlying equity stock, equity index or basket of equity stock.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (indicates the type of underlying equity product that the forward contract buyer will buy, and that the seller will sell, at the specified future date and price)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single stock (single equity stock)",
                letter: "S"
              },
              {
                description: "Index",
                letter: "I"
              },
              {
                description: "Basket (a defined basket of instruments)",
                letter: "B"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Futures",
                letter: "F"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Return or payout trigger (method used to determine contract value if different from standard forward price of underlying instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Spread-bet [the payout is determined by the movement in the reference price of the underlying instrument to its price at expiry (or the price when the holder wishes to close out) multiplied by an agreed amount per point movement]",
                letter: "S"
              },
              {
                description: "Forward price of underlying instrument (the agreed-upon price for the time of delivery)",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      F: {
        name: "Foreign exchange",
        description: "Forward contracts to buy or sell the foreign currency.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Spot – Single Currency Pair",
                letter: "T"
              },
              {
                description: "Spot – Currency Index",
                letter: "U"
              },
              {
                description: "Spot – Custom Basket of Currencies",
                letter: "V"
              },
              {
                description: "Forward – Single Currency Pair",
                letter: "R"
              },
              {
                description: "Forward – Currency Index",
                letter: "S"
              },
              {
                description: "Forward – Custom Basket of Currencies",
                letter: "W"
              },
              {
                description: "Options – Single Currency Pair",
                letter: "O"
              },
              {
                description: "Options – Currency Index",
                letter: "J"
              },
              {
                description: "Options – Custom Basket of Currencies",
                letter: "K"
              },
              {
                description: "Futures – Single Currency Pair",
                letter: "F"
              },
              {
                description: "Futures – Currency Index",
                letter: "L"
              },
              {
                description: "Futures – Custom Basket of Currencies",
                letter: "N"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Return or payout trigger",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Spread-bet",
                letter: "S"
              },
              {
                description: "Forward price of underlying instrument",
                letter: "F"
              },
              {
                description: "Rolling spot (an indefinitely renewed position in which no currency is actually delivered until a party closes out its position)",
                letter: "R"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      C: {
        name: "Credit",
        description: "Forward contracts to buy or sell the credit product.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Single name",
                letter: "A"
              },
              {
                description: "Index",
                letter: "I"
              },
              {
                description: "Basket",
                letter: "B"
              },
              {
                description: "CDS on a single name",
                letter: "C"
              },
              {
                description: "CDS on an index",
                letter: "D"
              },
              {
                description: "CDS on a basket (where the underlying risk is multiple reference entities or multiple reference obligations)",
                letter: "G"
              },
              {
                description: "Options",
                letter: "O"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Return or payout trigger (method used to determine contract value if different from standard forward price of underlying instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Spread-bet [the payout is determined by the movement in the reference price of the underlying instrument to its price at expiry (or the price when the holder wishes to close out) multiplied by an agreed amount per point movement]",
                letter: "S"
              },
              {
                description: "Forward price of underlying instrument (the agreed-upon price for the time of delivery)",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      R: {
        name: "Rates",
        description: "Forward contracts to buy or sell the financial rates product.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Interest rate index",
                letter: "I"
              },
              {
                description: "Options",
                letter: "O"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Return or payout trigger (method used to determine contract value if different from standard forward price of underlying instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Spread-bet [the payout is determined by the movement in the reference price of the underlying instrument to its price at expiry (or the price when the holder wishes to close out) multiplied by an agreed amount per point movement]",
                letter: "S"
              },
              {
                description: "Forward price of underlying instrument (the agreed-upon price for the time of delivery)",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "Forward contracts to buy or sell the commodity asset.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Agriculture",
                letter: "A"
              },
              {
                description: "Basket – single-commodity (a custom basket containing constituents from one of the underlying assets identified for this attribute)",
                letter: "B"
              },
              {
                description: "Basket – multi-commodity (a custom basket containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "C"
              },
              {
                description: "Freight",
                letter: "G"
              },
              {
                description: "Index – single-commodity (an option where the underlying reference entity is a commodity index)",
                letter: "I"
              },
              {
                description: "Index – multi-commodity (an index containing constituents from two or more of the underlying assets identified for this attribute)",
                letter: "H"
              },
              {
                description: "Energy",
                letter: "J"
              },
              {
                description: "Metals",
                letter: "K"
              },
              {
                description: "Environmental",
                letter: "N"
              },
              {
                description: "Polypropylene products",
                letter: "P"
              },
              {
                description: "Fertilizer",
                letter: "S"
              },
              {
                description: "Paper",
                letter: "T"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Return or payout trigger (method used to determine contract value if different from standard forward price of underlying instrument)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "CFD",
                letter: "C"
              },
              {
                description: "Spread-bet [the payout is determined by the movement in the reference price of the underlying instrument to its price at expiry (or the price when the holder wishes to close out) multiplied by an agreed amount per point movement]",
                letter: "S"
              },
              {
                description: "Forward price of underlying instrument (the agreed-upon price for the time of delivery)",
                letter: "F"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  K: {
    name: "Strategies",
    description: "This subclause defines a classification of derivative strategies. Strategies are the simultaneous trading of two or more derivative instruments.",
    groups: {
      R: {
        name: "Rates",
        description: "Interest rate derivative strategies are the simultaneous trading of two or more rate contracts in which two counterparties agree to exchange interest rate cash flows on defined dates during an agreed period, based on a specified notional amount, from a fixed rate to a floating rate, floating to fixed, or floating to floating.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "Commodity derivative strategies are the simultaneous trading of two or more commodity contracts where the value of the contract is derived from an underlying commodity or commodity index. Commodity derivatives can be physically or cash settled. Primary underliers include metals, agricultural goods and energy.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      E: {
        name: "Equity",
        description: "Equity derivative strategies are the simultaneous trading of two or more equity contracts where payments are linked to the change in value of an underlying equity (e.g. shares, basket of equities or index). The equity return payer pays to the equity return receiver any increase in the value of the underlying plus any dividends received. The equity return receiver pays the equity return payer any decrease in the value of the underlying plus funding cost.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      C: {
        name: "Credit",
        description: "Credit derivative strategies are the simultaneous trading of two or more credit default contracts in which one party (protection seller) agrees to provide payment to the other party (protection buyer) should a credit event occur against the underlying, which could be a specified debt (the reference obligation), a specific debt issuer (reference entity), a basket of reference entities and/or reference obligations, or a credit index (reference index).",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      F: {
        name: "Foreign exchange",
        description: "Foreign exchange derivative strategies are the simultaneous trading of two or more foreign exchange agreements between two parties to exchange a given amount of one currency for another currency for spot delivery or for forward delivery at an agreed rate after a specified period of time.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      Y: {
        name: "Mixed assets",
        description: "Mixed asset derivative strategies are the simultaneous trading of two or more contracts of different asset types between two parties.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Strategies that do not fit into any of the above Groups.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  L: {
    name: "Financing",
    description: "Financing is a collateralized loan agreement entered into between two parties where one party, the lender, lends (temporarily) the underlying asset which is secured with cash or other acceptable collateral (securities or other assets) provided by the borrower. Depending on the exact type of financing transaction, a simultaneous agreement to reverse the agreement may be entered into at the same time with an agreed-upon future date for the reverse transaction to take place.",
    groups: {
      L: {
        name: "Loan-lease",
        description: "One party lends a commodity to a counterparty, in return for a fixed price or a premium (aka lease rate). At the end of the contract, the lender expects to receive the commodity back, and their return is linked to fluctuations in the market price of the commodity, or the premium, respectively.",
        attributes: {
          attribute_1: {
            description: "Underlying assets",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Agriculture",
                letter: "A"
              },
              {
                description: "Baskets",
                letter: "B"
              },
              {
                description: "Energy",
                letter: "J"
              },
              {
                description: "Metals",
                letter: "K"
              },
              {
                description: "Environmental",
                letter: "N"
              },
              {
                description: "Polypropylene products",
                letter: "P"
              },
              {
                description: "Fertilizer",
                letter: "S"
              },
              {
                description: "Paper",
                letter: "T"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash (the contract will settle as cash on the performance of the contract at maturity)",
                letter: "C"
              },
              {
                description: "Physical (the contract will settle with the delivery of shares on the performance of the contract at maturity)",
                letter: "P"
              }
            ]
          }
        }
      },
      R: {
        name: "Repurchase agreements",
        description: "A simultaneous sale and repurchase agreement entered into by two parties where one party agrees to sell securities or cash to the other party in exchange for collateral. The repurchase terms are generally for the repurchase of the same or equivalent securities at a specified price on an agreed future date. During the term of the agreement, the ownership rights of the securities are transferred.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (underlying collateral used to secure the repurchase agreement)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "General collateral [the repurchase agreement is secured by a range of acceptable high-quality and liquid assets, or a general collateral basket of acceptable general collateral securities as prescribed by a repo trading system or the central counterparty (CCP); the securities may be substituted with other acceptable securities, as determined by the seller]",
                letter: "G"
              },
              {
                description: "Specific security collateral (the repurchase agreement is secured by a specific agreed-upon security, which may not be substituted)",
                letter: "S"
              },
              {
                description: "Cash collateral",
                letter: "C"
              }
            ]
          },
          attribute_2: {
            description: "Termination (termination terms of the agreement)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Flexible (the agreement has flexible term duration of up to 120 days)",
                letter: "F"
              },
              {
                description: "Overnight (the term of the agreement is one day)",
                letter: "N"
              },
              {
                description: "Open (the agreement has no fixed termination date)",
                letter: "O"
              },
              {
                description: "Term (the set number of days specified at the outset of the agreement)",
                letter: "T"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery (delivery method for the collateral at the outset of the agreement)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Delivery versus payment (the borrower delivers the collateral to the lender against payment of funds within a securities settlement system)",
                letter: "D"
              },
              {
                description: "Hold-in-custody (the borrower holds the collateral in a segregated customer account, in custody, for the lender)",
                letter: "H"
              },
              {
                description: "Tri-party (the borrower delivers the collateral to the lender's account at the lender's clearing bank or custodian)",
                letter: "T"
              }
            ]
          }
        }
      },
      S: {
        name: "Securities lending",
        description: "An agreement entered into by two parties where one party (lender) agrees to lend cash or securities to the other party (borrower) in exchange for collateral plus a lending fee. The lender retains the rights (i.e. title, dividend/interest payments and corporate actions that may arise during the term of the loan) to the securities on loan to the borrower.",
        attributes: {
          attribute_1: {
            description: "Underlying assets (collateral that may be used, or substituted, to secure the lending agreement)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Cash collateral",
                letter: "C"
              },
              {
                description: "Government bonds",
                letter: "G"
              },
              {
                description: "Corporate bonds",
                letter: "P"
              },
              {
                description: "Convertible bonds",
                letter: "T"
              },
              {
                description: "Equity",
                letter: "E"
              },
              {
                description: "Letter of credit",
                letter: "L"
              },
              {
                description: "Certificate of deposit",
                letter: "D"
              },
              {
                description: "Warrants",
                letter: "W"
              },
              {
                description: "Money market instruments",
                letter: "K"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Termination",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Overnight",
                letter: "N"
              },
              {
                description: "Open",
                letter: "O"
              },
              {
                description: "Term",
                letter: "T"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Delivery",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Delivery versus payment",
                letter: "D"
              },
              {
                description: "Free of payment (the borrower delivers the collateral with no corresponding payment of funds)",
                letter: "F"
              },
              {
                description: "Hold-in-custody",
                letter: "H"
              },
              {
                description: "Tri-party",
                letter: "T"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  T: {
    name: "Referential instruments",
    description: "Indicators that are used as a reference for other financial instruments.",
    groups: {
      C: {
        name: "Currencies",
        description: "An accepted form of money, including coins and paper notes, which is generally issued by a government and circulated within an economy.",
        attributes: {
          attribute_1: {
            description: "Type",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "National currency (the currency or legal tender issued by a nation's central bank or monetary authority; the national currency of a nation is usually the predominant currency used for most financial transactions in that country according to ISO 4217)",
                letter: "N"
              },
              {
                description: "Legacy currency (national currency that ceased to be a legal tender)",
                letter: "L"
              },
              {
                description: "Bullion coins (coins struck from precious metals and kept as a store of value or an investment, rather than used in day-to-day commerce)",
                letter: "C"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      T: {
        name: "Commodities",
        description: "A basic good used in commerce that is interchangeable with other commodities of the same type. Commodities are most often used as inputs in the production of other goods or services.",
        attributes: {
          attribute_1: {
            description: "Type of commodity",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Extraction resources (metals, precious metals, coal, oil, gas)",
                letter: "E"
              },
              {
                description: "Agriculture (commodities which include forestry, fishing, livestock, grain, dairy, corn, cocoa, soybeans, sugar, coffee)",
                letter: "A"
              },
              {
                description: "Industrial products (construction, manufacturing)",
                letter: "I"
              },
              {
                description: "Services (transportation, communication, trade)",
                letter: "S"
              },
              {
                description: "Environmental (includes carbon-related, emission reduction, weather)",
                letter: "N"
              },
              {
                description: "Polypropylene products (includes plastics)",
                letter: "P"
              },
              {
                description: "Generated resources (includes electricity, renewable energy, or any power/energy delivered through a utility network or provider)",
                letter: "H"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      R: {
        name: "Interest rates",
        description: "Rate at which interest is paid by a borrower for the use of money that they borrow from a lender.",
        attributes: {
          attribute_1: {
            description: "Type of interest rates",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Nominal (an interest rate before taking inflation into account)",
                letter: "N"
              },
              {
                description: "Variable (an interest rate that fluctuates over time)",
                letter: "V"
              },
              {
                description: "Fixed (an interest rate that remains fixed for the entire term)",
                letter: "F"
              },
              {
                description: "Real (an interest rate that has been adjusted to remove the effects of inflation)",
                letter: "R"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Frequency of calculation",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Daily",
                letter: "D"
              },
              {
                description: "Weekly",
                letter: "W"
              },
              {
                description: "Monthly",
                letter: "N"
              },
              {
                description: "Quarterly",
                letter: "Q"
              },
              {
                description: "Semi-annually",
                letter: "S"
              },
              {
                description: "Annually",
                letter: "A"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      I: {
        name: "Indices",
        description: "An index provides a representation of the value of its constituents. Indices often serve as barometers for a given market or industry and benchmarks against which financial or economic performance is measured.",
        attributes: {
          attribute_1: {
            description: "Asset classes",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Equities",
                letter: "E"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "CIVs",
                letter: "F"
              },
              {
                description: "Real estate",
                letter: "R"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies",
                letter: "C"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Weighting types (type of weighting of the financial instruments in the index)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Price weighted (market index where each constituent makes up a fraction of the index that is proportional to its price; for a stock market index, this implies that stocks are included in proportions based on their quoted prices)",
                letter: "P"
              },
              {
                description: "Capitalization weighted (index whose components are weighted according to the total market value of their outstanding shares)",
                letter: "C"
              },
              {
                description: "Equal weighted (an equal-weighted index uses a type of weighting that gives the same weight or importance to each stock in a portfolio or index fund)",
                letter: "E"
              },
              {
                description: "Modified market capitalization weighted (a modified market cap weighted index is a hybrid between equal weighting and capitalization weighting; it is similar to a general market cap with one main difference: the largest stocks are capped to a percentage of the weight of the total stock index and the excess weight will be redistributed equally among the stocks under that cap)",
                letter: "F"
              },
              {
                description: "Other weighting (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_3: {
            description: "Index return types",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Price return (the price return is the rate of return on an investment portfolio, where the return measure takes into account only the capital appreciation of the portfolio, while the income generated by the assets in the portfolio, in the form of interest and dividends, is ignored)",
                letter: "P"
              },
              {
                description: "Net total return (a net total return is the rate of return on an investment portfolio, where the return measure takes into account all net cash distributions made to the components of an index. Net cash distributions are cash distributions after any withholding tax)",
                letter: "N"
              },
              {
                description: "Gross total return (a gross total return is the rate of return on an investment portfolio, where the return measure takes into account all gross cash distributions made to the components of an index)",
                letter: "G"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      B: {
        name: "Baskets",
        description: "Group of securities that have been put together for a specific investment purpose.",
        attributes: {
          attribute_1: {
            description: "Composition",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Equities",
                letter: "E"
              },
              {
                description: "Debt instruments",
                letter: "D"
              },
              {
                description: "CIVs",
                letter: "F"
              },
              {
                description: "Indices",
                letter: "I"
              },
              {
                description: "Commodities",
                letter: "T"
              },
              {
                description: "Currencies",
                letter: "C"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      D: {
        name: "Stock dividends",
        description: "Relate to the dividend payments of a specific underlying equity stock.",
        attributes: {
          attribute_1: {
            description: "Type of equity",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Common/ordinary shares",
                letter: "S"
              },
              {
                description: "Preferred/preference shares",
                letter: "P"
              },
              {
                description: "Common/ordinary convertible shares",
                letter: "C"
              },
              {
                description: "Preferred/preference convertible shares",
                letter: "F"
              },
              {
                description: "Limited partnership units",
                letter: "L"
              },
              {
                description: "CIVs",
                letter: "K"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      M: {
        name: "Others (miscellaneous)",
        description: "Referential instruments that do not fit into any of the Groups described in above groups.",
        attributes: {
          attribute_1: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  },
  M: {
    name: "Others (miscellaneous)",
    description: "Financial instruments that do not fit the above categories as defined.",
    groups: {
      C: {
        name: "Combined instruments",
        description: "Packages of different financial instruments issued and/or traded as one single unit (also referred to as staple security). They can be separated during their life cycle and be traded individually. In that case, CFI codes classifying each of the financial instruments involved individually shall be assigned.",
        attributes: {
          attribute_1: {
            description: "Components",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Combination of shares (with different characteristics)",
                letter: "S"
              },
              {
                description: "Combination of bonds (with different characteristics)",
                letter: "B"
              },
              {
                description: "Share and bond",
                letter: "H"
              },
              {
                description: "Share and warrant",
                letter: "A"
              },
              {
                description: "Warrant and warrant (or multiple)",
                letter: "W"
              },
              {
                description: "Fund unit and other components",
                letter: "U"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Ownership/transfer/sales restrictions (the ownership or transfer of the security is subject to special conditions including country-specific restrictions)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Restrictions",
                letter: "T"
              },
              {
                description: "Free (unrestricted)",
                letter: "U"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Form (negotiability, transmission)",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Bearer (the owner is not registered in the books of the issuer or of the registrar)",
                letter: "B"
              },
              {
                description: "Registered (securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner)",
                letter: "R"
              },
              {
                description: "Bearer/registered (securities are issued in both bearer and registered form but with the same identification number)",
                letter: "N"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          }
        }
      },
      M: {
        name: "Other assets (miscellaneous)",
        description: "The following are other assets that do not fit the Groupings as defined.",
        attributes: {
          attribute_1: {
            description: "Further grouping",
            possible_values: [
              {
                description: "Not Applicable/Undefined",
                letter: "X"
              },
              {
                description: "Real estate deeds (represent ownership of property)",
                letter: "R"
              },
              {
                description: "Insurance policies",
                letter: "I"
              },
              {
                description: "Escrow receipts (bank guarantee that an option writer has the underlying security on deposit and that the underlying security is readily available for delivery if the option is exercised)",
                letter: "E"
              },
              {
                description: "Trade finance instruments (assets based on the securitization of the movement of goods and services)",
                letter: "T"
              },
              {
                description: "Carbon credit (certificate or permit representing the right to emit carbon dioxide)",
                letter: "N"
              },
              {
                description: "Precious metal receipts",
                letter: "P"
              },
              {
                description: "Other OTC derivative products",
                letter: "S"
              },
              {
                description: "Others (miscellaneous)",
                letter: "M"
              }
            ]
          },
          attribute_2: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_3: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          },
          attribute_4: {
            description: "Not applicable/undefined",
            possible_values: [
              {
                description: "Not applicable/undefined",
                letter: "X"
              }
            ]
          }
        }
      },
      X: {
        name: "Not Applicable/Undefined",
        description: "",
        attributes: {
          attribute_1: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_2: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_3: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          },
          attribute_4: {
            description: "No attributes to select.",
            possible_values: [
              {
                letter: "X",
                description: "Not Applicable/Undefined"
              }
            ]
          }
        }
      }
    }
  }
}