import { IconButton, MenuItem, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { ICompanyConnection } from "../../core/models/company-connection";
import { CompanyFundRelationTypeOptions, CompanyFundRelationTypes } from "../../core/constants/company-relation-types.const";

interface ICompanyFormFieldProps {
    connection: { id: number, companyToken: string, relationType: CompanyFundRelationTypes },
    companies: ICompanyConnection[];
    onDeleteConnection: (index: number) => void;
    onCompanyParticipantChanged: (index: number, companyToken: string) => void;
    onCompanyRelationTypeChanged: (index: number, relationType: number) => void;
}

const CompanyFormField = ({
    companies,
    connection,
    onDeleteConnection,
    onCompanyParticipantChanged,
    onCompanyRelationTypeChanged
}: ICompanyFormFieldProps) => {
    const handleChangeCompanyRelationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedRelationType = + event.target.value;
        onCompanyRelationTypeChanged(connection.id, selectedRelationType);
    }

    const handleChangeParticipantCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedCompany = event.target.value;
        onCompanyParticipantChanged(connection.id, selectedCompany);
    }

    return (
        <div className="field-group">
            <TextField
                key={`company-${connection.id}`}
                select
                label="Participant company"
                className="field"
                onChange={handleChangeParticipantCompany}
                value={connection.companyToken}
            >
                {
                    companies
                        .map(({ token, name }: ICompanyConnection) => (
                            <MenuItem key={token} value={token}>
                                {name}
                            </MenuItem>
                        ))
                }
            </TextField>

            <TextField
                select
                key={`relation-${connection.id}`}
                label="Relation Type"
                className="field"
                onChange={handleChangeCompanyRelationType}
                value={connection.relationType}
            >
                {
                    CompanyFundRelationTypeOptions.map((option: { label: string, type: CompanyFundRelationTypes }) =>
                        <MenuItem key={option.type} value={option.type}>
                            {option.label}
                        </MenuItem>
                    )
                }
            </TextField>

            <IconButton aria-label="delete" onClick={() => onDeleteConnection(connection.id)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}

export { CompanyFormField };