import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import makeStyles from "@mui/styles/makeStyles";
import { CfiBlockItemTitle } from "../cfi-block-item-title";
import { commonConstants } from "../core/constants/common.const";
import { CfiItem } from "../core/models/cfi-item";

import "./cfi-attribute-list.css";

const useListStyles = makeStyles({
    root: {
        "& .Mui-selected": {
            backgroundColor: '#D0D7FF',
            color: '#1636FF'
        },
        "& .Mui-selected:hover": {
            backgroundColor: '#D0D7FF'
        },
        "& .attribute-name": {
            fontSize: '12px',
            fontWeight: 400,
            color: '#00062B',
            lineHeight: '23px',
            letterSpacing: '0.5px',
            marginLeft: '12px',
        },
        "& .attribute-name:first-letter": {
            fontSize: '16px',
            fontWeight: 600,
        }
    }
});

export const CfiAttributeList = (props: any) => {
    const {
        selectionChanged,
        selectedLetterCodes,
        list,
        title,
    } = props;

    const listStyles = useListStyles();

    const itemSelectionChanged = (
        itemLetterCode: string,
        checked: boolean) => {

        if (checked) {
            selectedLetterCodes.push(itemLetterCode);

            const isNotUdefinedLetterCodeChecked =
                itemLetterCode !== commonConstants.undefinedCfiLetterCode;

            if (isNotUdefinedLetterCodeChecked) {
                const undefinedItemIndexToRemove =
                    selectedLetterCodes.indexOf(commonConstants.undefinedCfiLetterCode);

                if (undefinedItemIndexToRemove !== -1) {
                    selectedLetterCodes.splice(undefinedItemIndexToRemove, 1);
                }
            }
            else {
                selectedLetterCodes.length = 0;
                selectedLetterCodes.push(commonConstants.undefinedCfiLetterCode);
            }
        }
        else {
            const itemIndexToRemove =
                selectedLetterCodes.indexOf(itemLetterCode);

            selectedLetterCodes.splice(itemIndexToRemove, 1);

            if (!selectedLetterCodes.length)  {
                selectedLetterCodes.push(commonConstants.undefinedCfiLetterCode);
            }
        }

        selectionChanged(selectedLetterCodes);
    }

    return (
        <div className="block-item-list">
            <CfiBlockItemTitle title={title}
                subtitle={list?.longDescription}>
            </CfiBlockItemTitle>

            <Card sx={{ height: '80%' }}>
                <CardContent sx={{ height: '100%', padding: '0' }}>
                    <List sx={{ height: '100%', overflow: 'auto' }} className={listStyles.root}>
                        {
                            list.values
                                .map((item: CfiItem, index: number) => {
                                    const checked = selectedLetterCodes.indexOf(item.letterCode) !== -1;
                                    const isUndefinedCfiLetterCode = item.letterCode === commonConstants.undefinedCfiLetterCode;
                                    const isDisabled = (list.values.length === 1) || (isUndefinedCfiLetterCode && !checked && !!selectedLetterCodes.length);

                                    return (
                                        <ListItemButton
                                            key={index}
                                            disabled={isDisabled}
                                            selected={selectedLetterCodes.indexOf(item.letterCode) !== -1}
                                            sx={{ fontSize: '12px', paddingLeft: '5px' }}
                                            onClick={() => itemSelectionChanged(item.letterCode, !checked)}
                                        >
                                            <ListItemIcon sx={{ width: "30px", minWidth: "30px" }}>
                                                <Checkbox
                                                    size="small"
                                                    checked={checked}
                                                    sx={{ '&.Mui-checked': { color: '#1636FF' } }}
                                                />
                                            </ListItemIcon>

                                            <div className="attribute-name">
                                                {item.name}
                                            </div>
                                        </ListItemButton>)
                                })
                        }
                    </List>
                </CardContent>
            </Card>
        </div>
    )
}