import { Box, Chip, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import { useState } from "react";
import { DailyHoldingsCheckResult } from "../../core/enums/daily-holdings-check-result.enum";
import { IncidentStatusType } from "../../core/enums/incident-status-type.enum";
import { GuidelineCheckHistoryItem } from "../../core/models/guideline-check-history-item";
import { getMonitoringStatus } from "../../dashboard";
import { GuidelineIncidentStatus } from "../guideline-incident/guideline-incident-status";

import "./guideline-check-history.css";

interface IGuidelineCheckHistoryProps {
    checkHistoryItems: GuidelineCheckHistoryItem[];
    selectItem: (dailyHoldingsToken: string) => void;
}

const formatDate = (date: Date) => (format(date, "do LLL yyyy, H:mm"));

const GuidelineCheckHistory = ({
    checkHistoryItems,
    selectItem,
}: IGuidelineCheckHistoryProps) => {
    const [selectedIndex, setSelectedIndex] = useState(checkHistoryItems[0].dailyHoldingsToken);

    const onClickHandler = (index: string) => {
        setSelectedIndex(index);
        selectItem(index);
    };

    const getIncidentStausLabel = (status: IncidentStatusType) => {
        switch (status) {
            case IncidentStatusType.ActiveBreach:
                return <Chip sx={{width: '100%'}} color='error' label='ACTIVE BREACH' />
            case IncidentStatusType.Escalation:
                return <Chip sx={{width: '100%'}} color='primary' label='ESCALATION' />
            case IncidentStatusType.PassiveBreach:
                return <Chip sx={{width: '100%'}} color='primary' label='PASSIVE BREACH' />
            case IncidentStatusType.Open:
                return <Chip sx={{width: '100%'}} color='default' label='OPEN' />
            case IncidentStatusType.Resolved:
                return <Chip sx={{width: '100%'}} color='success' label='RESOLVED' />
        }
    }

    return (
        <Grid className="history-container">
            <p className="history-title">
                CHECK HISTORY
            </p>

            <Box sx={{ width: '100%', maxWidth: 360 }}>
                <List component="nav">
                    {
                        checkHistoryItems
                            .map((checkHistoryItem: GuidelineCheckHistoryItem) => {
                                const isSelectedItem = selectedIndex === checkHistoryItem.dailyHoldingsToken;

                                const itemStyle = {
                                    background: isSelectedItem ? "#D0D7FF" : "#F2F2F2",
                                    borderTop: isSelectedItem ? "1px solid #1636FF" : "none",
                                    borderBottom: isSelectedItem ? "1px solid #1636FF" : "none",
                                };

                                return (
                                    <ListItemButton
                                        className="history-item"
                                        onClick={() => onClickHandler(checkHistoryItem.dailyHoldingsToken)}
                                        selected={isSelectedItem}
                                        style={itemStyle}
                                        key={checkHistoryItem.dailyHoldingsToken}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={5}
                                            justifyContent="center"
                                            alignItems="center">
                                            <div>
                                                <span>
                                                    {
                                                        getMonitoringStatus(checkHistoryItem.overallResult)
                                                    }
                                                </span>

                                                <ListItemText>
                                                    <span className="time">
                                                        {
                                                            formatDate(new Date(checkHistoryItem.timeStamp))
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </div>
                                            {
                                                checkHistoryItem.incidentHistoryItem &&
                                                <div>
                                                    <span>
                                                        {
                                                            getIncidentStausLabel(checkHistoryItem.incidentHistoryItem.status.status)
                                                        }
                                                    </span>
                                                    <ListItemText>
                                                        <span className="time">
                                                            <GuidelineIncidentStatus
                                                                status={checkHistoryItem.incidentHistoryItem.status}
                                                                mutedDate={checkHistoryItem.incidentHistoryItem.mutedDate} />
                                                        </span>
                                                    </ListItemText>
                                                </div>
                                            }
                                        </Stack>
                                    </ListItemButton>)
                            })
                    }
                </List>
            </Box>
        </Grid>
    )
};

export { GuidelineCheckHistory };