import httpClient from "../../http/http-client";
import { IncidentStatusType } from "../enums/incident-status-type.enum";
import { IncidentReadModel } from "../models/incident-read-model";

const fundApiUrl = `${process.env.REACT_APP_FUNDS_API_URL!}/`;

export const IncidentService = {
    changeStatus: async (
        incidentToken: string,
        fundToken: string,
        dailyHoldingsToken: string,
        status: IncidentStatusType,
        mutedDate: null | Date): Promise<IncidentReadModel> => {
        const incidentStatusUpdateUrl = `${fundApiUrl}${fundToken}/incidents/${incidentToken}`;

        const incidentUpdateModel = {
            dailyHoldingsToken: dailyHoldingsToken,
            status: status,
            mutedDate: mutedDate
        };

        const response =
            await httpClient.put(incidentStatusUpdateUrl, incidentUpdateModel);

        return response.data;
    },

    changeAssignee: async (
        incidentToken: string,
        fundToken: string,
        dailyHoldingsToken: string,
        assignee: string): Promise<IncidentReadModel> => {
        const incidentStatusUpdateUrl = `${fundApiUrl}${fundToken}/incidents/${incidentToken}`;

        const incidentUpdateModel = {
            dailyHoldingsToken: dailyHoldingsToken,
            assignee: assignee
        };

        const response =
            await httpClient.put(incidentStatusUpdateUrl, incidentUpdateModel);

        return response.data;
    },

    addComment: async (
        incidentToken: string,
        fundToken: string,
        dailyHoldingsToken: string,
        comment: string): Promise<IncidentReadModel> => {
        const incidentStatusUpdateUrl = `${fundApiUrl}${fundToken}/incidents/${incidentToken}`;

        const incidentUpdateModel = {
            dailyHoldingsToken: dailyHoldingsToken,
            comment: comment
        };

        const response =
            await httpClient.put(incidentStatusUpdateUrl, incidentUpdateModel);

        return response.data;
    },
}