import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { CfiCodeBuilder } from "../../cfi-code-builder";
import { CfiCodesRow } from "../../cfi-codes-row";

import './cfi-codes-builder-dialog.css';

const useStyles = makeStyles(() => ({
    dialog: {
        height: '90vh'
    }
}));

export const CfiCodesBuilderDialog = (props: any) => {
    const { onClose, open, initialCfiCodes } = props;

    const styles = useStyles();

    const [newCfiCodes, setNewCfiCodes] = useState<string[]>(initialCfiCodes);

    useEffect(() => setNewCfiCodes(initialCfiCodes), [initialCfiCodes]);

    const codesChanged = (cfiCodes: string[]) => {
        setNewCfiCodes(cfiCodes);
    }

    const cancelCfiCodesAdding = () => {
        onClose([]);
    }

    const addCfiCodes = () => {
        onClose(newCfiCodes);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='xl'
            classes={{ paper: styles.dialog }}
            open={open}
        >
            <DialogTitle>
                <div className="cfi-modal-title">
                    Add CFI code
                </div>

                <div className="cfi-modal-subtitle">
                    Compose your CFI code using our CFI builder below.
                </div>

                <CfiCodesRow codes={newCfiCodes} />
            </DialogTitle>

            <DialogContent>
                <div className="cfi-container">
                    <CfiCodeBuilder
                        initialCodes={initialCfiCodes}
                        codesChanged={codesChanged}
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    color='error'
                    variant="outlined"
                    onClick={cancelCfiCodesAdding}
                >
                    Cancel
                </Button>

                <Button
                    variant="outlined"
                    onClick={addCfiCodes}
                >
                    Add Codes
                </Button>
            </DialogActions>
        </Dialog>
    );
}