import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../auth";
import { useEffect, useState } from "react";
import { FundService } from "../core/services/fund.service";
import { GuidelineCheckHistoryItem } from "../core/models/guideline-check-history-item";
import { GuidelineCheckResult } from "../core/models/guideline-check-result";
import { DailyHoldingsCheckResultItem } from "../core/models/daily-holdings-check-result-item";
import { GuidelineCheckHistory } from "./guideline-check-history";
import { GuidelineCheckSummary } from "./guideline-check-summary";
import { DailyHoldingsCheckResult } from "./daily-holdings-check-result";
import { NotUsedHoldings } from "./not-used-holdings";
import { AccessTokenProviderService } from "../core/services/access-token-provider.service";
import "./guideline-check-history-page.css";
import { GuidelineService } from "../core/services/guideline.service";

interface IGuidelineCheckHistoryPageProps {
    onPageChanged?: (isShowSidebar: boolean) => void;
}

const GuidelineCheckHistoryPage = ({ onPageChanged }: IGuidelineCheckHistoryPageProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const { guidelineToken, fundToken = "" } = useParams();

    if (!guidelineToken || !fundToken) {
        throw new Error('Fund and guideline token should be specified')
    }

    const [guidelineCheckResult, setGuidelineCheckResult] = useState<GuidelineCheckResult>({} as GuidelineCheckResult);
    const [guidelineCheckHistory, setGuidelineCheckHistory] = useState<GuidelineCheckHistoryItem[]>([] as GuidelineCheckHistoryItem[]);
    const [dailyHoldingsToken, setDailyHoldingsToken] = useState<string>(guidelineCheckHistory[0]?.dailyHoldingsToken);

    useEffect(() => {
        if (!guidelineCheckHistory.length) {
            loadGuidelineCheckHistory(guidelineToken);
        }
    }, []);

    useEffect(() => {
        if (dailyHoldingsToken) {
            loadGuidelineCheckResult(guidelineToken, fundToken, dailyHoldingsToken);
        }

    }, [dailyHoldingsToken]);


    useEffect(() => {
        if (onPageChanged) {
            onPageChanged(false);

            return () => {
                onPageChanged(true);
            }
        }
    }, [])


    useEffect(() => {
        const token = AccessTokenProviderService.getToken();

        if (!user.isAuthenticated && !token) navigate('/login', { replace: true });
    }, [user.isAuthenticated]);


    function loadGuidelineCheckHistory(guidelineToken: string) {
        GuidelineService
            .getGuidelineCheckHistoryByToken(guidelineToken, fundToken)
            .then((checkHistory: GuidelineCheckHistoryItem[]) => {
                setGuidelineCheckHistory(checkHistory);

                checkHistory[0] && setDailyHoldingsToken(checkHistory[0].dailyHoldingsToken);
            });
    }

    function loadGuidelineCheckResult(
        guidelineToken: string,
        fundToken: string,
        dailyHoldingsToken: string,
    ) {
        FundService
            .getDailyHoldingsCheckResult(guidelineToken, fundToken, dailyHoldingsToken)
            .then((checkResult: GuidelineCheckResult) => setGuidelineCheckResult(checkResult));
    }

    const selectHistoryItem = (dailyHoldingsToken: string) => setDailyHoldingsToken(dailyHoldingsToken);

    return (
        <div className="monitoring-container">
            <div className="left-column">
                <Link to={"/"} className="button-link">
                    <Button type="button" variant="outlined">
                        Back to monitoring list
                    </Button>
                </Link>

                {
                    !!guidelineCheckHistory.length
                    && <GuidelineCheckHistory
                        checkHistoryItems={guidelineCheckHistory}
                        selectItem={selectHistoryItem}
                    />
                }
            </div>

            <div className="right-column">
                {
                    guidelineCheckResult.checkResultSummary
                    && <GuidelineCheckSummary
                        dailyHoldingsToken={dailyHoldingsToken}
                        fundToken={fundToken}
                        incident={guidelineCheckResult.incident}
                        summary={guidelineCheckResult.checkResultSummary} />
                }

                {
                    guidelineCheckResult.results
                    && guidelineCheckResult.results.map((result: DailyHoldingsCheckResultItem, idx: number) =>
                        <DailyHoldingsCheckResult result={result} key={idx} />)
                }

                {
                    !!guidelineCheckResult.notUsedHoldings?.length
                    && <NotUsedHoldings holdings={guidelineCheckResult.notUsedHoldings} />
                }
            </div>
        </div>
    )
}

export { GuidelineCheckHistoryPage };  
