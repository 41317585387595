import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { DailyHolding } from "../../core/models/daily-holding";
import { HoldingsTable } from "../holdings-table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface INotUsedHoldingsProps {
    holdings: DailyHolding[];
}

const NotUsedHoldings = ({ holdings }: INotUsedHoldingsProps) => (
    <Grid item className="summary">
        <div className="summary-title">
            <h3>
                Not Used Holdings
            </h3>
        </div>

        <Accordion className="expandable-section">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="expandable-summary"/>

            <AccordionDetails>
                <HoldingsTable holdings={holdings} />
            </AccordionDetails>
        </Accordion>
    </Grid>
);

export { NotUsedHoldings };